/*eslint-disable*/
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import CustomTextField from '../../../core-components/atoms/TextField';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendarIconSmall.svg';
import { FormControl, IconButton } from '@mui/material';
import { ImagesList } from './ImageList';
import PlacesAutocomplete from 'react-places-autocomplete';
import MarkerIcon from '../../../assets/icons/mapMarker.svg';
import Search from '../../../assets/icons/magnify.svg';
import { GOOGLE_MAP_API_KEY } from '../../../constants';
import { GoogleApiWrapper } from 'google-maps-react';
import { makeStyles } from '@material-ui/styles';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import API from '../../../libs/axios';
import { useEffect, useState } from 'react';
import { onError } from '../../../libs/errorLib';

const RefuelingForm = ({ formik }) => {
  const [vendors, setVendors] = useState([]);
  const [vendorSearch, setVendorSearch] = useState('');

  const useStyles = makeStyles({
    customTextField: {
      '& input::placeholder': {
        fontSize: '14px'
      }
    }
  });

  const classes = useStyles();

  const _getVendors = async (page, searchKeyword) => {
    try {
      const response = await API.get(`vendors`, {
        params: {
          search: searchKeyword
        }
      });
      setVendors(response.data);
    } catch (err) {
      if (err?.response?.data?.message) {
        onError(err);
        return;
      }

      let errors = err?.response?.data?.error?.errors || [];

      errors.map((data) => {
        onError(data);
      });
    } finally {
    }
  };

  useEffect(() => {
    _getVendors();
  }, [vendorSearch]);

  return (
    <div className="p-4 overflow-y-scroll">
      <div className="mt-4">
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              components={{
                OpenPickerIcon: CalendarIcon
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  onBlur={formik.handleBlur}
                  size="small"
                  sxProps={{
                    '& input::placeholder': {
                      fontSize: '14px'
                    },
                    '& input': {
                      fontSize: '14px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      height: '20px',
                      marginLeft: '8px'
                    },
                    '& label': {
                      fontSize: '14px'
                    },
                    '& .MuiIconButton-root': {
                      padding: 0
                    },
                    '& .MuiIconButton-edgeEnd': {
                      marginRight: '-6px'
                    }
                  }}
                  placeholder="Date"
                />
              )}
              inputFormat="dd/MM/yyyy"
              value={formik?.values?.date || null}
              label="Date"
              onChange={(newValue) => {
                formik.setFieldValue('date', newValue);
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </div>
      <div className="mt-4">
        <FormControl fullWidth variant="outlined">
          <CustomTextField
            value={formik.values.distance}
            onChange={(e) => formik.setFieldValue('distance', e.target.value)}
            placeholder={'Speedometer Reading'}
            classes={{ root: classes.customTextField }}
          />
        </FormControl>
      </div>
      <div className="mt-4">
        <FormControl fullWidth variant="outlined">
          <CustomTextField
            value={formik.values.refuel}
            onChange={(e) => formik.setFieldValue('refuel', e.target.value)}
            placeholder={'Refuel'}
            classes={{ root: classes.customTextField }}
          />
        </FormControl>
      </div>
      <div className="mt-4">
        <FormControl fullWidth variant="outlined">
          <CustomTextField
            value={formik.values.fuelPrice}
            onChange={(e) => formik.setFieldValue('fuelPrice', e.target.value)}
            placeholder={'Fuel Price'}
            classes={{ root: classes.customTextField }}
          />
        </FormControl>
      </div>
      <div className="mt-4">
        <FormControl fullWidth={true} variant="outlined">
          <Autocomplete
            options={vendors ? vendors : []}
            value={formik.values.vendor}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                variant="outlined"
                placeholder="Vendor"
              />
            )}
            getOptionLabel={(vendor) => {
              if (vendor?.name) {
                return vendor.name;
              }

              return vendor?.Vendor ? vendor?.Vendor?.name : '';
            }}
            onChange={(e, val) => {
              formik.setFieldValue('vendor', val);
            }}
            onKeyPress={(e) => setVendorSearch(e.target.value)}
          />
        </FormControl>
      </div>
      <div className="mt-4">
        <FormControl fullWidth={true} variant="outlined">
          <Autocomplete
            options={vendors ? vendors : []}
            value={formik.values.vendor}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                variant="outlined"
                placeholder="Vendor"
              />
            )}
            getOptionLabel={(vendor) => {
              if (vendor?.name) {
                return vendor.name;
              }

              return vendor?.Vendor ? vendor?.Vendor?.name : '';
            }}
            onChange={(e, val) => {
              formik.setFieldValue('vendor', val);
            }}
            onKeyPress={(e) => setVendorSearch(e.target.value)}
          />
        </FormControl>
      </div>
      <div className="mt-4">
        <PlacesAutocomplete
          searchOptions={{}}
          value={formik.values.location}
          onChange={(e) => formik.setFieldValue('location', e)}
          // onSelect={handleSingleLocationSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <div>
              <div className="w-[400px]">
                <FormControl fullWidth>
                  <CustomTextField
                    {...getInputProps({
                      placeholder: 'Search for a place...',
                      className: 'bg-white'
                    })}
                    classes={{ root: classes.customTextField }}
                  />
                </FormControl>
              </div>

              {suggestions?.length > 0 && (
                <div className="z-50 w-[400px] h-[200px] overflow-auto map">
                  {suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: suggestion.active ? '#F1F8FF' : '#ffffff'
                    };

                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          style
                        })}
                        key={suggestion.placeId}
                        className="px-3 py-4 hover:bg-[#F1F8FF] cursor-pointer flex items-center gap-2"
                      >
                        <img src={MarkerIcon} />
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </PlacesAutocomplete>
      </div>
      <div className="mt-4">
        <ImagesList formik={formik} />
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_API_KEY
})(RefuelingForm);
