/*eslint-disable*/
import { useContext, useEffect, useState, useRef } from 'react';
import {
  FormControl,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import {
  dateFormatSlashDivider,
  SharedContext,
  exportToCSV
} from '../../../utils/common';
import { isRequired } from '../../../utils/validators';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton
} from '@mui/material';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import moment from 'moment';
import Frame from '../../../assets/icons/Frame1.svg';
import LoadingInventory from '../../../assets/icons/loading_inventory.svg';
import DateRangePickerSingleCalendar from '../ledgerReports/DateRangePicker';
import Button from '../../../core-components/atoms/Button';
import { Paragraph, Title } from '../../../core-components/atoms/Text';
import DownloadIcon from '../../../assets/icons/downloadOutlineIcon.svg';
import { constants } from '../../invoiceandbilling/invoiceManagement/constants';
import FileDownload from 'js-file-download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { RemoveRedEye, Close } from '@material-ui/icons';
import PaymentListing from '../../invoiceandbilling/invoiceManagement/PaymentListing';

const useStyles = makeStyles(() => ({
  parentContainer: {
    boxSizing: 'border-box',
    padding: '20px 20px',
    width: '100% !important',
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      padding: '20px 14px'
    },
    margin: '0px',
    paddingTop: '16px',
    paddingLeft: '32px',
    paddingRight: '24px',
    minHeight: '152px',
    backgroundColor: '#F6F7FB'
  },
  container: {
    padding: 20
  }
}));

export default function PayablesReport() {
  const classes = useStyles();
  const { setCurrentPageTitle, setAPILoader } = useContext(SharedContext);

  const [invoiceData, setInvoiceData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [customerId, setCustomerId] = useState('');
  const [customers, setCustomers] = useState([]);
  const [searchCompanyValue, setSearchCompanyValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [singleCustomer, setSingleCustomer] = useState(null);
  const [carrier, setCarrier] = useState({});
  const [carrierId, setCarrierId] = useState(null);
  const [carriers, setCarriers] = useState([]);
  const [carrierSearch, setCarrierSearch] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalOutstanding, setTotalOutstanding] = useState(0);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const tableContainerRef = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [activeInvoice, setActiveInvoice] = useState();
  const [payments, setPayments] = useState(false);

  const statusOptions = [
    { label: 'All', value: '' },
    { label: 'Paid', value: 'PAID' },
    { label: 'Unpaid', value: 'UNPAID' },
    { label: 'Partially Paid', value: 'PARTIALLY_PAID' },
    { label: 'Overdue', value: 'OVERDUE' }
  ];

  const columnNames = [
    'Vendor / Carrier Name',
    'Payable Number',
    'Payable Date',
    'Due Date',
    'Total Amount',
    'Payments Applied',
    'Outstanding Balance',
    'Last Payment Date',
    'Debit Adjustments',
    'Debit Note',
    ''
  ];

  useEffect(() => {
    setCurrentPageTitle('Payable Report');
  }, [setCurrentPageTitle]);

  useEffect(() => {
    getCompanies(searchCompanyValue);
  }, [searchCompanyValue]);

  useEffect(() => {
    getCarriers(carrierSearch);
  }, [carrierSearch]);

  const getCompanies = (searchCompanyValue) => {
    let search = searchCompanyValue;
    setAPILoader(true);
    API.get('vendors', {
      params: { search }
    })
      .then((res) => {
        if (res.data) {
          setCustomers(res.data);
          if (res.data?.rows?.length == 1) {
            setSingleCustomer(res.data?.[0]);
            setCustomerId(res.data?.rows?.id);
          }
        }
      })
      .finally(() => setAPILoader(false));
  };

  const getCarriers = (searchCompanyValue) => {
    let search = searchCompanyValue;
    setAPILoader(true);
    API.get('companies', {
      params: { search, type: 'VENDOR' }
    })
      .then((res) => {
        if (res.data) {
          setCarriers(res.data.rows);
        }
      })
      .finally(() => setAPILoader(false));
  };

  const handleTableScroll = async () => {
    if (isLoading) {
      return;
    }
    const container = tableContainerRef.current;
    if (
      container.scrollTop + container.clientHeight + 20 >=
        container.scrollHeight &&
      page < pageCount
    ) {
      setPage(page + 1);
      await generateInvoiceReport(page + 1, invoiceData);
    }
  };

  const generateInvoiceReport = async (currentPage = 1, oldData = []) => {
    try {
      setIsLoading(true);
      const params = {
        page: currentPage,
        startDate: moment.utc(startDate).toISOString(),
        endDate: moment
          .utc(endDate)
          .add(23, 'hours')
          .add(59, 'minutes')
          .add(59, 'seconds')
          .toISOString(),
        vendorId: customerId,
        customerId: carrierId,
        billing: true,
        status: selectedStatus
      };

      const response = await API.get('reports/payable-ledger', { params });
      const newInvoices = response.invoices || [];

      if (currentPage === 1) {
        setInvoiceData(newInvoices);
      } else {
        setInvoiceData([...oldData, ...newInvoices]);
      }

      setPageCount(response.pages || 1);
      setTotalAmount(response.paymentTotals?.totalAmount);
      setTotalPaid(response.paymentTotals?.totalPaid);
      setTotalOutstanding(response.paymentTotals?.totalDue);
    } catch (err) {
      onError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const exportReport = async () => {
    const params = {
      startDate: moment.utc(startDate).toISOString(),
      endDate: moment
        .utc(endDate)
        .add(23, 'hours')
        .add(59, 'minutes')
        .add(59, 'seconds')
        .toISOString(),
      customerId,
      billing: true,
      status: selectedStatus
    };
    const response = await API.get('invoices/export', {
      params,
      responseType: 'blob'
    });

    FileDownload(response, `Invoices ${moment().format('DD-MM-yyyy')}.xlsx`);
  };

  return (
    <div>
      <Grid container className={classes.parentContainer} spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <div className="flex flex-row">
            <Title className="py-1 w-[72px] mt-2 flex" variant={'sm'}>
              Vendor<p className={'text-red-500'}>*</p>
            </Title>
            <div className="w-full">
              <FormControl fullWidth={true} variant="outlined">
                <Autocomplete
                  id="customer"
                  defaultValue={''}
                  value={singleCustomer || null}
                  options={[{ name: 'All', id: 'ALL' }, ...customers]}
                  placeholder={'Select Vendor'}
                  getOptionLabel={(customer) =>
                    customer.name || customer.label || ''
                  }
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setCustomerId(newValue.id);
                      setSingleCustomer(newValue);
                    } else {
                      setCustomerId(null);
                      setSingleCustomer(null);
                    }
                  }}
                  onInputChange={(event, newValue) => {
                    setSearchCompanyValue(newValue);
                  }}
                />
              </FormControl>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <div className="flex flex-row">
            <Title className="py-1 w-[72px] mt-2 flex" variant={'sm'}>
              Carrier<p className={'text-red-500'}>*</p>
            </Title>
            <div className="w-full">
              <FormControl fullWidth={true} variant="outlined">
                <Autocomplete
                  id="customer"
                  defaultValue={''}
                  value={carrier || null}
                  options={[{ name: 'All', id: 'ALL' }, ...carriers]}
                  placeholder={'Select Carrier'}
                  getOptionLabel={(customer) =>
                    customer.name || customer.label || ''
                  }
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setCarrierId(newValue.id);
                      setCarrier(newValue);
                    } else {
                      setCarrierId(null);
                      setCarrier(null);
                    }
                  }}
                  onInputChange={(event, newValue) => {
                    setCarrierSearch(newValue);
                  }}
                  inputValue={carrierSearch}
                />
              </FormControl>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <div className="flex flex-row">
            <Title className="py-1 w-[72px] mt-2 flex" variant={'sm'}>
              Status
            </Title>
            <div className="w-full">
              <FormControl fullWidth={true} variant="outlined">
                <Autocomplete
                  id="status"
                  options={statusOptions}
                  getOptionLabel={(option) => option.label}
                  value={
                    statusOptions.find(
                      (option) => option.value === selectedStatus
                    ) || statusOptions[0]
                  }
                  onChange={(event, newValue) => {
                    setSelectedStatus(newValue?.value || '');
                  }}
                  placeholder="Select Status"
                />
              </FormControl>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={12}>
          <div className="flex flex-row justify-end">
            <div>
              <DateRangePickerSingleCalendar
                startDate={startDate}
                endDate={endDate}
                updateRange={(input) => {
                  setStartDate(input.selection.startDate);
                  setEndDate(input.selection.endDate);
                }}
                showDatesInButton
              />
            </div>
            <div className="ml-2">
              <Button
                className="py-2 px-3 ml-2 h-8 border-0"
                overrideSize={true}
                labelClass="font-medium text-xs"
                variant="primary"
                label={'Generate Report'}
                onClick={() => {
                  setPage(1);
                  generateInvoiceReport(1, []);
                }}
                disabled={!customerId && !carrierId}
              />
            </div>
          </div>
        </Grid>
      </Grid>

      {invoiceData.length > 0 ? (
        <div className={'no-scrollbar'}>
          <Paper className="no-scrollbar rounded-t-lg shadow-none mx-[28px] mt-[32px]">
            <TableContainer
              className="mt-6 no-scrollbar rounded-t-lg border border-solid border-dark300 inventory-report-table"
              style={{ overflow: 'auto' }}
              onScroll={handleTableScroll}
              ref={tableContainerRef}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {columnNames.map((columnName, index) => (
                      <TableCell
                        key={index}
                        className="text-[10px] text-dark500 bg-white py-2 pl-3 pr-2 font-normal h-[40px]"
                      >
                        {columnName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceData.map((row, index) => {
                    const dueAmount = row.total - (row.paidAmount || 0);
                    return (
                      <TableRow key={index}>
                        <TableCell className="text-[12px]">
                          {row.Vendor?.name || row?.Company?.name}
                        </TableCell>
                        <TableCell className="text-[12px]">
                          {row.number}
                        </TableCell>
                        <TableCell className="text-[12px]">
                          {dateFormatSlashDivider(row.startDate)}
                        </TableCell>
                        <TableCell className="text-[12px]">
                          {dateFormatSlashDivider(row.dueDate)}
                        </TableCell>
                        <TableCell className="text-[12px]">
                          {row.currency || 'PKR'} {row.total}
                        </TableCell>
                        <TableCell className="text-[12px]">
                          {row.currency || 'PKR'} {row.paidAmount || 0}
                        </TableCell>
                        <TableCell className="text-[12px]">
                          {row.currency || 'PKR'}{' '}
                          {dueAmount > 0 ? dueAmount : 0}
                        </TableCell>
                        <TableCell className="text-[12px]">
                          {row?.BillPayments?.length > 0
                            ? dateFormatSlashDivider(row?.BillPayments[0]?.date)
                            : '-'}
                        </TableCell>
                        <TableCell className="text-[12px]">
                          {row?.CreditNote
                            ? `${row?.currency} ${row.adjustment}`
                            : '-'}
                        </TableCell>
                        <TableCell className="text-[12px]">
                          {row?.CreditNote
                            ? `${row?.CreditNote?.number}`
                            : '-'}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              setPayments(true);
                              setActiveInvoice(row);
                            }}
                            size="small"
                            sx={{ ml: 2 }}
                          >
                            <RemoveRedEye sx={{ width: 32, height: 32 }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {page < pageCount && (
                    <TableRow>
                      <TableCell className="py-2 h-12" colSpan={12}>
                        <div className="flex w-full justify-center">
                          <CircularProgress size="14px" />
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          {payments && (
            <Dialog
              open={payments}
              onClose={() => {
                setPayments(false);
                setActiveInvoice(null);
              }}
              BackdropProps={{ style: { backgroundColor: '#0B1940' } }}
              scroll="paper"
              classes={{
                scrollPaper: classes.topScrollPaper,
                paperScrollBody: classes.topPaperScrollBody
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setPayments(false);
                    setActiveInvoice(null);
                  }}
                >
                  <Close />
                </IconButton>
              </div>

              <DialogContent>
                <PaymentListing
                  payments={activeInvoice?.BillPayments || []}
                  invoiceNumber={activeInvoice?.number}
                  billing={false}
                />
              </DialogContent>
            </Dialog>
          )}
          <div className="flex justify-between items-center bg-dark200 rounded-b-lg h-14 border-solid border border-dark300 border-t-0 py-3 px-4 mx-[28px]">
            <Button
              variant="transparent"
              icon={<img src={DownloadIcon} className={`mr-2`} />}
              label="Export"
              className="py-2 px-3 ml-2 h-8 border-solid border"
              overrideSize={true}
              labelClass="font-medium text-xs"
              iconClass="h-[14px] w-[14px] mr-1"
              onClick={exportReport}
            />

            <div className="flex gap-8">
              <div className="flex items-center">
                <span className="text-xs text-dark500">Total Amount:</span>
                <span className="text-sm font-medium ml-2">
                  {invoiceData[0]?.currency || 'PKR'}{' '}
                  {totalAmount.toLocaleString()}
                </span>
              </div>

              <div className="flex items-center">
                <span className="text-xs text-dark500">Payments Applied:</span>
                <span className="text-sm font-medium ml-2">
                  {invoiceData[0]?.currency || 'PKR'}{' '}
                  {totalPaid.toLocaleString()}
                </span>
              </div>

              <div className="flex items-center">
                <span className="text-xs text-dark500">
                  Outstanding Balance:
                </span>
                <span className="text-sm font-medium ml-2">
                  {invoiceData[0]?.currency || 'PKR'}{' '}
                  {totalOutstanding.toLocaleString()}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : isLoading ? (
        <div className="w-full h-[573px]">
          <div className="flex justify-center items-center flex-col h-full">
            <img src={LoadingInventory} alt="Report Loading" />
            <Paragraph variant={'md'} className="text-dark400 mt-3">
              Please wait while we generate your report
            </Paragraph>
          </div>
        </div>
      ) : (
        <div className="w-full h-[573px]">
          <div className="flex justify-center items-center flex-col h-full">
            <img src={Frame} alt="No Report" />
            <Paragraph
              variant={'md'}
              className="text-dark400 mt-3 flex items-center"
            >
              Select the filters and
              <Title variant={'md'} className={'px-0.5'}>
                {' Generate Report '}
              </Title>
              to view the data
            </Paragraph>
          </div>
        </div>
      )}
    </div>
  );
}
