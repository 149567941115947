/*eslint-disable*/

import { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Typography, makeStyles } from '@material-ui/core';
import API from '../../../libs/axios';
import { SharedContext } from '../../../utils/common';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import { onError } from '../../../libs/errorLib';
import InvoiceForm from '../invoices/InvoiceForm';
import { ImagesList } from './Payment';
import CustomTextField from '../../../core-components/atoms/TextField';
import Button from '../../../core-components/atoms/Button';
import { upload } from '../../../utils/upload';
import { toaster } from '../../../utils/toaster';
import CreditNoteItemsForm from './CreditNoteItemsForm';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem'
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginTop: '2rem',
    marginBottom: '1rem'
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    '& button': {
      textTransform: 'none'
    }
  }
}));

export const CreditNotePopup = ({ setOpen, type, id, refetch }) => {
  const classes = useStyles();
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [taxes, setTaxes] = useState([])
  const [search, setSearch] = useState('');
  const { setAPILoader } = useContext(SharedContext);

  const formik = useFormik({
    initialValues: {
      adjustmentAmount: '',
      reason: '',
      images: [],
      date: new Date()
    },
    validate: (values) => {
      const errors = {};
      if (!values.adjustmentAmount) {
        errors.adjustmentAmount = 'Required';
      } else if (
        selectedInvoice &&
        Number(values.adjustmentAmount) > selectedInvoice.totalAmount
      ) {
        errors.adjustmentAmount = 'Cannot exceed invoice amount';
      }
      return errors;
    },
    onSubmit: async (values) => {
      setAPILoader(true);
      try {
        let fileIds = [];
        if (values.images.length) {
          fileIds = await upload(values.images, 'credit-notes');
        }

        if (!id)
          await API.post('invoices/credit-note', {
            invoiceId: selectedInvoice.id,
            adjustment: values.adjustmentAmount,
            invoiceItems: invoiceItems,
            reason: values.reason,
            type
          });
        else
          await API.put(`invoices/credit-note/${id}`, {
            invoiceId: selectedInvoice.id,
            adjustment: values.adjustmentAmount,
            invoiceItems: invoiceItems,
            reason: values.reason,
            type
          });

        refetch && await refetch()

        toaster(
          'success',
          !id ? 'Credit note created successfully' : 'credit note updated'
        );
        setOpen(false);
      } catch (err) {
        onError(err);
      } finally {
        setAPILoader(false);
      }
    }
  });

  const getInvoices = async () => {
    try {
      setAPILoader(true);
      const res = await API.get(`invoices`, {
        params: {
          filters: {
            Status: [{ value: 'PENDING' }, { value: 'OVERDUE' }],
            hasFullCredit: false
          },
          billing: type == 'debit',
          search
        }
      });
      setInvoices(res.invoices);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getCreditNote = async () => {
    try {
      setAPILoader(true);
      const res = await API.get(`invoices/credit-note/${id}`);
      setSelectedInvoice(res.data.Invoice);
      formik.setFieldValue('reason', res.data.reason);
      formik.setFieldValue('adjustmentAmount', res.data.Invoice.adjustment);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getTaxes = async () => {
    try {
      setAPILoader(true);
      const tax = await API.get(`/taxes`);
      setTaxes(
        tax?.taxes.map((tax) => ({
          ...tax,
          name: `${tax.name} (${tax.rate}%)`
        }))
      );
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    getInvoices();
  }, [search]);

  useEffect(() => {
    if (id) getCreditNote();
  }, [id]);

  useEffect(() => {
    if (selectedInvoice) {
      setInvoiceItems(
        selectedInvoice?.InvoiceItems?.map((it) => ({
          ...it,
          unitType: it.UnitType,
          title: it.InvoiceTitle,
          tax: it.Tax ? {
            ...it.Tax,
            name: `${it.Tax?.name} (${it.Tax.rate}%)`
          }: null
        }))
      );
    }
  }, [selectedInvoice]);

  useEffect(() => {
    getTaxes()
  }, [])

  return (
    <div className={classes.container}>
      {!id && (
        <Autocomplete
          options={invoices}
          value={selectedInvoice}
          placeholder={type !== 'debit' ? 'Select Invoice' : 'Select Bill'}
          getOptionLabel={(option) => option.number || ''}
          onChange={(e, value) => setSelectedInvoice(value)}
          inputValue={search}
          onInputChange={(e, value) => {
            setSearch(value);
          }}
        />
      )}

      {selectedInvoice && (
        <>
          <CreditNoteItemsForm
            invoiceItems={invoiceItems}
            setInvoiceItems={setInvoiceItems}
            taxes={taxes}
          />

          <CustomTextField
            label="Reason"
            value={formik.values.reason}
            onChange={(e) => formik.setFieldValue('reason', e.target.value)}
          />
          <CustomTextField
            label="Adjustment Amount"
            type="number"
            value={formik.values.adjustmentAmount}
            onChange={(e) =>
              formik.setFieldValue('adjustmentAmount', e.target.value)
            }
            error={
              formik.touched.adjustmentAmount && formik.errors.adjustmentAmount
            }
            helperText={
              formik.touched.adjustmentAmount && formik.errors.adjustmentAmount
            }
          />

          <div className={classes.imageContainer}>
            <Typography>Attach Supporting Documents</Typography>
            <ImagesList formik={formik} field="images" />
          </div>

          <div className={classes.btns}>
            <Button variant="secondary" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={formik.submitForm}
              disabled={!formik.isValid || !selectedInvoice}
            >
              {!id ? 'Create' : 'Update'}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
