/*eslint-default*/
import { Box, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { checkPermission } from '../../../utils/auth';
import { SharedContext } from '../../../utils/common';
import CancelledTab from '../invoiceManagement/CancelledTab';
import DraftsTab from '../invoiceManagement/DraftsTab';
import InvoicesTab from '../invoiceManagement/InvoicesTab';
import useStyles from '../invoiceStyles';
import mixpanel from 'mixpanel-browser';
import { PayableStats } from './payableStats';
import DateRangePickerSingleCalendar from '../../reporting/ledgerReports/DateRangePicker';
import { CreditNotePopup } from '../invoiceManagement/CreditNotePopup';
import Popup from '../../../core-components/atoms/Popup';
import CreditNotes from '../invoiceManagement/CreditNote';
import Button from '../../../core-components/atoms/Button';

export const InvoiceFiltersInitialValue = {
  Status: [],
  Type: [],
  Customer: [],
  Warehouse: [],
  Contract: [],
  Vendor: []
};

export const cancelledFilterInitialValue = {
  Type: [],
  Customer: [],
  Warehouse: [],
  Contract: [],
  Vendor: []
};

export default function ExpenseManagement() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [filterStateInvoices, setFilterStateInvoices] = useState(
    InvoiceFiltersInitialValue
  );
  const [filterStateDrafts, setFilterStateDrafts] = useState(
    InvoiceFiltersInitialValue
  );
  const [filterCancelledInvoice, setFilterCancelInvoice] = useState(
    cancelledFilterInitialValue
  );
  const [invoicesLength, setInvoicesLength] = useState(0);
  const [draftLength, setDraftLength] = useState(0);
  const [cancelled, setCancelled] = useState(0);
  const [stats, setStats] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [creditNotePopup, setCreditNotePopup] = useState(false);
  const [creditNotes, setCreditNotes] = useState([]);
  const [creditNotePage, setCreditNotePage] = useState(1);
  const [creditNotePageCount, setCreditNotePageCount] = useState(0);
  const [creditNoteSearch, setCreditNoteSearch] = useState('');
  const navigate = useNavigate();

  const { setCurrentPageTitle, setAPILoader, currentUser } =
    useContext(SharedContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getStats = async () => {
    try {
      setAPILoader(true);
      const stats = await API.get('invoices/stats', {
        params: { billing: true }
      });
      setInvoicesLength(stats.invoices);
      setDraftLength(stats.drafts);
      setCancelled(stats.cancelled);
    } catch (e) {
      onError(e);
    } finally {
      setAPILoader(false);
    }
  };

  const getTopStats = async (startDate, endDate) => {
    try {
      setAPILoader(true);
      const stats = await API.get('invoices/billed-amount-stats', {
        params: {
          startDate,
          endDate
        }
      });
      setStats(stats);
    } catch (e) {
      // onError(e)
    } finally {
      setAPILoader(false);
    }
  };

  const getCreditNotes = async () => {
    try {
      setAPILoader(true);
      const params = {
        page: creditNotePage,
        search: creditNoteSearch,
        type: 'debit'
      };

      const response = await API.get('invoices/credit-note', { params });
      setCreditNotes(response.data || []);
      setCreditNotePageCount(response.pages);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    getCreditNotes();
  }, [creditNoteSearch, creditNotePage]);

  useEffect(() => {
    setCurrentPageTitle('Expenses');
    getStats();
    getTopStats(startDate, endDate);
    mixpanel.track('Expenses Page', {});
  }, []);

  return (
    <div>
      <PayableStats stats={stats} />
      <Grid
        item
        container
        className="mt-10"
        style={{ width: '95%', margin: 'auto' }}
      >
        <Box className={classes.borderBottom} mt={5}>
          <Box className={classes.tabsContainer}>
            <Tabs value={value} onChange={handleChange}>
              <Tab
                label={
                  <Typography
                    className={value == 0 ? classes.active : classes.tabText}
                  >{`Bills (${invoicesLength})`}</Typography>
                }
                style={{ textTransform: 'none', fontSize: '1rem' }}
              />
              <Tab
                label={
                  <Typography
                    className={value == 1 ? classes.active : classes.tabText}
                  >{`Drafts (${draftLength})`}</Typography>
                }
                style={{ textTransform: 'none', fontSize: '1rem' }}
                active={value == 1}
              />
              <Tab
                label={
                  <Typography
                    className={value == 2 ? classes.active : classes.tabText}
                  >{`Cancelled (${cancelled})`}</Typography>
                }
                style={{ textTransform: 'none', fontSize: '1rem' }}
                active={value == 2}
              />

              <Tab
                label={
                  <Typography
                    className={value == 3 ? classes.active : classes.tabText}
                  >{`Debit Notes`}</Typography>
                }
                style={{ textTransform: 'none', fontSize: '1rem' }}
                active={value == 2}
              />
            </Tabs>
          </Box>
          <div className={'flex'}>
            <DateRangePickerSingleCalendar
              startDate={startDate}
              endDate={endDate}
              updateRange={async (input) => {
                setStartDate(input.selection.startDate);
                setEndDate(input.selection.endDate);
                await getTopStats(
                  input.selection.startDate,
                  input.selection.endDate
                );
              }}
              showDatesInButton={false}
            />
            {checkPermission(currentUser, 'OPS_CREDIT_NOTE_CREATE') && (
              <Button
                onClick={() => setCreditNotePopup(true)}
                variant="primary"
                labelClass="font-medium text-xs"
                className="ml-2 h-8 rounded border-solid"
              >
                <span className={classes.addIcon}>+</span>
                <span>Debit Note</span>
              </Button>
            )}
            {checkPermission(currentUser, 'OPS_EXPENSE_CREATE') && (
              <Button
                variant="primary"
                color="primary"
                onClick={() => navigate('/billing-invoice/payables/create')}
                className="ml-2 h-8 rounded border-solid"
              >
                <span className={classes.addIcon}>+</span>
                <span>Add Expense</span>
              </Button>
            )}
          </div>
        </Box>
        {value === 0 && (
          <InvoicesTab
            tab={value}
            billing={true}
            filterStateInvoices={filterStateInvoices}
            setFilterStateInvoices={setFilterStateInvoices}
            getStats={getStats}
          />
        )}
        {value === 1 && (
          <DraftsTab
            tab={value}
            billing={true}
            filterStateDrafts={filterStateDrafts}
            setFilterStateDrafts={setFilterStateDrafts}
          />
        )}
        {value === 2 && (
          <CancelledTab
            tab={value}
            billing={true}
            filterCancelledInvoice={filterCancelledInvoice}
            setFilterCancelInvoice={setFilterCancelInvoice}
          />
        )}
        {value === 3 && (
          <CreditNotes
            type="debit"
            creditNotes={creditNotes}
            page={creditNotePage}
            setPage={setCreditNotePage}
            pageCount={creditNotePageCount}
            search={creditNoteSearch}
            setSearch={setCreditNoteSearch}
            refetch={async () => await getCreditNotes()}
          />
        )}
      </Grid>
      <Popup
        open={creditNotePopup}
        setOpen={setCreditNotePopup}
        content={
          <div className="min-w-[600px]">
            <CreditNotePopup
              setOpen={setCreditNotePopup}
              type="debit"
              refetch={async () => await getCreditNotes()}
            />
          </div>
        }
        title={<span>Create Debit Note</span>}
      />
    </div>
  );
}
