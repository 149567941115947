/*eslint-disable*/

import RightArrowIcon from '../../../../assets/icons/rightArrow.svg';
import CancelledIcon from '../../../../assets/icons/cancelled.svg';
import EditIcon from '../../../../assets/icons/edit-row.svg';
import LinearProgressBar from '../../../../core-components/atoms/LinerProgressBar';
import OptionsMenu from '../../Options';
import { useContext, useState } from 'react';
import AddShipmentTMS from '../../AddShipmentTMS';
import clsx from 'clsx';
import { CRIMSON_500, JADE_700, PRIMARY } from '../../../../constants/colors';
import ShipmentIcon from '../../../../assets/icons/shipment.svg';
import moment from 'moment';
import { ActionBtns } from '../tracking/LogisticTMSShipmentTracking';
import EyeIcon from '../../../../assets/icons/eye.svg';
import PinDropIcon from '../../../../assets/icons/pinDropIcon.svg';
import ShipmentDetail from '../../ShipmentDetail';
import ShipIcon from '../../../../assets/icons/ShipIcon.svg';
import AirplaneIcon from '../../../../assets/icons/AirplaneIcon.svg';
import DarkShipIcon from '../../../../assets/icons/darkShip.svg';
import DarkAeroplaneIcon from '../../../../assets/icons/darkAeroPlane.svg';
import DarkTruckIcon from '../../../../assets/icons/shipment.svg';
import BlueTruckIcon from '../../../../assets/icons/TruckIcon.svg';
import GreenTruckIcon from '../../../../assets/icons/greentTruckIcon.svg';
import GreenShipIcon from '../../../../assets/icons/greenShip.svg';
import GreenAeroPlaneIcon from '../../../../assets/icons/greenAeroplane.svg';
import RedTruckIcon from '../../../../assets/icons/redTruckIcon.svg';
import RedShipIcon from '../../../../assets/icons/redShip.svg';
import RedAeroplaneIcon from '../../../../assets/icons//redAeroPlane.svg';
import {
  SharedContext,
  decimalDisplay,
  getCurrencySymbol
} from '../../../../utils/common';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import FLAGS from '../../../../constants/featureFlags';
import WaseeqaPopup from '../WaseeqaNumberPopup';
import { Edit } from '@material-ui/icons';

export const LogisticTMSCard = ({
  shipment,
  order,
  refetch,
  setCancelPopUp
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const [open, setOpen] = useState(false);
  const [shipmentDetail, setShipmentDetail] = useState(false);
  const [action, setAction] = useState('edit');
  const [waseeqaPopup, setWaseeqaPopup] = useState(false);
  const [podDownloadOpen, setPodDownloadOpen] = useState(false);

  const { organization } = useContext(SharedContext);

  const options = [];

  let cases = {
    AWAITING_CARRIER:
      shipment.status.key == 'CREATED' &&
      shipment?.ShipmentLegs?.[0]?.status == 'AWAITING_CARRIER',
    CARRIER_ASSIGNED:
      shipment.status.key == 'CREATED' &&
      shipment?.ShipmentLegs?.[0]?.status == 'CARRIER_ASSIGNED' &&
      shipment.subStatus?.key !== 'LOADING_IN_PROGRESS',
    LOADING_IN_PROGRESS:
      shipment.status.key == 'CREATED' &&
      shipment?.subStatus?.key == 'LOADING_IN_PROGRESS',
    OUT_FOR_DELIVERY:
      shipment?.status?.key == 'IN_TRANSIT' && !shipment?.subStatus?.key,
    ARRIVED_AT_DESTINATION: shipment?.status?.key == 'ARRIVED_AT_DESTINATION',
    UNLOADING_COMPLETE:
      shipment.status.key == 'ARRIVED_AT_DESTINATION' &&
      shipment?.subStatus?.key == 'UNLOADING_COMPLETE',
    COMPLETED: shipment.status.key == 'COMPLETED',
    CANCELLED: shipment.status.key == 'CANCELLED'
  };

  if (!cases.CANCELLED)
    options.push({
      label: (
        <div className="flex gap-3">
          <img src={EditIcon} className="w-4" />
          <span>Edit Shipment</span>
        </div>
      ),
      onClick: () => {
        setOpen(true);
      }
    });

  if (
    !cases.CANCELLED &&
    !cases.COMPLETED &&
    !cases.ARRIVED_AT_DESTINATION &&
    !cases.UNLOADING_COMPLETE &&
    isFeatureEnabled(FLAGS.SHIPMENT_CANCELLATION)
  ) {
    options.push(
      ...[
        {
          label: (
            <div className="flex gap-3">
              <img src={CancelledIcon} className="w-4" />
              <span>Cancel Shipment</span>
            </div>
          ),
          onClick: () => {
            setCancelPopUp(true);
          },
          icon: CancelledIcon
        }
      ]
    );
  }

  options.push({
    label: (
      <div className="flex gap-3">
        <img src={EyeIcon} className="w-4" />
        <span>View Shipment Detail</span>
      </div>
    ),
    onClick: () => {
      setShipmentDetail(true);
    }
  });

  const bgStyle = cases.OUT_FOR_DELIVERY
    ? 'bg-[#000] text-[#fff]'
    : cases.ARRIVED_AT_DESTINATION || cases.UNLOADING_COMPLETE
    ? 'border-jade700 text-jade700 border'
    : cases.COMPLETED
    ? 'bg-jade700 border-jade700'
    : cases.CANCELLED
    ? 'bg-[#870000] border-none'
    : 'border-[#000] border';
  const textStyle = cases.OUT_FOR_DELIVERY
    ? 'text-[#fff]'
    : cases.ARRIVED_AT_DESTINATION || cases.UNLOADING_COMPLETE
    ? 'text-jade700 font-medium'
    : cases.COMPLETED
    ? 'text-[#fff] font-medium'
    : cases.CANCELLED
    ? 'text-[#fff] font-medium'
    : '';

  const mode = shipment?.ShipmentLegs?.[0]?.mode;

  const shipmentCost = shipment?.Expenses?.reduce((acc, v) => acc + v.cost, 0);

  const expectedDeliveryDate = moment(shipment.dropoffDateTime);
  const currentDate = moment();
  const timeDifference = expectedDeliveryDate.diff(currentDate);
  const daysDifference =
    moment.duration(timeDifference).asDays() < 0
      ? '0'
      : moment.duration(timeDifference).asDays().toFixed(0);

  const duration = moment.duration(
    moment(shipment.completedAt).diff(shipment.createdAt)
  );
  const durationInDays = duration.asDays();

  const formattedDuration =
    durationInDays >= 1 ? `${Math.round(durationInDays)} days` : '1 day';

  return (
    <>
      <div
        className={clsx('mt-4 mb-4 p-4 w-full border border-collapse rounded', {
          'bg-dark100 border-dark400':
            !cases.COMPLETED &&
            !cases.CANCELLED &&
            !cases.ARRIVED_AT_DESTINATION &&
            !cases.UNLOADING_COMPLETE,
          'bg-jade100':
            cases.COMPLETED ||
            cases.ARRIVED_AT_DESTINATION ||
            cases.UNLOADING_COMPLETE,
          'bg-crimson100': cases.CANCELLED
        })}
      >
        <div className="flex justify-between">
          <div>
            <div className="flex items-center gap-2">
              <p className="font-medium text-sm text-primaryBlue">
                <span>{shipment.customId}</span>
              </p>
              <div
                className={`flex items-center py-[2px] px-[6px] rounded-[4px] ${bgStyle}`}
              >
                <span
                  className={`text-[10px] font-medium capitalize ${textStyle}`}
                >
                  {cases.AWAITING_CARRIER ||
                  cases.CARRIER_ASSIGNED ||
                  cases.LOADING_IN_PROGRESS
                    ? 'Ready for pickup'
                    : cases.OUT_FOR_DELIVERY
                    ? 'In Transit'
                    : cases.ARRIVED_AT_DESTINATION || cases.UNLOADING_COMPLETE
                    ? 'Arrived at destination'
                    : cases.COMPLETED
                    ? 'Delivered'
                    : 'Cancelled'}
                </span>
              </div>
            </div>
            <div className="flex gap-2 mt-2">
              <span className="text-primaryBlue text-xs">
                {cases.CANCELLED
                  ? `CANCELLED :   ${moment(shipment.updatedAt).format(
                      'DD/MM/YYYY'
                    )}`
                  : cases.COMPLETED
                  ? `Delivered in: ${formattedDuration}`
                  : `EST: ${
                      daysDifference && daysDifference != 'NaN'
                        ? `${daysDifference || 1} days`
                        : '-'
                    }`}
              </span>
              <span className="text-primaryBlue text-xs">
                Cost: {`${getCurrencySymbol(organization.defaultCurrency)}`}{' '}
                {decimalDisplay(shipmentCost)}
              </span>
            </div>
            {shipment.waseeqaNumber && (
              <p className="text-primaryBlue text-xs mt-4 flex gap-4 items-center">
                <span className="font-bold">Waseeqa Number:</span>{' '}
                {shipment.waseeqaNumber}

              </p>
            )}
          </div>
          <div
            className="border-none flex gap-2 items-center"
            sx={{ width: '30%', border: 'none' }}
          >
            <ActionBtns
              refetch={refetch}
              order={order}
              shipment={shipment}
              onAssignCarrier={() => {
                setOpen(true);
                setAction('assign');
              }}
              setWaseeqaPopup={setWaseeqaPopup}
              setPodDownloadOpen={setPodDownloadOpen}
            />
            {shipment.ShipmentLegs[0]?.Vehicle?.trackingLink &&
              shipment?.status?.key !== 'COMPLETED' && (
                <div>
                  <a
                    href={shipment.ShipmentLegs[0]?.Vehicle?.trackingLink}
                    target="_blank"
                  >
                    <img src={PinDropIcon} />
                  </a>
                </div>
              )}
            <OptionsMenu options={options} />
          </div>
        </div>

        <div className="w-full mt-10">
          {cases.AWAITING_CARRIER ? (
            <div className="flex gap-2 justify-center items-center  mb-2">
              <span className="text-xs text-oasisPrimary600 uppercase">
                Awaiting Carrier Details
              </span>
            </div>
          ) : cases.CARRIER_ASSIGNED ? (
            <div className="flex gap-2 justify-center items-center  mb-2">
              <span className="text-xs text-oasisPrimary600 uppercase">
                Carrier Assigned
              </span>
            </div>
          ) : cases.OUT_FOR_DELIVERY ? (
            <div className="flex gap-2 justify-center items-center  mb-2">
              <span className="text-xs text-oasisPrimary600 uppercase">
                Out For Delivery
              </span>
            </div>
          ) : cases.LOADING_IN_PROGRESS ? (
            <div className="flex gap-2 justify-center items-center  mb-2">
              <span className="text-xs text-oasisPrimary600 uppercase">
                Loading
              </span>
            </div>
          ) : cases.ARRIVED_AT_DESTINATION ? (
            <div className="flex gap-2 justify-center items-center  mb-2">
              <span className="text-xs uppercase text-jade700">Reached</span>
            </div>
          ) : cases.UNLOADING_COMPLETE ? (
            <div className="flex gap-2 justify-center items-center  mb-2">
              <span className="text-xs text-jade700 uppercase">Unloaded</span>
            </div>
          ) : cases.CANCELLED ? (
            <div className="flex justify-center mb-2 gap-2">
              <span className="text-xs text-crimson700 uppercase">
                Cancelled By{' '}
                {shipment.cancelledBy?.firstName +
                  ' ' +
                  shipment.cancelledBy?.lastName}
              </span>
            </div>
          ) : (
            <div className="flex gap-2 items-center justify-center mb-2">
              <span className="text-xs uppercase">Completed - </span>
              <span className="text-xs">
                {moment(shipment.updatedAt).format('DD/MM/YYYY')}
              </span>
              <span className="text-xs">
                {moment(shipment.updatedAt).format('hh:mm A')}
              </span>
            </div>
          )}
          <div className="flex items-center w-full gap-2">
            <div className="flex items-center w-[50%]">
              <div
                className={`rounded-full h-[6px] w-[6px] ${
                  cases.COMPLETED ||
                  cases.ARRIVED_AT_DESTINATION ||
                  cases.DOCK_ASSIGNED
                    ? 'bg-jade700'
                    : cases.CANCELLED
                    ? 'bg-crimson500'
                    : 'bg-primary'
                }`}
              />
              <LinearProgressBar
                variant="determinate"
                value={100}
                sx={{
                  backgroundColor: 'white',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor:
                      cases.COMPLETED ||
                      cases.ARRIVED_AT_DESTINATION ||
                      cases.UNLOADING_COMPLETE
                        ? JADE_700
                        : cases.CANCELLED
                        ? CRIMSON_500
                        : PRIMARY
                  },
                  border: `1px ${
                    cases.COMPLETED ||
                    cases.ARRIVED_AT_DESTINATION ||
                    cases.UNLOADING_COMPLETE
                      ? JADE_700
                      : shipment?.status?.key == 'CANCELLED'
                      ? CRIMSON_500
                      : PRIMARY
                  } solid`,
                  borderRadius: '1000px',
                  height: '2px',
                  width: '100%'
                }}
              />
            </div>

            <img
              src={
                mode == 'LAND'
                  ? cases.ARRIVED_AT_DESTINATION || cases.UNLOADING_COMPLETE
                    ? GreenTruckIcon
                    : cases.CANCELLED
                    ? RedTruckIcon
                    : cases.COMPLETED
                    ? DarkTruckIcon
                    : BlueTruckIcon
                  : mode == 'SEA'
                  ? cases.ARRIVED_AT_DESTINATION || cases.UNLOADING_COMPLETE
                    ? GreenShipIcon
                    : cases.CANCELLED
                    ? RedShipIcon
                    : cases.COMPLETED
                    ? DarkShipIcon
                    : ShipIcon
                  : cases.ARRIVED_AT_DESTINATION || cases.UNLOADING_COMPLETE
                  ? GreenAeroPlaneIcon
                  : cases.CANCELLED
                  ? RedAeroplaneIcon
                  : cases.COMPLETED
                  ? DarkAeroplaneIcon
                  : AirplaneIcon
              }
              className="w-[2%]"
            />
            <div className="flex items-center w-[50%]">
              <LinearProgressBar
                variant="determinate"
                value={100}
                sx={{
                  backgroundColor: 'white',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor:
                      cases.COMPLETED ||
                      cases.ARRIVED_AT_DESTINATION ||
                      cases.DOCK_ASSIGNED
                        ? JADE_700
                        : cases.CANCELLED
                        ? CRIMSON_500
                        : PRIMARY
                  },
                  border: `1px ${
                    cases.COMPLETED ||
                    cases.ARRIVED_AT_DESTINATION ||
                    cases.UNLOADING_COMPLETE
                      ? JADE_700
                      : shipment?.status?.key == 'CANCELLED'
                      ? CRIMSON_500
                      : PRIMARY
                  } solid`,
                  borderRadius: '1000px',
                  height: '2px',
                  width: '100%'
                }}
              />
              <div
                className={`rounded-full h-[6px] w-[6px] ${
                  cases.COMPLETED ||
                  cases.ARRIVED_AT_DESTINATION ||
                  cases.UNLOADING_COMPLETE
                    ? 'bg-jade700'
                    : cases.CANCELLED
                    ? 'bg-crimson500'
                    : 'bg-primary'
                }`}
              />
            </div>
          </div>
        </div>
        <div className="mt-1 text-center">
          {!cases.COMPLETED ? (
            <p className="flex gap-2 justify-center items-center">
              <span className="text-xs text-primaryBlue flex-shrink-0 w-2/5 text-right">
                {order.pickupNode?.name || '-'}
              </span>
              {order.dropOffNode?.name && (
                <img
                  src={RightArrowIcon}
                  alt="arrow"
                  className="flex-shrink-0 w-[20px]"
                />
              )}
              <span className="text-xs text-primaryBlue flex-shrink-0 w-2/5 text-left">
                {order.dropOffNode?.name}
              </span>
            </p>
          ) : (
            <p className="flex justify-between items-center w-full">
              <span className="text-xs text-right">
                {order.pickupNode?.name || '-'}
              </span>
              <span className="text-xs text-left">
                {order.dropOffNode?.name}
              </span>
            </p>
          )}
        </div>
      </div>
      {open && (
        <AddShipmentTMS
          shipment={shipment}
          open={open}
          setOpen={setOpen}
          selectedOrder={order}
          edit={true}
          refetch={refetch}
          action={action}
          onClose={() => setAction('edit')}
          editable={
            !(
              (shipment?.status?.key == 'IN_TRANSIT' &&
                shipment.subStatus?.key == 'ARRIVED_AT_DESTINATION') ||
              (shipment?.status?.key == 'IN_TRANSIT' &&
                shipment.subStatus?.key == 'UNLOADING_COMPLETE') ||
              shipment?.status?.key == 'COMPLETED' ||
              shipment?.status?.key == 'CANCELLED'
            )
          }
        />
      )}
      {ShipmentDetail && (
        <ShipmentDetail
          shipmentId={shipment?.customId}
          open={shipmentDetail}
          selectedShipmentMode={shipment?.ShipmentLegs?.[0]?.mode}
          order={order}
          refetch={refetch}
          setOpen={setShipmentDetail}
          onClose={async () => await refetch()}
          editable={
            !(
              (shipment?.status?.key == 'IN_TRANSIT' &&
                shipment.subStatus?.key == 'ARRIVED_AT_DESTINATION') ||
              (shipment?.status?.key == 'IN_TRANSIT' &&
                shipment.subStatus?.key == 'UNLOADING_COMPLETE') ||
              shipment?.status?.key == 'COMPLETED' ||
              shipment?.status?.key == 'CANCELLED'
            )
          }
        />
      )}
      {
        waseeqaPopup && <WaseeqaPopup orderId={order.customId} shipmentId={shipment.customId} setOpen={setWaseeqaPopup} open={waseeqaPopup} refetch={refetch} waseeqaNumber={shipment.waseeqaNumber} />
      }
    </>
  );
};
