/*eslint-disable*/

import { useContext, useEffect, useState } from 'react';
import fileDownload from 'js-file-download';
import mixpanel from 'mixpanel-browser';
import { useFormik } from 'formik';
import moment from 'moment';

import { Stats } from './components/stats';
import { SharedContext } from '../../utils/common';
import { ShipmentsTable } from './components/ShipmentsTable';
import {
  cancelShipmentService,
  createShipment,
  getCarriers,
  getCustomers,
  getDrivers,
  getOrders,
  getShipments,
  getShipmentsExport,
  getShipmentStats,
  getVehicles,
  updateOrderStatus,
  updateShipmentService
} from './services';
import CustomPagination from '../invoiceandbilling/invoiceManagement/CustomPagination';
import Popup from '../../core-components/atoms/Popup';
import AddShipment from '../revampedOrder/AddShipment';
import Button from '../../core-components/atoms/Button';
import { shipmentInitialValues } from '../revampedOrder/OrderManagement';
import AddShipmentValidation from '../revampedOrder/validationSchema/AddShipmentValidation';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FLAGS from '../../constants/featureFlags';
import ShipmentIcon from '../../assets/icons/ShipmentIcon.svg';
import { onError } from '../../libs/errorLib';
import CancelShipment from '../revampedOrder/CancelShipment';
import { toaster } from '../../utils/toaster';
import NewSearchBar from '../../atomicComponents/NewSearchBar';
import AddShipmentTMS from '../revampedOrder/AddShipmentTMS';
import ShipmentDetail from '../revampedOrder/ShipmentDetail';
import DownloadIcon from '../../assets/icons/download.svg';
import { Search } from '@material-ui/icons';
import CustomTextField from '../../core-components/atoms/TextField';
import { InputAdornment } from '@mui/material';
import Filters from './components/Filters';
import { ORGANIZATION_TYPES } from '../../constants';
import { constants } from '../revampedOrder/constants';
import { capitalizeWords } from '../revampedOrder/AddOrder';
import CustomerIcon from '../../assets/icons/account-outline.svg';
import TypeIcon from '../../assets/icons/button-pointer.svg';
import StatusIcon from '../../assets/icons/list-status.svg';
import EditableColumns from '../revampedOrder/components/editColumns';
import API from '../../libs/axios';
import DateRangePickerSingleCalendar from '../revampedOrder/DateRangePicker';

export const ShipmentManagement = () => {
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activeShipment, setActiveShipment] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [shipmentAnchorEl, setShipmentAnchorEl] = useState(null);
  const [view, setView] = useState(false);
  const [activeShipmentOrder, setActiveShipmentOrder] = useState(null);
  const [activeShipmentPopup, setActiveShipmentPopup] = useState(false);
  const [cancelPopUp, setCancelPopUp] = useState(false);
  const [optionsClick, setOptionsClick] = useState(false);
  const [addShipmentPopup, setAddShipmentPopup] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderIdSearch, setOrderIdSearch] = useState('');
  const [selectedOrder, setSelectedOrder] = useState();
  const [shipmentDetailView, setShipmentDetailView] = useState(false);
  const [carriers, setCarriers] = useState([]);
  const [carrierSearch, setCarrierSearch] = useState('');
  const [driverSearch, setDriverSearch] = useState('');
  const [customerSearch, setCustomerSearch] = useState('')
  const [drivers, setDrivers] = useState([]);
  const [stats, setStats] = useState({});
  const [userPreferences, setUserPreferences] = useState({});
  const [vehicles, setVehicles] = useState([]);
  const [companies, setCompanies] = useState([])
  const [currentFilter, setCurrentFilter] = useState({
    Status: [],
    Carrier: [],
    Type: [],
    Driver: [],
    Vehicle: [],
    Company: [],
    podBillingStatuses: []
  });

  const { setCurrentPageTitle, setAPILoader, currentUser, organizationType } =
    useContext(SharedContext);
  const { isFeatureEnabled } = useFeatureFlags();

  const formik = useFormik({
    initialValues: {
      ...shipmentInitialValues
    },
    onSubmit: (values) => {
      // addShipment(values);
    },
    validationSchema: AddShipmentValidation
  });

  const cancelFormik = useFormik({
    initialValues: {
      cancelReason: '',
      cancelReasonComment: ''
    },
    onSubmit: async (values) => {
      await cancelShipment({
        ...values,
        cancelledById: currentUser.id
      });
    }
  });

  const fetchShipments = async () => {
    setAPILoader(true);
    try {
      const response = await getShipments({
        page,
        shipmentId: search,
        status: currentFilter.Status?.map((status) => status.value),
        podStatuses: currentFilter.podBillingStatuses?.map(status => status.value),
        type: currentFilter.Type?.map((type) => type.value),
        carrierIds: currentFilter.Carrier?.map((c) => c.value),
        carrierNames: currentFilter.Carrier?.map((c) => c.label),
        companyIds: currentFilter.Company?.map((c) => c.value),
        driverIds: currentFilter.Driver?.map((d) => d.value),
        vehicleIds: currentFilter.Vehicle.map((v) => v.value),
        startDate: moment.utc(startDate)?.toISOString(),
        endDate: moment
          .utc(endDate)
          .add(23, 'hours')
          .add(59, 'minutes')
          .add(59, 'seconds')
          .toISOString()
      });

      setPages(response.pages);
      setShipments(response.data);
    } catch (e) {
      console.error(e);
    } finally {
      setAPILoader(false);
    }
  };

  const addShipment = async (values) => {
    try {
      const payload = {
        ...values,
        pickupDateTime: new Date(values.pickupDateTime),
        dropoffDateTime: new Date(values.dropoffDateTime)
      };
      setAPILoader(true);
      await createShipment(selectedOrder.customId, {
        shipmentPayload: {
          ...payload
        },
        legsPayload: [],
        hostUrl: window.location.href
      });

      if (selectedOrder?.status == 'PENDING') {
        await updateOrderStatus(selectedOrder.customId, 'IN_PROGRESS');
      }

      toaster('success', 'Shipment created successfully');
      mixpanel.track('Shipment created', { orderId: selectedOrder.customId });
      setSelectedOrder(null);
      setAddShipmentPopup(false);
      formik.resetForm();
    } catch (e) {
      onError(e);
    } finally {
      setAPILoader(false);
    }
  };

  const updateShipment = async (values) => {
    setAPILoader(true);
    try {
      let payload = {
        ...values,
        palletize: values.pallete == 'Palletized'
      };
      await updateShipmentService(
        activeShipmentOrder.customId,
        activeShipment.customId,
        {
          shipmentPayload: {
            ...payload,
            driverName: payload.driver?.name || payload.driverName,
            driverPhone: payload.driver?.phone || payload.driverPhone
          }
        }
      );
      mixpanel.track('Shipment updated', {
        orderId: activeShipmentOrder.customId,
        shipmentId: activeShipment?.customId
      });

      toaster('success', 'Shipment updated successfully!');
    } catch (e) {
      onError(e);
    } finally {
      setAPILoader(false);
    }
  };

  const cancelShipment = async (values) => {
    setAPILoader(true);
    try {
      await cancelShipmentService(
        activeShipmentOrder?.customId,
        activeShipment?.customId,
        { ...values, hostUrl: window.location.href }
      );
      await fetchShipments();

      toaster('success', 'Shipment cancelled successfully');
      mixpanel.track('Shipment cancelled', {
        orderId: activeShipmentOrder.customId,
        shipmentId: activeShipment?.customId
      });
    } catch (e) {
      onError(e);
    } finally {
      setAPILoader(false);
    }
  };

  const fetchOrders = async () => {
    setAPILoader(true);
    try {
      const orderIds = await getOrders(orderIdSearch);
      setOrders(orderIds.data);
    } catch (e) {
    } finally {
      setAPILoader(false);
    }
  };

  const exportToExcel = async () => {
    try {
      const shipments = await getShipmentsExport({
        offset: page,
        search
      });

      fileDownload(shipments, `Shipment ${moment().format('DD-MM-yyyy')}.xlsx`);
      mixpanel.track('Shipment Export');
    } catch (err) {
      onError(err);
    }
  };

  const fetchStats = async () => {
    setAPILoader(true);

    try {
      const stats = await getShipmentStats({
        status: currentFilter.Status?.map((status) => status.value),
        type: currentFilter.Type?.map((type) => type.value),
        carrierIds: currentFilter.Carrier?.map((c) => c.value),
        carrierNames: currentFilter.Carrier?.map((c) => c.label)
      });
      setStats(stats);
    } catch (e) {
    } finally {
      setAPILoader(false);
    }
  };

  const setFilter = (key, value, type, filterType) => {
    console.log(key, value)
    let currentFilters = { ...currentFilter };
    let copiedArr = [...currentFilters[key]];
    if (type === 'add') {
      copiedArr.push(value);
    } else {
      copiedArr = currentFilters[key].filter((v) => v.value != value.value);
    }
    delete currentFilters[key];
    currentFilters[key] = copiedArr;
    setCurrentFilter(currentFilters);
  };

  const fetchCarriers = async (search) => {
    try {
      const carriers = await getCarriers(search);
      setCarriers(carriers.data.rows);
    } catch (e) {}
  };

  const fetchDrivers = async (search) => {
    try {
      const drivers = await getDrivers(search);
      setDrivers(drivers.data);
    } catch (e) {}
  };

  const fetchVehicles = async (search) => {
    try {
      const vehicles = await getVehicles(search);
      setVehicles(vehicles.data);
    } catch (e) {}
  };

  const fetchCompanies = async (search) => {
    try {
      const customers = await getCustomers(search);
      setCompanies(customers.data.rows);
    } catch (e) {}
  };

  const getUserColumnPreference = async () => {
    try {
      const response = await API.get('user-column-preferences/Shipments');
      setUserPreferences(response || {});
    } catch (e) {
      console.error(e);
    }
  };

  const updatePreference = async (col, checked) => {
    try {
      if (checked) {
        let payload = userPreferences
          ? {
              ...userPreferences,
              payload: {
                columns: userPreferences?.payload?.columns
                  ? [...userPreferences.payload.columns, col]
                  : [col]
              }
            }
          : { payload: { columns: [col] } };
        await API.put('user-column-preferences/Shipments', payload);
        await getUserColumnPreference();
        toaster('success', 'User Preference Updated Successfully!');
      } else {
        let payload = {
          ...userPreferences,
          payload: {
            columns: userPreferences?.payload?.columns?.filter(
              (column) => column !== col
            )
          }
        };
        await API.put('user-column-preferences/Shipments', payload);
        await getUserColumnPreference();
        toaster('success', 'User Preference Updated Successfully!');
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setCurrentPageTitle('Shipment Management');
    getUserColumnPreference();
  }, []);

  useEffect(() => {
    fetchShipments();
    fetchStats();
  }, [page, search, currentFilter, startDate, endDate]);

  useEffect(() => {
    fetchCarriers();
  }, [carrierSearch]);

  useEffect(() => {
    fetchCompanies();
  }, [carrierSearch]);

  useEffect(() => {
    fetchDrivers();
  }, [driverSearch]);

  useEffect(() => {
    fetchVehicles();
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [orderIdSearch]);

  let filterOptions = [
    {
      label: 'Status',
      name: 'Status',
      options: [
        {
          label: 'In Transit',
          value: 'IN_TRANSIT'
        },
        {
          label: 'Created',
          value: 'CREATED'
        },
        {
          label: 'Cancelled',
          value: 'CANCELLED'
        },
        {
          label: 'Completed',
          value: 'COMPLETED'
        },
        {
          label: 'Arrived',
          value: 'ARRIVED_AT_DESTINATION'
        },
        {
          label: 'Departure Delayed',
          value: 'DEPARTURE_DELAYED'
        },
        {
          label: 'Arrival Delayed',
          value: 'ARRIVAL_DELAYED'
        },
        {
          label: 'Halted',
          value: 'HALTED'
        },
      ],
      icon: StatusIcon
    },
    {
      label: 'PoD & Billing Statuses',
      name: 'podBillingStatuses',
      icon: StatusIcon,
      options: [
        {
          label: 'PoD Pending',
          value: 'POD_PENDING'
        },
        {
          label: 'PoD Uploaded',
          value: 'POD_UPLOADED'
        },
        {
          label: 'Invoice Generated',
          value: 'INVOICE_GENERATED'
        },
      ]
    },
    {
      label: 'Type',
      name: 'Type',
      options: Object.keys(
        organizationType === ORGANIZATION_TYPES.MANUFACTURER
          ? constants.MANUFACTURER_ORDER_TYPES
          : constants.PL3_ORDER_TYPES
      ).map((key, id) => ({
        value: key,
        label: capitalizeWords(
          organizationType === ORGANIZATION_TYPES.MANUFACTURER
            ? constants.MANUFACTURER_ORDER_TYPES[key]
            : constants.PL3_ORDER_TYPES[key]
        ),
        id
      })),
      icon: TypeIcon
    }
  ];

  filterOptions.push({
    label: 'Carrier',
    name: 'Carrier',
    options: carriers?.map((c) => ({
      label: c.name,
      value: c.id
    })),
    icon: CustomerIcon
  });

  filterOptions.push({
    label: 'Company',
    name: 'Company',
    options: companies?.map((c) => ({
      label: c.name,
      value: c.id
    })),
    icon: CustomerIcon
  });

  filterOptions.push({
    label: 'Driver',
    name: 'Driver',
    options: drivers?.map((d) => ({
      label: d.name,
      value: d.id
    })),
    icon: CustomerIcon
  });

  filterOptions.push({
    label: 'Vehicle',
    name: 'Vehicle',
    options: vehicles?.map((v) => ({
      label: v.registrationNumber,
      value: v.id
    })),
    icon: CustomerIcon
  });

  return (
    <div>
      <Stats stats={stats} />
      <div className="flex items-center justify-end mt-8 mb-8 mr-8 gap-2">
        <CustomTextField
          placeholder="Search table"
          variant="outlined"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          sxProps={{
            '& .MuiInputBase-root': {
              padding: '4px 16px !important',
              fontSize: '12px',
              height: 32
            },
            '& input::placeholder': {
              fontSize: '12px'
            },
            '& input': {
              padding: '0px !important'
            },
            '& textarea::placeholder': {
              fontSize: '12px'
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <Search
                  style={{
                    width: '16px',
                    height: '16px',
                    marginRight: '8px'
                  }}
                />
              </InputAdornment>
            )
          }}
        />
        <DateRangePickerSingleCalendar
          startDate={startDate}
          endDate={endDate}
          updateRange={(input) => {
            setStartDate(input.selection.startDate);
            setEndDate(input.selection.endDate);
          }}
        />
        <Filters
          options={filterOptions}
          filters={currentFilter}
          setFilter={setFilter}
          fetchCarriers={fetchCarriers}
          fetchDrivers={fetchDrivers}
          fetchVehicles={fetchVehicles}
          fetchCompanies={fetchCompanies}
        />
        <Button
          label="+ Create Shipment"
          variant="primary"
          onClick={() => {
            setAddShipmentPopup(true);
          }}
          className={'px-2 py-4 h-8 rounded border-none'}
          overrideSize
          labelClass="font-medium text-xs"
        />
        <EditableColumns
          fixedColumns={[
            'Shipment ID',
            'Origin & Destination',
            'Shipment Type',
            'Shipment Status',
            'Shipment Carrier'
          ]}
          updatePreference={updatePreference}
          variableColumns={[
            'Shipment Mode',
            'Vehicle & Driver',
            'PoD & Billing Status',
            'Creation Date & Time'
          ]}
          editableColumns={
            userPreferences?.payload?.columns
              ? userPreferences?.payload?.columns
              : []
          }
        />

        <Button
          icon={<img src={DownloadIcon} className="mr-[6px]" />}
          className={'p-2 h-8 rounded border-solid'}
          label="Export"
          iconClass="h-[14px] w-[14px]"
          variant="transparent"
          overrideSize={true}
          labelClass="font-medium text-xs "
          onClick={exportToExcel}
        />
      </div>
      <div className="mt-4 ml-6">
        <ShipmentsTable
          shipments={shipments}
          page={page}
          pages={pages}
          activeShipment={activeShipment}
          setActiveShipment={setActiveShipment}
          view={view}
          setView={setView}
          activeShipmentOrder={activeShipmentOrder}
          setActiveShipmentOrder={setActiveShipmentOrder}
          shipmentAnchorEl={shipmentAnchorEl}
          setShipmentAnchorEl={setShipmentAnchorEl}
          setActiveShipmentPopup={setActiveShipmentPopup}
          setCancelPopUp={setCancelPopUp}
          setOptionsClick={setOptionsClick}
          setAddShipmentPopup={setAddShipmentPopup}
          setShipmentDetailView={setShipmentDetailView}
          refetch={() => fetchShipments()}
          extraColumns={userPreferences?.payload?.columns || []}
          filters={currentFilter}
        />
        <CustomPagination page={page} pages={pages} setPage={setPage} />
      </div>

      {shipmentDetailView && (
        <ShipmentDetail
          open={setShipmentDetailView}
          order={activeShipmentOrder}
          selectedShipmentMode={activeShipment?.ShipmentLegs?.[0]?.mode}
          setOpen={setShipmentDetailView}
          shipmentId={activeShipment?.customId}
          onClose={async () => await getShipments()}
        />
      )}

      {!isFeatureEnabled(FLAGS.TMS) &&
      !isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) ? (
        <Popup
          open={activeShipmentPopup}
          setOpen={setActiveShipmentPopup}
          content={
            <div>
              <AddShipment
                order={activeShipmentOrder}
                shipment={activeShipment}
                formik={formik}
                view={view}
              />
            </div>
          }
          actions={
            view ? (
              <div className="flex gap-2">
                <Button
                  label="Close"
                  variant="primary"
                  onClick={async () => {
                    setActiveShipmentOrder(null);
                    setActiveShipment(null);
                    setActiveShipmentPopup(false);
                  }}
                  size="medium"
                />
              </div>
            ) : (
              <div className="flex gap-2">
                <Button
                  label="Cancel"
                  variant="text"
                  onClick={async () => {
                    setActiveShipmentOrder(null);
                    setActiveShipmentPopup(false);
                    setActiveShipment(null);
                  }}
                  size="large"
                />
                <Button
                  label="Save Changes"
                  variant="primary"
                  onClick={async () => {
                    await updateShipment(formik.values);
                    await fetchShipments();
                    setActiveShipmentOrder(null);
                    setActiveShipmentOrder(null);
                    setActiveShipmentPopup(false);
                  }}
                  size="large"
                  disabled={
                    isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
                      ? !formik.values.carrier?.id ||
                        !formik.values.vehicleType?.id
                      : false
                  }
                />
              </div>
            )
          }
          title={
            <div className="flex items-center justify-center gap-2">
              <img src={ShipmentIcon} />
              <span>{activeShipmentOrder?.pickupNode?.name}</span>
              <div className="text-[40px] flex items-center mb-[10px]">
                &#8594;
              </div>
              <span>{activeShipmentOrder?.dropOffNode?.name}</span>
            </div>
          }
          headerClass={'border-b w-full border-b-[#E1E5F0] mb-4'}
          onClose={() => {
            setView(false);
            setActiveShipment(null);
            setActiveShipmentOrder(null);
          }}
        />
      ) : (
        <AddShipmentTMS
          selectedOrder={activeShipmentOrder}
          shipment={activeShipment}
          open={activeShipmentPopup}
          setOpen={setActiveShipmentPopup}
          refetch={() => fetchShipments()}
          edit={true}
          editable={true}
        />
      )}

      <Popup
        open={cancelPopUp}
        setOpen={setCancelPopUp}
        content={<CancelShipment formik={cancelFormik} />}
        actions={
          <div className="flex gap-2">
            <Button
              label={"Don't Cancel"}
              variant="text"
              onClick={async () => {
                setCancelPopUp(false);
                cancelFormik.setValues({
                  cancelReason: '',
                  cancelReasonComment: ''
                });
              }}
              size="large"
            />
            <Button
              label={'Cancel Shipment'}
              variant="primary"
              onClick={async () => {
                await cancelFormik.handleSubmit();
                await fetchShipments();
                cancelFormik.setValues({
                  cancelReason: '',
                  cancelReasonComment: ''
                });
                setCancelPopUp(false);
              }}
              size="large"
              disabled={!cancelFormik.values.cancelReason}
            />
          </div>
        }
        title={`Why are you cancelling shipment ${activeShipment?.customId} ?`}
      />
      {!isFeatureEnabled(FLAGS.TMS) &&
      !isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) ? (
        <Popup
          open={addShipmentPopup}
          setOpen={setAddShipmentPopup}
          content={
            <AddShipment
              formik={formik}
              orders={orders}
              order={selectedOrder}
              setOrder={setSelectedOrder}
              setOrderSearch={setOrderIdSearch}
            />
          }
          actions={
            <div className="flex gap-4">
              <Button
                label="Cancel"
                onClick={() => {
                  setAddShipmentPopup(false);
                  formik.resetForm();
                  setSelectedOrder(null);
                }}
              />
              <Button
                label="Create"
                onClick={async () => await addShipment(formik.values)}
                variant="primary"
              />
            </div>
          }
        />
      ) : (
        <AddShipmentTMS
          selectedOrder={selectedOrder}
          open={addShipmentPopup}
          setOrderSearch={setOrderIdSearch}
          setSelectedOrder={setSelectedOrder}
          setOpen={setAddShipmentPopup}
          orders={orders}
          refetch={() => fetchShipments()}
          onClose={() => setSelectedOrder(null)}
        />
      )}
    </div>
  );
};
