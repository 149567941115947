/*eslint-disable*/
import React, { useEffect, useRef } from 'react';
import {
  Popover,
  List,
  ListItem,
  ListItemText,
  FormControl
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { Checkbox, IconButton, InputAdornment } from '@mui/material';
import Button from '../../../core-components/atoms/Button';
import TextField from '../../../core-components/atoms/TextField';
import { makeStyles } from '@material-ui/styles';
import PartiallyCompleteIcon from '../../../assets/icons/partial_complete.svg';
import { icons } from '../../revampedOrder/statusIcons';

function Filters({
  options,
  setFilter,
  number,
  filters,
  fetchDrivers,
  fetchCarriers,
  fetchVehicles,
  fetchCompanies
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState(null);
  const [activeIndex, setActiveIndex] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const mainRef = useRef();
  const secondaryRef = useRef();

  const handleClick = (event) => {
    event.stopPropagation();
    if (!anchorEl) setAnchorEl(event.currentTarget);
    else {
      handleClose();
    }
  };

  const handleSubMenuClick = (event, index) => {
    setSubMenuAnchorEl(event.currentTarget.parentNode);
    setActiveIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubMenuClose = () => {
    setSubMenuAnchorEl(null);
    setSearch('');
  };

  const useStyles = makeStyles((theme) => ({
    ListItem: {
      '&:hover': {
        backgroundColor: '#F1F8FF'
      },
      cursor: 'pointer',
      display: 'flex',
      gap: '8px'
    },
    popOver: {
      maxHeight: '500px',
      '& ::-webkit-scrollbar': {
        width: '0.3rem'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      },
      pointerEvents: 'none'
    },
    search: {
      margin: '1rem auto',
      width: '90%',
      boxSizing: 'border-box',
      display: 'block'
    },
    paper: {
      pointerEvents: 'auto',
      width: '210px',
      border: '1px solid #E7E6E6',
      height: '250px',
      marginTop: '12px',
      marginRight: '16px'
    },
    subMenuPaper: {
      pointerEvents: 'auto',
      border: '1px solid #E7E6E6',
      height: '300px'
    }
  }));

  const classes = useStyles();

  const open = Boolean(anchorEl);
  const subMenuOpen = Boolean(subMenuAnchorEl);

  useEffect(() => {
    if (activeIndex && options[activeIndex].label === 'Carrier' && search) {
      fetchCarriers(search);
    } else if (
      activeIndex &&
      options[activeIndex].label === 'Company' &&
      search
    ) {
      fetchCompanies(search);
    } else if (
      activeIndex &&
      options[activeIndex].label === 'Company' &&
      search
    ) {
      fetchCompanies(search);
    } else if (
      activeIndex &&
      options[activeIndex].label === 'Driver' &&
      search
    ) {
      fetchDrivers(search);
    } else if (
      activeIndex &&
      options[activeIndex].label === 'Vehicle' &&
      search
    ) {
      fetchVehicles(search);
    } else {
      fetchCarriers();
      fetchDrivers();
      fetchVehicles();
      fetchCompanies();
    }
  }, [search]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        mainRef.current &&
        !mainRef.current.contains(event.target) &&
        secondaryRef.current &&
        !secondaryRef.current.contains(event.target)
      ) {
        handleClose();
        handleSubMenuClose();
      }

      if (
        mainRef.current &&
        !mainRef.current.contains(event.target) &&
        !secondaryRef.current
      ) {
        handleClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <Button
        onClick={handleClick}
        variant="tertiary"
        icon={<FilterListIcon sx={{ marginRight: '8px' }} fontSize="small" />}
        label="Filters"
        className={'p-2 h-8 rounded border-solid'}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        ref={mainRef}
        style={{ pointerEvents: 'none' }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        classes={{
          paper: classes.paper
        }}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      >
        <List>
          <p className="text-xs font-medium text-[#67718C] px-4 py-2 ">
            APPLY FILTERS TO THE TABLE
          </p>
          {options.map((option, i) => (
            <ListItem
              className={classes.ListItem}
              onMouseEnter={(e) => handleSubMenuClick(e, i)}
            >
              <img src={option.icon} />
              <ListItemText primary={option.label} />
            </ListItem>
          ))}
        </List>
      </Popover>
      <Popover
        open={subMenuOpen}
        anchorEl={subMenuAnchorEl}
        onClose={handleSubMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        className={classes.popOver}
        classes={{
          paper: classes.subMenuPaper
        }}
        PaperProps={{
          onMouseLeave: handleSubMenuClose
        }}
        ref={secondaryRef}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      >
        <List style={{ width: '300px' }}>
          {activeIndex &&
          (options[activeIndex]?.name === 'Carrier' ||
            options[activeIndex]?.name === 'Vehicle' ||
            options[activeIndex]?.name === 'Driver') ? (
            <ListItem>
              <TextField
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                fullWidth
              />
            </ListItem>
          ) : null}
          {(activeIndex || activeIndex == 0) &&
            options[activeIndex]?.options.map((option, i) => (
              <ListItem
                className={classes.ListItem}
                onClick={(e) => {
                  let checked = filters?.[options[activeIndex].name]?.reduce(
                    (a, b) => a || b.value == option.value,
                    false
                  );
                  if (checked) {
                    setFilter(options[activeIndex].name, option, 'remove');
                  } else {
                    setFilter(options[activeIndex].name, option, 'add');
                  }
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    e.stopPropagation();
                    if (e.target.checked) {
                      setFilter(options[activeIndex].name, option, 'add');
                    } else {
                      setFilter(options[activeIndex].name, option, 'remove');
                    }
                  }}
                  checked={filters?.[options[activeIndex].name]?.reduce(
                    (a, b) => a || b.value == option.value,
                    false
                  )}
                />
                {(options[activeIndex]?.label == 'Status' ||
                  options[activeIndex]?.label == 'PoD & Billing Statuses') &&
                  (option.value == 'PARTIALLY_COMPLETE' ||
                  option.value == 'LOAD_DISPATCHED' ? (
                    <img src={PartiallyCompleteIcon} />
                  ) : (
                    <div className={icons[option.value]} />
                  ))}
                <ListItemText primary={option.label} />
              </ListItem>
            ))}
        </List>
      </Popover>
    </div>
  );
}

export default Filters;
