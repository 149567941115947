/*eslint-disable*/
import { FormControl, Modal, TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { CloseOutlined } from '@material-ui/icons';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import BackIcon from '../../assets/icons/chevronLeft.svg';

import FixedLayout from '../../core-components/molecules/FixedLayout';
import { ShipmentMode } from './constants';
import ShipmentHead from './ShipmentHead';
import ShipmentStatsSecondary from './ShipmentStatsSecondary';
import LegMap from './LegMap';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LandShipmentForm from './LandShipmentForm';
import Button from '../../core-components/atoms/Button';
import { useFormik } from 'formik';
import { calculateEuclideanDistance, SharedContext } from '../../utils/common';
import API from '../../libs/axios';
import { toaster } from '../../utils/toaster';
import { logError, onError } from '../../libs/errorLib';
import SeaShipmentForm from './SeaShipmentForm';
import AirShipmentForm from './AirShipmentForm';
import { GOOGLE_MAP_API_KEY } from '../../constants';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FLAGS from '../../constants/featureFlags';
import mixpanel from 'mixpanel-browser';
import { getSchedules } from './services';
import Popup from '../../core-components/atoms/Popup';
import RightArrow from '../../assets/icons/rightArrow.svg';
import ScheduleTable from './components/ScheduleTable';
import LegIcon from '../../assets/icons/shipment.svg';
import ShipIcon from '../../assets/icons/ShipIcon.svg';
import AirplaneIcon from '../../assets/icons/AirplaneIcon.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendarIconSmall.svg';
import { Paragraph } from '../../core-components/atoms/Text';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import CustomTextField from '../../core-components/atoms/TextField';
import { TransitNodeSelect } from './components/TransitNodeSelect';
import { checkPermission } from '../../utils/auth';

const AddShipmentTMS = ({
  open,
  setOpen,
  selectedOrder,
  shipment,
  edit,
  refetch,
  editable,
  action = 'edit',
  onClose,
  orders,
  setSelectedOrder,
  setOrderSearch
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { setAPILoader } = useContext(SharedContext);
  const [selectedShipmentMode, setShipmentMode] = useState(
    edit
      ? shipment?.ShipmentLegs?.[0]?.mode
      : selectedOrder?.dropOffNode?.portType || 'LAND'
  );
  const [schedules, setSchedules] = useState([]);
  const [scheduleView, setScheduleView] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [transitOrigin, setTransitOrigin] = useState(null);
  const [transitDestination, setTransitDestination] = useState(null);
  const [distance, setDistance] = useState(0)

  const [submitting, setSubmitting] = useState(false);
  const [payloadWeightErr, setPayloadWeightErr] = useState('');
  const [map, setMap] = useState();
  let totalVolume = selectedOrder?.OrderProductGroups?.reduce(
    (acc, og) =>
      og.Product.dimensionsCBM *
        (og.originalOrderedQuantity || og.orderedQuantity) +
      acc,
    0
  );

  if (totalVolume) {
    totalVolume = (totalVolume / 1000000).toFixed(3);
  }
  const formik = useFormik({
    initialValues: {
      carrier: {},
      vehicleType: {},
      vehicle: {},
      driver: {},
      volume: totalVolume ?? null,
      weight: selectedOrder?.calculatedWeight ?? null
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      await submitForm(values);
      await refetch();
    }
  });

  const submitForm = async (values) => {
    if (values.carrier?.id) {
      if (selectedShipmentMode == 'LAND') {
        if (!values.vehicleType?.id) {
          toaster('error', 'Vehicle Type cannot be empty');
          return;
        }

        if (
          isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) &&
          !isFeatureEnabled(FLAGS.TMS)
        ) {
          if (!values.carrier?.id) {
            toaster('error', 'Carrier cannot be empty');
            return;
          }
          if (!values.vehicleType?.id) {
            toaster('error', 'Vehicle type cannot be empty');
            return;
          }
        } else {
          if (!values.vehicle?.id) {
            toaster('error', 'Vehicle cannot be empty');
            return;
          }

          if (!values.driver?.id) {
            toaster('error', 'Driver cannot be empty');
            return;
          }

          if (!values.carrier?.id) {
            toaster('error', 'Carrier cannot be empty');
            return;
          }
          if (!values.vehicleType?.id) {
            toaster('error', 'Vehicle type cannot be empty');
            return;
          }
        }
      }
    }

    const payload = {
      mode: selectedShipmentMode,
      pickupDate: values?.pickupDate || null,
      dropoffDate: values?.dropoffDate || null,
      driverName: values?.driverName,
      vehicleNumber: values.vehicleNumber,
      ...(values?.carrier?.name &&
        !values?.carrier?.id && { carrierName: values?.carrier?.name }),
      ...(values?.carrier?.id && { carrierId: values?.carrier?.id }),
      ...(values?.vehicleType?.id &&
        selectedShipmentMode == 'LAND' && {
          vehicleTypeId: values?.vehicleType.id
        }),
      ...(values?.vehicle?.id &&
        selectedShipmentMode == 'LAND' && { vehicleId: values?.vehicle?.id }),
      ...(values?.driver?.id &&
        selectedShipmentMode == 'LAND' && { driverId: values?.driver?.id }),
      ...(values?.weight && { weight: values?.weight }),
      ...(values?.containerNumber && {
        containerNumber: values?.containerNumber
      }),
      ...(values?.trackingLink && { trackingLink: values?.trackingLink }),
      ...(values?.volume && { volume: values?.volume }),
      ...(values?.sellingPrice && { sellingPrice: values?.sellingPrice }),
      ...(values?.hblNumber && { hblNumber: values?.hblNumber }),
      ...(values?.mblNumber && { mblNumber: values?.mblNumber }),
      ...(values?.flightNumber && { flightNumber: values?.flightNumber }),
      ...(values?.carrierCost && { carrierCost: values?.carrierCost }),
      ...(values?.sealNumber && { sealNumber: values?.sealNumber }),
      ...(values?.vesselName && { vesselName: values?.vesselName }),
      ...(values?.waseeqaNumber && { waseeqaNumber: values?.waseeqaNumber })
    };
    try {
      setAPILoader(true);
      setSubmitting(true);
      const distance = await calculateEuclideanDistance(
        selectedOrder?.pickupNode?.locationLatLng,
        selectedOrder?.dropOffNode?.locationLatLng
      );
      const speed = formik.values.vehicle?.avgSpeed;
      const mileage = formik.values.vehicle?.mileage;
      const fuelPrice =
        formik.values.vehicle?.VehicleMaintenances?.[0]?.fuelPrice;

      if (!edit) {
        await API.post(`orders/${selectedOrder.customId}/shipments`, {
          shipmentPayload: {
            weight: payload.weight,
            pickupDateTime: payload.pickupDate ?? null,
            dropoffDateTime: payload.dropoffDate ?? null,
            sellingPrice: Number(values.sellingPrice),
            sealNumber: payload.sealNumber,
            carrierName: payload.carrierName ?? null,
            transitDestinationId: transitDestination?.id,
            transitOriginId: transitOrigin?.id,
            estimatedDistance: distance || null,
            waseeqaNumber: payload.waseeqaNumber,
            estimatedTime:
              distance && speed
                ? parseFloat(distance / speed).toFixed(3)
                : null,
            estimatedFuelConsumption:
              distance && mileage
                ? parseFloat(distance / mileage).toFixed(3)
                : null,
            estimatedFuelCost:
              fuelPrice & distance && mileage
                ? parseFloat((distance / mileage) * fuelPrice).toFixed(3)
                : null,
            trackingLink: payload.trackingLink
          },
          legsPayload: [{ ...payload }],
          hostUrl: window.location.href
        });
      } else {
        await API.put(
          `orders/${selectedOrder.customId}/shipments/${shipment.customId}`,
          {
            shipmentPayload: {
              weight: payload.weight,
              pickupDateTime: payload.pickupDate || null,
              dropoffDateTime: payload.dropoffDate || null,
              sellingPrice: Number(values.sellingPrice),
              sealNumber: payload.sealNumber,
              carrierName: payload.carrierName ?? null,
              transitDestinationId: transitDestination?.id,
              transitOriginId: transitOrigin?.id,
              estimatedDistance: distance || null,
              waseeqaNumber: payload.waseeqaNumber,
              estimatedTime:
                distance && speed
                  ? parseFloat(distance / speed).toFixed(3)
                  : null,
              estimatedFuelConsumption:
                distance && mileage
                  ? parseFloat(distance / mileage).toFixed(3)
                  : null,
              estimatedFuelCost:
                fuelPrice & distance && mileage
                  ? parseFloat((distance / mileage) * fuelPrice).toFixed(3)
                  : null,
              trackingLink: payload.trackingLink
            },
            legsPayload: [{ ...payload, id: shipment?.ShipmentLegs?.[0].id }],
            hostUrl: window.location.href
          }
        );
      }
      setOpen(false);
      formik.resetForm();
      onClose && onClose();
      toaster(
        'success',
        edit ? 'Shipment has been updated' : 'Shipment has been created.'
      );
      await refetch();
      mixpanel.track(`Shipment   ${edit ? 'updated' : 'created'}`, {
        orderId: selectedOrder?.customId,
        shipmentId: shipment?.customId
      });
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
      setSubmitting(false);
    }
  };

  useEffect(async () => {
    if (shipment) {
      setAPILoader(true);
      formik.setValues({ ...shipment?.ShipmentLegs?.[0] });
      formik.setFieldValue('sealNumber', shipment.sealNumber);
      formik.setFieldValue('weight', shipment?.ShipmentLegs?.[0]?.weight);
      formik.setFieldValue('sellingPrice', shipment.sellingPrice);
      formik.setFieldValue('waseeqaNumber', shipment?.waseeqaNumber)
      formik.setFieldValue(
        'carrierCost',
        shipment?.ShipmentLegs?.[0]?.Expense?.cost
      );
      if (shipment?.ShipmentLegs?.[0]?.carrierId) {
        const carrier = await getCarrier(
          shipment?.ShipmentLegs?.[0]?.carrierId
        );
        formik.setFieldValue('carrier', carrier?.company);
      }
      if (shipment?.ShipmentLegs?.[0]?.vehicleTypeId) {
        const vehicleType = await getVehicleType(
          shipment?.ShipmentLegs?.[0]?.vehicleTypeId
        );
        formik.setFieldValue('vehicleType', vehicleType);
      }
      if (shipment?.ShipmentLegs?.[0]?.vehicleId) {
        const vehicleType = await getVehicle(
          shipment?.ShipmentLegs?.[0]?.vehicleId
        );
        formik.setFieldValue('vehicle', vehicleType?.vehicle);
      }

      if (shipment?.ShipmentLegs?.[0]?.driverId) {
        const driver = await getDriver(shipment?.ShipmentLegs?.[0]?.driverId);
        formik.setFieldValue('driver', driver?.driver);
      }
      if (shipment?.ShipmentLegs?.[0]?.vesselName) {
        formik.setFieldValue(
          'vessleName',
          shipment?.ShipmentLegs?.[0]?.vesselName
        );
      }
      if (shipment?.TransitDestination?.id) {
        setTransitDestination(shipment?.TransitDestination);
      }
      if (shipment?.TransitOrigin?.id) {
        setTransitOrigin(shipment?.TransitOrigin);

        if (shipment.trackingLink) {
          formik.setFieldValue('trackingLink', shipment.trackingLink);
        }
        setAPILoader(false);
      }
    }
  }, [shipment]);

  useEffect(() => {
    if (formik.values.trackingLink) formik.setFieldValue('trackingLink', null);
    formik.setFieldValue('carrier', null);
    setTransitDestination(null);
    setTransitOrigin(null);
  }, [selectedShipmentMode]);

  const calculateDistance = async () => {
    if (selectedOrder?.pickupNode?.locationLatLng && selectedOrder?.dropOffNode?.locationLatLng) {
      const distance = await calculateEuclideanDistance(
        selectedOrder?.pickupNode?.locationLatLng,
        selectedOrder?.dropOffNode?.locationLatLng
      )
      setDistance(distance)
    }
    else {
      setDistance(0)
    }
  }

  useEffect(() => {
    calculateDistance()
  }, [selectedOrder])

  const getCarrier = async (id) => {
    try {
      const vendor = await API.get(`companies/${id}`, {});
      return vendor;
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const getVehicleType = async (id) => {
    try {
      const vehicleType = await API.get(`vehicles/types/${id}`, {});
      return vehicleType;
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const getVehicle = async (id) => {
    try {
      const vehicleType = await API.get(`vehicles/${id}`, {});
      return vehicleType;
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const fetchSchedules = async () => {
    setAPILoader(true);
    try {
      const schedules = await getSchedules(
        transitOrigin?.id,
        transitDestination?.id,
        scheduleDate,
        formik.values.mblNumber,
        formik.values.vesselName,
        selectedShipmentMode
      );
      setSchedules(schedules.data);

      if (formik.values.mblNumber || formik.values.vesselName) {
        if (schedules.data?.[0]) {
          let schedule = schedules.data?.[0];
          formik.setFieldValue('carrier', {
            name: schedule.carrierName
          });
          formik.setFieldValue('pickupDate', schedule.startDate);
          formik.setFieldValue('dropoffDate', schedule.endDate);
          formik.setFieldValue('carrierCost', schedule.price);
          formik.setFieldValue('vesselName', schedule.vesselName);
        }
      }
    } catch (e) {
      logError(e);
    } finally {
      setAPILoader(false);
    }
  };

  const getDriver = async (id) => {
    try {
      const driver = await API.get(`drivers/${id}`, {});
      return driver;
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const render = (status) => {
    switch (status) {
      case Status.LOADING:
        return (
          <Box className="w-100 flex justify-center items-center">
            <CircularProgress />
          </Box>
        );
      case Status.FAILURE:
        return <div>Error!</div>;
      case Status.SUCCESS:
        return transitOrigin &&
          transitDestination &&
          selectedShipmentMode == 'SEA' ? (
          <LegMap
            originAddress={transitOrigin?.address}
            destinationAddress={transitDestination?.address}
            origin={transitOrigin?.locationLatLng}
            destination={transitDestination?.dropOffNode?.locationLatLng}
            open={open}
            map={map}
            setMap={setMap}
            selectedShipmentMode={selectedShipmentMode}
            key={`${transitOrigin?.address}-${transitDestination?.address}`}
          />
        ) : (
          <LegMap
            originAddress={selectedOrder?.pickupNode?.address}
            destinationAddress={selectedOrder?.dropOffNode?.address}
            origin={selectedOrder?.pickupNode?.locationLatLng}
            destination={selectedOrder?.dropOffNode?.locationLatLng}
            open={open}
            map={map}
            setMap={setMap}
            selectedShipmentMode={selectedShipmentMode}
            key={`${selectedOrder?.pickupNode?.address}-${selectedOrder?.dropOffNode?.address}`}
          />
        );
    }
  };

  const renderTransitMap = (status) => {
    switch (status) {
      case Status.LOADING:
        return (
          <Box className="w-100 flex justify-center items-center">
            <CircularProgress />
          </Box>
        );
      case Status.FAILURE:
        return <div>Error!</div>;
      case Status.SUCCESS:
        return transitOrigin && transitDestination ? (
          <LegMap
            originAddress={transitOrigin?.address}
            destinationAddress={transitDestination?.address}
            origin={transitOrigin?.locationLatLng}
            destination={transitDestination?.locationLatLng}
            open={open}
            map={map}
            setMap={setMap}
            selectedShipmentMode={selectedShipmentMode}
          />
        ) : (
          <LegMap
            originAddress={selectedOrder?.pickupNode?.address}
            destinationAddress={selectedOrder?.dropOffNode?.address}
            origin={selectedOrder?.pickupNode?.locationLatLng}
            destination={selectedOrder?.dropOffNode?.locationLatLng}
            open={open}
            map={map}
            setMap={setMap}
            selectedShipmentMode={selectedShipmentMode}
          />
        );
    }
  };

  useEffect(() => {
    if (selectedShipmentMode !== ShipmentMode.LAND) {
      fetchSchedules();
      if (transitOrigin && transitDestination) setMap(map);
    } else {
      setSchedules([]);
    }
  }, [
    selectedShipmentMode,
    scheduleDate,
    formik.values.mblNumber,
    transitOrigin,
    transitDestination,
    formik.values.vesselName
  ]);

  useEffect(() => {
    if (edit) {
      setShipmentMode(shipment?.ShipmentLegs?.[0]?.mode);
    } else {
      setShipmentMode(selectedOrder?.dropOffNode?.portType || 'LAND');
    }
  }, [shipment]);

  let origin = selectedOrder?.pickupNode?.name;
  let destination = selectedOrder?.dropOffNode?.name;

  return (
    <>
      <Modal
        sx={{
          minHeight: '100%',
          minWidth: '100%',
          background: 'white',
          border: 'none',
          overflowY: 'auto',
          margin: 0,
          boxShadow: 'none',
          outline: 'none'
        }}
        open={open}
        BackdropProps={{ style: { backgroundColor: 'white' } }}
        onClose={() => {
          formik.resetForm();
          setTransitDestination(null);
          setTransitOrigin(null);
          onClose && onClose();
        }}
      >
        <div>
          <form onSubmit={() => {}}>
            <FixedLayout
              showFooter={true}
              header={
                <>
                  <div className="flex justify-between w-full items-center px-6">
                    <div className="flex items-center">
                      <img
                        src={BackIcon}
                        alt="back"
                        onClick={() => {
                          setOpen(false);
                          formik.resetForm();
                        }}
                        className="mr-1"
                      />
                      <p className="font-semibold text-lg text-primaryBlue">
                        {edit
                          ? action == 'edit'
                            ? `Update Shipment ${shipment?.customId}`
                            : `Assign Carrier Details for Shipment ${shipment?.customId}`
                          : 'Add Carrier Details to this Shipment'}
                      </p>
                    </div>
                    <div>
                      <CloseOutlined
                        onClick={() => {
                          setOpen(false);
                          formik.resetForm();
                          onClose && onClose();
                        }}
                        className="cursor-pointer text-[20px] text-dark400"
                      />
                    </div>
                  </div>
                </>
              }
              content={
                <>
                  <div className="w-full flex justify-between h-fit">
                    <div className="mt-4 ml-8 w-fit h-fit">
                      <ShipmentHead
                        selectedShipmentMode={selectedShipmentMode}
                        setShipmentMode={setShipmentMode}
                        origin={origin || ''}
                        destination={destination || ''}
                        edit={edit}
                      />
                      {selectedShipmentMode === ShipmentMode.LAND && (
                        <LandShipmentForm
                          formik={formik}
                          payloadWeightErr={payloadWeightErr}
                          setPayloadWeightErr={setPayloadWeightErr}
                          edit={edit}
                          editable={editable}
                          orders={orders}
                          selectedOrder={selectedOrder}
                          setOrderSearch={setOrderSearch}
                          setSelectedOrder={setSelectedOrder}
                          order={selectedOrder}
                          distance={distance}
                        />
                      )}
                      {selectedShipmentMode === ShipmentMode.SEA && (
                        <SeaShipmentForm
                          formik={formik}
                          edit={edit}
                          editable={editable}
                          orders={orders}
                          selectedOrder={selectedOrder}
                          setOrderSearch={setOrderSearch}
                          setSelectedOrder={setSelectedOrder}
                          order={selectedOrder}
                          transitOrigin={transitOrigin}
                          transitDestination={transitDestination}
                          setTransitOrigin={setTransitOrigin}
                          setTransitDestination={setTransitDestination}
                        />
                      )}
                      {selectedShipmentMode === ShipmentMode.AIR && (
                        <AirShipmentForm
                          formik={formik}
                          edit={edit}
                          editable={editable}
                          orders={orders}
                          selectedOrder={selectedOrder}
                          setOrderSearch={setOrderSearch}
                          setSelectedOrder={setSelectedOrder}
                          order={selectedOrder}
                        />
                      )}

                      {selectedShipmentMode !== 'LAND' && (
                        <Button
                          label="View Schedules"
                          onClick={() => setScheduleView(true)}
                          variant="primary"
                        />
                      )}

                      <Popup
                        open={scheduleView}
                        setOpen={setScheduleView}
                        content={
                          <div className="min-w-[600px]">
                            <div className="flex justify-between items-center mt-10">
                              <div className="flex gap-8">
                                <TransitNodeSelect
                                  value={transitOrigin}
                                  onChange={(value) => setTransitOrigin(value)}
                                  label="Origin"
                                  mode={selectedShipmentMode}
                                />
                                <TransitNodeSelect
                                  value={transitDestination}
                                  onChange={(value) =>
                                    setTransitDestination(value)
                                  }
                                  label="Destination"
                                  mode={selectedShipmentMode}
                                />
                              </div>
                              <FormControl>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <DatePicker
                                    components={{
                                      OpenPickerIcon: CalendarIcon
                                    }}
                                    renderInput={(params) => {
                                      const { label, ...props } = params;
                                      return (
                                        <CustomTextField
                                          {...props}
                                          onBlur={formik.handleBlur}
                                          size="small"
                                        />
                                      );
                                    }}
                                    inputFormat="dd/MM/yyyy"
                                    value={scheduleDate || null}
                                    label="Date"
                                    onChange={(newValue) => {
                                      setScheduleDate(newValue);
                                    }}
                                  />
                                </LocalizationProvider>
                              </FormControl>
                            </div>
                            <ScheduleTable
                              schedules={schedules}
                              shipmentMode={selectedShipmentMode}
                              onRowClick={(schedule) => {
                                setAPILoader(true);
                                formik.setFieldValue('carrier', {
                                  name: schedule.carrierName
                                });
                                formik.setFieldValue(
                                  'pickupDate',
                                  schedule.startDate
                                );
                                formik.setFieldValue(
                                  'dropoffDate',
                                  schedule.endDate
                                );
                                formik.setFieldValue(
                                  'carrierCost',
                                  schedule.price
                                );
                                formik.setFieldValue(
                                  'vesselName',
                                  schedule.vesselName
                                );
                                setAPILoader(false);
                                setScheduleView(false);
                              }}
                              showSchedules={
                                transitOrigin?.id && transitDestination?.id
                              }
                            />
                          </div>
                        }
                        title={
                          <div className="ml-2 flex items-center mb-3 gap-4">
                            {
                              <img
                                src={
                                  selectedShipmentMode == 'SEA'
                                    ? ShipIcon
                                    : AirplaneIcon
                                }
                              />
                            }
                            <p className="text-primaryBlue font-semibold text-base">
                              {origin?.length > 20
                                ? `${origin.substring(0, 20)}...`
                                : origin}
                            </p>
                            <img
                              src={RightArrow}
                              alt="right"
                              className="mx-2"
                            />
                            <p className="text-primaryBlue font-semibold text-base">
                              {destination?.length > 20
                                ? `${destination.substring(0, 20)}...`
                                : destination}
                            </p>
                          </div>
                        }
                        actions={
                          <div className="flex gap-4 mr-4">
                            <Button
                              label="Cancel"
                              onClick={() => setScheduleView(false)}
                            />
                          </div>
                        }
                      />
                    </div>
                    <div className="w-fit h-full mt-8 mr-[42px]">
                      {map && selectedOrder && (
                        <ShipmentStatsSecondary
                          id={selectedOrder?.customId}
                          selectedShipmentId={shipment?.customId}
                          selectedShipmentMode={selectedShipmentMode}
                          weight={formik.values.weight}
                          distance={distance}
                          shipment={shipment}
                          selectedOrder={selectedOrder}
                          speed={formik.values.vehicle?.avgSpeed || null}
                          mileage={formik.values.vehicle?.mileage}
                          fuelPrice={
                            formik.values.vehicle?.VehicleMaintenances?.[0]
                              ?.fuelPrice
                          }
                        />
                      )}
                      <div className="flex h-[552px] w-[640px] border-dark400 border rounded mt-[25px] overflow-hidden">
                        {transitOrigin && transitDestination ? (
                          <Wrapper
                            apiKey={GOOGLE_MAP_API_KEY}
                            version="beta"
                            libraries={['marker', 'geometry', 'routes']}
                            render={renderTransitMap}
                          />
                        ) : (
                          <Wrapper
                            apiKey={GOOGLE_MAP_API_KEY}
                            version="beta"
                            libraries={['marker', 'geometry', 'routes']}
                            render={render}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </>
              }
              footer={
                <>
                  <div className="flex justify-between items-center w-full pr-6 z-50">
                    <Button
                      label="Cancel"
                      variant="transparent"
                      className={'p-2 ml-2 h-8 rounded border-solid'}
                      overrideSize={true}
                      labelClass="font-medium text-xs "
                      onClick={async () => {
                        await formik.resetForm();
                        onClose && onClose();
                        setOpen(false);
                      }}
                    />

                    {(isFeatureEnabled(FLAGS.BASIC_SHIPMENT_CREATION) ||
                      isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)) && (
                      <div className="flex justify-between">
                        <Button
                          label={edit ? 'Update Shipment' : 'Create Shipment'}
                          className={
                            'py-2 px-3 ml-[7px] h-8 border-0 rounded w-[115px]'
                          }
                          variant="primary"
                          overrideSize={true}
                          labelClass="font-medium text-xs"
                          onClick={() => submitForm(formik.values)}
                          disabled={submitting}
                        />
                      </div>
                    )}
                  </div>
                </>
              }
            ></FixedLayout>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AddShipmentTMS;
