/*eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import { SharedContext } from '../../../utils/common';
import CustomPagination from './CustomPagination';
import clsx from 'clsx';
import { Label } from '../../../core-components/atoms/Text';
import moment from 'moment';
import CustomTextField from '../../../core-components/atoms/TextField';
import { Close, Search, MoreVert } from '@material-ui/icons';
import { Box, IconButton, InputAdornment, Menu, MenuItem } from '@mui/material';
import { CreditNotePopup } from './CreditNotePopup';
import Popup from '../../../core-components/atoms/Popup';
import { checkPermission } from '../../../utils/auth';

const useStyles = makeStyles(() => ({
  parentContainer: {
    padding: '20px',
    backgroundColor: '#F6F7FB'
  },
  container: {
    padding: 20
  }
}));

export default function CreditNotes({
  type,
  page,
  setPage,
  creditNotes,
  pageCount,
  search,
  setSearch,
  refetch
}) {

  function openFileInNewTab(url) {
    const newTab = window.open(url, '_blank');
    newTab.focus();
  }

  const columnNames = [
    'Credit Note #',
    'Customer Name',
    'Invoice Number',
    'Creation Date',
    'Amount',
    'Reason',
    'Status',
    ''
  ];

  return (
    <div className="w-full">
      <div className="flex justify-end">
        <CustomTextField
          placeholder="Search"
          variant="outlined"
          color={'primary'}
          size="small"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          inputProps={{
            onFocus: () => search
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <Search
                  style={{
                    width: '20px',
                    height: '20px',
                    marginRight: '10px'
                  }}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {search && (
                  <Close
                    style={{
                      width: '20px',
                      height: '20px',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setSearch('');
                    }}
                  />
                )}
              </InputAdornment>
            )
          }}
        />
      </div>
      <div>
        <Paper className="m-6">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columnNames.map((name, index) => (
                    <TableCell key={index} className="bg-dark50">
                      {name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {creditNotes.map((note, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <div className="flex gap-4 items-center cursor-pointer" onClick={() => {
                        openFileInNewTab(note?.link);
                      }}
                      >
                        <div
                          className={clsx(
                            'flex items-center justify-center rounded h-[30px] w-[30px]',
                            'bg-[#CE375C]'
                          )}
                        >
                          <Label variant={'xs'} className={'text-white'}>
                            PDF
                          </Label>
                        </div>
                        {note.number}
                      </div>
                    </TableCell>
                    <TableCell>{note.Invoice?.Company?.name}</TableCell>
                    <TableCell>{note.Invoice?.number}</TableCell>
                    <TableCell>
                      {moment(note.createdAt).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      {note.Invoice?.currency} {note.Invoice?.adjustment}
                    </TableCell>
                    <TableCell>{note.reason || '-'}</TableCell>
                    <TableCell>
                      <div className='flex gap-4 items-center'>
                        <span className={`w-3 h-3 rounded-[50%] ${note.isCancelled ? 'bg-error' : 'bg-success'}`}></span>
                        <span>{note.isCancelled ? 'Cancelled' : 'Created'}</span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Options id={note.id} type={type} refetch={refetch} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <CustomPagination page={page} pages={pageCount} setPage={setPage} />
      </div>
    </div>
  );
}

const Options = ({ id, type, refetch }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [creditNote, setCreditNote] = useState(false);
  const open = Boolean(anchorEl);

  const { setAPILoader, currentUser } = useContext(SharedContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const useStyles = makeStyles({
    topScrollPaper: {
      alignItems: 'flex-start',
      marginTop: '100px'
    },
    topPaperScrollBody: {
      verticalAlign: 'top'
    }
  });

  const cancelCreditNote = async () => {
    try {
      setAPILoader(true);
      const response = await API.delete(`invoices/credit-note/${id}`);
      await refetch()
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const showMenu =
    checkPermission(currentUser, 'OPS_CREDIT_NOTE_EDIT') ||
    checkPermission(currentUser, 'OPS_CREDIT_NOTE_CANCEL');

  return (
    <React.Fragment>
      {showMenu && (
        <Box
          sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
        >
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreVert sx={{ width: 32, height: 32 }} />
          </IconButton>
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5
          }
        }}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {checkPermission(currentUser, 'OPS_CREDIT_NOTE_EDIT') && (
          <MenuItem
            onClick={async () => {
              setCreditNote(true);
            }}
            className="block px-4 py-1"
          >
            Edit
          </MenuItem>
        )}
        {checkPermission(currentUser, 'OPS_CREDIT_NOTE_CANCEL') && (
          <MenuItem
            onClick={async () => {
              await cancelCreditNote();
            }}
            className="block px-4 py-1"
          >
            Cancel
          </MenuItem>
        )}
      </Menu>

      <Popup
        open={creditNote}
        setOpen={setCreditNote}
        content={
          <div className="min-w-[600px]">
            <CreditNotePopup setOpen={setCreditNote} type={type} id={id} refetch={refetch} />
          </div>
        }
        title={<span>Update Credit Note</span>}
      />
    </React.Fragment>
  );
};
