import React, { useContext, useEffect, useState } from 'react';

import Button from '../../core-components/atoms/Button';
import { SharedContext } from '../../utils/common';
import ExpandableTable from './ExpandableTable';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import CustomPagination from '../invoiceandbilling/invoiceManagement/CustomPagination';
import CompanySearch from './supplierSearchBar';
import AddProductPopup from './ListViews/components/AddProductPopup';
import { checkPermission } from '../../utils/auth';
import { Title } from '../../core-components/atoms/Text';
import addIcon from '../../assets/icons/addIcon.svg';
const CompaniesView = () => {
  const [customers, setCustomers] = useState([]);
  const [organizationNodes, setOrganizationNodes] = useState({});
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [companyNameFilter, setCompanyNameFilter] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [addProductView, setAddProductView] = useState(false);
  const { organization, currentUser } = useContext(SharedContext);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const getCustomers = async (value, params) => {
    try {
      const response = await API.get(`companies`, {
        params: {
          search: value,
          limit: page == 1 ? 9 : 10,
          page,
          type: ['Customer'],
          ...params
        }
      });
      setCustomers(response);
      setPages(response.pages);
    } catch (err) {
      onError(err);
    }
  };

  const getOrgNodes = async () => {
    try {
      const response = await API.get(`nodes`);
      setOrganizationNodes(response);
    } catch (err) {
      onError(err);
    }
  };

  useEffect(() => {
    getOrgNodes();
  }, []);

  useEffect(() => {
    getCustomers();
  }, [page]);

  useEffect(() => {
    if (companyNameFilter) {
      getCustomers(companyNameFilter, { companyIds: [customerId] });
    }
  }, [companyNameFilter]);

  return (
    <div>
      <AddProductPopup
        open={addProductView}
        setOpen={setAddProductView}
        setPage={setPage}
      />
      <div className="flex justify-between mr-4 ml-8 mt-10 items-center">
        <Title variant={'lg'}>All Entities</Title>
        <div className="flex gap-2">
          {checkPermission(currentUser, 'OPS_PRODUCT_CREATE') && (
            <Button
              label="Add Product"
              className={
                'py-2 px-3 ml-2 h-8 rounded border-solid border-oasisPrimary500'
              }
              variant="primary"
              overrideSize={true}
              icon={<img src={addIcon} className={`mr-2`} />}
              labelClass="font-medium text-xs text-white"
              iconClass="h-[14px] w-[14px] mr-1"
              onClick={() => {
                setAddProductView(!addProductView);
              }}
            />
          )}
        </div>
      </div>
      <div className="mt-4 mr-4 ml-8">
        <ExpandableTable
          staticRow={
            organization?.id && !companyNameFilter
              ? {
                  name: organization?.name,
                  type: 'Organization',
                  paymentTerms: null,
                  ntn: organization?.ntn,
                  billingAddress: organization?.address,
                  numberOfNodes: organizationNodes?.count,
                  nodes: organizationNodes?.data || []
                }
              : {}
          }
          rows={customers?.data?.rows || []}
          page={page}
          setPage={setPage}
          pages={pages}
          showHighlight={!!companyNameFilter}
          setSelectedCompany={setSelectedCompany}
          selectedCompany={selectedCompany}
          getCustomers={getCustomers}
          getOrgNodes={getOrgNodes}
        />
        <CustomPagination page={page} pages={pages} setPage={setPage} />
      </div>
      <div className="fixed top-[4px] right-[122px] z-[1100]">
        <CompanySearch
          companyFiterName={companyNameFilter}
          setCompanyNameFilter={setCompanyNameFilter}
          setCustomerId={setCustomerId}
          onClear={async () => {
            await getCustomers();
            setCustomerId('');
            setCompanyNameFilter('');
          }}
        />
      </div>
    </div>
  );
};

export default CompaniesView;
