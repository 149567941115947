/*eslint-disable*/
import { useFormik } from 'formik';
import React, { useState, useRef, useEffect } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  Button,
  FormControl,
  TextField,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddIcon from '@mui/icons-material/Add';
import { upload } from '../../../utils/upload';
import ImagePreview from '../../../atomicComponents/ImagePreview';
import delIcon from '../../../assets/icons/del.svg';
import { constants } from './constants';
import { formatPrice } from '../invoices/InvoiceForm';

export const ImagesList = ({ formik, disableUpload = false }) => {
  const inputRef = useRef(null);
  const [imagesArr, setImagesArr] = useState(formik?.values?.images || []);

  useEffect(() => {
    formik?.values?.images?.length && setImagesArr(formik?.values?.images);
  }, [formik?.values?.images]);

  const validateImage = async (event) => {
    const checkFile = event.target.files[0];
    if (checkFile) {
      if (!checkFile.name.toLowerCase().match(/\.(jpg|jpeg|png|pdf|xlsx)$/)) {
        alert('Only images or PDF file allowed!');
        return false;
      }
      const isLt2M = checkFile.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        alert('File must be smaller than 1MB!');
        return false;
      }

      const imgs = [...imagesArr, checkFile];
      setImagesArr(imgs);
      formik?.setFieldValue('images', imgs);
    }
  };

  return (
    <Grid container spacing={2} style={{ width: '500px' }}>
      {imagesArr?.length
        ? imagesArr?.map((file, id) => (
            <Grid key={id} item xs={3} style={{ position: 'relative' }}>
              <ImagePreview file={file} />
              <div
                style={{
                  position: 'absolute',
                  top: '0%',
                  right: '-2%',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  let imgs = [...imagesArr];
                  imgs = imgs.slice(0, id).concat(imgs.slice(id + 1));
                  setImagesArr(imgs);
                  formik.setFieldValue('images', imgs);

                  if (file.id) {
                    let docs = formik.values.supportingDocument || [];
                    docs = docs.filter((doc) => doc !== file.id);
                    formik.setFieldValue('supportingDocument', docs);
                  }
                }}
              >
                <img src={delIcon} />
              </div>
            </Grid>
          ))
        : null}
      <Grid xs={3} item>
        <div className="imagePreviewSec">
          <button
            type="button"
            className="upload-image-btn"
            onClick={() => inputRef.current.click()}
            disabled={disableUpload}
          >
            <AddIcon style={{ color: '#0C70D7' }} fontSize="large" />
          </button>
          <input
            ref={inputRef}
            hidden
            type="file"
            onChange={(e) => validateImage(e)}
            accept=".jpg,.png,.jpeg,.pdf,.xlsx"
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default function Payment({
  invoice,
  addBillPayment,
  closePopUp,
  billing
}) {
  const useStyles = makeStyles(() => ({
    container: {
      display: 'flex',
      gap: '1rem',
      marginTop: '1.5rem'
    },
    imageContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      marginTop: '3rem',
      marginBottom: '1rem'
    },
    btns: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '1rem',
      '& button': {
        textTransform: 'none'
      }
    },
    recordBtn: {
      padding: '4px 24px 4px 24px',
      backgroundColor: '#0D70D7',
      '&:hover': {
        backgroundColor: '#0D70D7'
      }
    },
    outstanding: {
      color: '#67718C',
      fontSize: '14px'
    },
    info: {
      display: 'flex',
      gap: '1rem',
      marginTop: '1.3rem',
      '& div': {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem'
      }
    },
    infoHeading: {
      fontSize: '14px',
      fontWeight: 'bold'
    }
  }));

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      date: new Date(),
      amount: ''
    },
    onSubmit: async (values) => {
      if (values.amount) {
        const payload = {
          date: values.date,
          payableAmount: invoice.total - invoice.paidAmount,
          amount: values.amount,
          outstandingAmount:
            invoice.total - (invoice.paidAmount + Number(formik.values.amount)),
          invoiceId: invoice.id,
          companyId: invoice.Company?.id || invoice.Vendor?.id,
          updatedPaidAmount: invoice.paidAmount + Number(formik.values.amount)
        };

        let fileIds = [];
        if (values?.images?.length) {
          fileIds = await upload(values.images, 'bills');
          payload.supportingDocument = [...fileIds];
        }
        addBillPayment(payload);
      }
    }
  });
  return (
    <div>
      <Typography style={{ fontWeight: 600, fontSize: '20px' }}>
        Record Payment
      </Typography>
      <Typography className={classes.outstanding}>
        {constants.CURRENCY[invoice.currency]}{' '}
        {formatPrice(
          invoice.total - (invoice.paidAmount + Number(formik.values.amount))
        )}{' '}
        needs to be paid
      </Typography>

      <Grid container spacing={1} className="mt-10">
        <Grid xs={4} item>
          <Typography className={classes.infoHeading}>
            {billing ? 'Vendor Name' : 'Customer Name'}
          </Typography>
          <Typography>
            {invoice.Company?.name || invoice.Vendor?.name}
          </Typography>
        </Grid>
        <Grid xs={4} item>
          <Typography className={classes.infoHeading}>
            {billing ? 'Payable Amount' : 'Receivable Amount'}
          </Typography>
          <Typography>
            {formatPrice(invoice.total - invoice.paidAmount)}
          </Typography>
        </Grid>
        <Grid xs={4} item>
          <Typography className={classes.infoHeading}>
            Outstanding Amount
          </Typography>
          <Typography>
            {formik.values.amount
              ? formatPrice(
                  invoice.total -
                    (invoice.paidAmount + Number(formik.values.amount))
                )
              : 0}
          </Typography>
        </Grid>
      </Grid>
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.container}>
          <div>
            <FormControl fullWidth={true}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  value={formik?.values?.date}
                  onChange={(newValue) => {
                    formik.setFieldValue('date', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField variant="outlined" {...params} id="date" />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </div>
          <div>
            <TextField
              value={formik.values.amount}
              onChange={(e) => formik.setFieldValue('amount', e.target.value)}
              variant="outlined"
              type="number"
              placeholder={billing ? 'Paid Amount' : 'Received Amount'}
            />
            {formik.errors.amount && (
              <Typography color="error">Payment is required !</Typography>
            )}
          </div>
        </div>
        <div className={classes.imageContainer}>
          <Typography>Attach Supporting Documents</Typography>
          <ImagesList formik={formik} />
        </div>
        <div className={classes.btns}>
          <Button variant="text" color="primary" onClick={closePopUp}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.recordBtn}
          >
            Record Payment
          </Button>
        </div>
      </form>
    </div>
  );
}
