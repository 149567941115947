/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import { constants } from './constants';
import Autocomplete from '../../../core-components/atoms/Autocomplete';

export const formatPrice = (val) =>
  parseFloat(parseFloat(val).toFixed(2)).toLocaleString('en-US', {
    useGrouping: true
  }) || 0.0;

const useStyles = makeStyles(() => ({
  table: {
    borderRadius: '10px',
    border: '1px solid #dcdcdc'
  },
  tableCell: {
    paddingLeft: '0.4rem',
    paddingRight: 0
  },
  invFormHeader: {
    fontSize: '14px',
    fontWeight: 500
  },
  placeholder: {
    color: '#6A7289'
  }
}));

export default function CreditNoteItemsForm({
  invoiceItems,
  setInvoiceItems,
  currency = 'PKR',
  taxes
}) {
  const classes = useStyles();
  const [items, setItems] = useState(invoiceItems || []);

  useEffect(() => {
    if (invoiceItems) {
      setItems(invoiceItems);
    }
  }, [invoiceItems]);

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];
    const currentItem = updatedItems[index];

    const newItem = {
      ...currentItem,
      [field]: value
    };

    const baseAmount =
      (field === 'unit' || field === 'rate') &&
        currentItem.unit &&
        currentItem.rate
        ? (field === 'unit' ? value : currentItem.unit) *
        (field === 'rate' ? value : currentItem.rate)
        : currentItem.totalAmount || 0;

    const taxRate = currentItem.tax?.rate
      ? parseFloat(currentItem.tax.rate) / 100
      : 0;
    const totalAmount = baseAmount + baseAmount * taxRate;

    newItem.totalAmount = totalAmount;

    updatedItems[index] = newItem;
    setItems(updatedItems);
    setInvoiceItems(updatedItems);
  };

  return (
    <Grid item container>
      <Grid item xs={12}>
        <Typography
          style={{ fontSize: '18px', fontWeight: 500, marginBottom: '1rem' }}
        >
          Invoice Items
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <div className={classes.table}>
          <Table>
            <TableHead className="table-header">
              <TableRow>
                <TableCell className="first-cell">
                  <Typography className={classes.invFormHeader}>
                    Title
                  </Typography>
                </TableCell>
                <TableCell className="table-cell">
                  <Typography className={classes.invFormHeader}>
                    Unit Type
                  </Typography>
                </TableCell>
                <TableCell className="table-cell">
                  <Typography className={classes.invFormHeader}>
                    Unit
                  </Typography>
                </TableCell>
                <TableCell className="table-cell">
                  <Typography className={classes.invFormHeader}>
                    Rate
                  </Typography>
                </TableCell>
                <TableCell className="table-cell">
                  <Typography className={classes.invFormHeader}>Tax</Typography>
                </TableCell>
                <TableCell className="table-cell">
                  <Typography className={classes.invFormHeader}>
                    Total Amount (Tax Exclusive)
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.tableCell}>
                    <Typography>{item.title?.name || '-'}</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography>{item.unitType?.name || '-'}</Typography>
                  </TableCell>
                  <TableCell>
                    <div className="invoiceTitleSection">
                      <div className="invoiceTitle">
                        {item?.unit ? (
                          <Typography>{item.unit}</Typography>
                        ) : (
                          <span className={classes.placeholder}>Unit</span>
                        )}
                      </div>
                      <input
                        type="number"
                        min="0"
                        className="invoiceTitleField"
                        onChange={(e) =>
                          handleItemChange(index, 'unit', e.target.value)
                        }
                        value={item.unit}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="invoiceTitleSection">
                      <div className="invoiceTitle">
                        {item?.rate ? (
                          <Typography>{item.rate}</Typography>
                        ) : (
                          <span className={classes.placeholder}>Rate</span>
                        )}
                      </div>
                      <input
                        type="number"
                        min="0"
                        className="invoiceTitleField"
                        onChange={(e) =>
                          handleItemChange(index, 'rate', e.target.value)
                        }
                        value={item.rate}
                      />
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableCell} style={{ width: '20%' }}>
                    <Autocomplete
                      options={taxes}
                      value={item.tax}
                      getOptionLabel={(option) => option.name || ''}
                      onChange={(e, value) => handleItemChange(index, 'tax', value)}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {`${constants.CURRENCY[currency]} ${formatPrice(
                        item.rate * item.unit
                      )}`}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Grid>
    </Grid>
  );
}
