/*eslint-disable*/
import React from 'react';
import { useEffect, useContext } from 'react';
import DateRangePickerSingleCalendar from './DateRangePicker';
import {
  SharedContext,
  exportToCSV,
  dateFormatSlashDivider,
  decimalDisplay
} from '../../../utils/common';
import { useState } from 'react';
import Button from '../../../core-components/atoms/Button';
import DownloadIcon from '../../../assets/icons/downloadOutlineIcon.svg';
import Type from '../../../assets/icons/button-pointer.svg';
import Batch from '../../../assets/icons/Batch.svg';
import Transaction from '../../../assets/icons/credit_card.svg';
import Filters from './Filters';
import API from '../../../libs/axios';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  TableRow,
  TableCell,
  TableHead
} from '@material-ui/core';
import { ORGANIZATION_TYPES } from '../../../constants/index';
import clsx from 'clsx';
import { Popover } from '@material-ui/core';
import PrintPdfLedgerDetail from './PrintPdfLedgerDetails';
import moment from 'moment';
import { Label, Paragraph } from '../../../core-components/atoms/Text';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import FLAGS from '../../../constants/featureFlags';

const LedgerReportPopupView = ({
  product,
  selectedStartDate,
  selectedEndDate
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { setAPILoader, organizationType } = useContext(SharedContext);
  const IS_MANUFACTURER = organizationType === ORGANIZATION_TYPES.MANUFACTURER;

  const filterInitialState = {
    Type: [],
    'Transaction ID': [],
    Batches: []
  };
  const setFilter = (key, value, type, filterType) => {
    let currentFilters;
    if (!filterType) {
      currentFilters = { ...advancedfilters };
    }
    let copiedArr = [...currentFilters[key]];
    if (type === 'add') {
      copiedArr.push(value);
    } else {
      copiedArr = currentFilters[key].filter((v) => v.value != value.value);
    }
    delete currentFilters[key];
    currentFilters[key] = copiedArr;
    if (!filterType) {
      setFilters(currentFilters);
    }
  };

  const exportToExcel = () => {
    if (productData.length) {
      setIsLoading(true);
      const dataToExport = productData.map((data) => {
        return {
          name: product?.Inventory?.Product?.name || ' ',
          description: product?.Inventory?.Product?.description || ' ',
          date: `"${dateFormatSlashDivider(data.createdAt)}"`,
          id: `"${data.referenceId}"`,
          type: data.transactionType || '',
          createdBy: `${data.User.firstName} ${data.User.lastName}`,
          origin: data.originNode?.name || '',
          destination: data.destinationNode?.name || '',
          batch: data.batchNumber || '',
          quantity: data.quantity || '',
          balance: data.closingStock || '',
        };
      });
      let newArr = [
        {
          name: ' ',
          description: ' ',
          date: `"${dateFormatSlashDivider(productData[0].createdAt)}"`,
          id: ' ',
          type: ' ',
          createdBy: ' ',
          origin: 'Opening Stock',
          destination: ' ',
          batch: ' ',
          quantity: ' ',
          balance: product?.openingStock
        },
        ...dataToExport,
        {
          name: ' ',
          description: ' ',
          date: `"${dateFormatSlashDivider(
            productData[productData.length - 1].createdAt
          )}"`,
          id: ' ',
          type: ' ',
          createdBy: ' ',
          origin: 'Closing Stock',
          destination: ' ',
          batch: ' ',
          quantity: ' ',
          balance: product?.closingStock
        }
      ];
      let title = `Ledger Report ${moment().format('DD-MM-yyyy')}`;
      const header = [
        'SKU Code',
        'SKU Description',
        //...(!IS_MANUFACTURER ? ['Company'] : []),
        'Date',
        'Id',
        'Type',
        'Created By',
        'Origin',
        'Destination',
        'Batch',
        'Qty',
        'Balance'
      ];
      exportToCSV(header, newArr, title);
      setIsLoading(false);
    }
  };

  const [columnNames] = useState([
    'DATE',
    'ID',
    'TYPE',
    'CREATED BY',
    'ORIGIN & DESTINATION',
    ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING) ? ['BATCH'] : []),
    'QTY'
  ]);
  const inventryStatus = {
    ...(IS_MANUFACTURER ? { PURCHASE: 'Purchase' } : {}),
    ...(IS_MANUFACTURER ? { SALES: 'Sales' } : {}),
    ...(IS_MANUFACTURER ? { SALES_RETURN: 'Sales Return' } : {}),
    ...(!IS_MANUFACTURER ? { INWARD: 'Inbound' } : {}),
    ...(!IS_MANUFACTURER ? { OUTBOUND: 'Outbound' } : {}),
    TRANSFER: 'Transfer',
    STOCK_ADJUSTMENT: 'Adjustment',
    GRN_REVERSAL: 'GRN Reversal',
    GDN_REVERSAL: 'GDN Reversal'
  };

  const [startDate, setStartDate] = useState(selectedStartDate);
  const [endDate, setEndDate] = useState(selectedEndDate);
  const [advancedfilters, setFilters] = useState(filterInitialState);
  const [productData, setProductData] = useState([]);
  const [areAnyFilterActive, setAreAnyFilterActive] = React.useState(false);
  const [isExportPopoverOpen, setIsExportPopoverOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const [, setIsLoading] = useState(false);
  const [transactionIds, setTransactionIds] = useState([]);
  let filterOptions = [
    {
      label: 'Type',
      name: 'Type',
      options: Object.keys(inventryStatus).map((key, id) => ({
        value: key,
        label: inventryStatus[key],
        id
      })),
      icon: Type
    },
    {
      options: transactionIds?.map((value) => ({
        value: value,
        label: value,
        id: value
      })),
      label: 'Transaction ID',
      name: 'Transaction ID',
      icon: Transaction
    },
    ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
      ? [
          {
            label: 'Batch(es)',
            name: 'Batches',
            icon: Batch
          }
        ]
      : [])
  ];

  const getLedgerTransatcions = (searchTransaction) => {
    let filters = {
      colVal: {},
      sortable: ''
    };
    setAPILoader(true);
    API.get(`reports/inventoryLedger/transaction`, {
      params: {
        inventoryId: product?.Inventory?.id,
        filters,
        search: searchTransaction,
        columns: ['id']
      }
    })
      .then((res) => {
        if (res.data) {
          setTransactionIds(res.data);
        }
      })
      .finally(() => setAPILoader(false));
  };

  const getInventoryDetails = () => {
    let filters = {
      colVal: {},
      sortable: ''
    };
    setAPILoader(true);
    let advanceFilters = Object.entries(advancedfilters).map(
      ([key, values]) => ({
        [key]: values.flat().map((item) => item.value || item)
      })
    );
    API.get(`reports/inventoryLedger/details`, {
      params: {
        startDate: moment.utc(startDate)?.toISOString(),
        endDate: moment
          .utc(endDate)
          .add(23, 'hours')
          .add(59, 'minutes')
          .add(59, 'seconds')
          .toISOString(),
        filters,
        advanceFilters,
        inventoryId: product?.Inventory.id
      }
    })
      .then((res) => {
        if (res.data.rows) {
          setProductData(res.data.rows);
        }
      })
      .finally(() => setAPILoader(false));
  };
  useEffect(() => {
    getLedgerTransatcions();
  }, []);
  useEffect(() => {
    getInventoryDetails();
  }, [startDate, endDate, advancedfilters]);

  useEffect(() => {
    const res = Object.values(advancedfilters).some(
      (filter) => filter.length > 0
    );
    setAreAnyFilterActive(res);
  }, [advancedfilters]);
  return (
    <div>
      <div className="flex w-full">
        <div className="border border-solid rounded py-3 px-4 h-[56px] w-[139px]">
          <Label variant={'xs'} className="text-dark500">
            OPENING STOCK QTY
          </Label>
          <Paragraph variant={'sm'}>
            {decimalDisplay(product?.openingStock)}
          </Paragraph>
        </div>
        {IS_MANUFACTURER && (
          <>
            <div className="border border-solid rounded py-3 px-4 ml-2 h-[56px] w-[89px]">
              <Label variant={'xs'} className="text-dark500">
                PURCHASES QTY
              </Label>
              <Paragraph variant={'sm'}>
                {decimalDisplay(product?.purchaseQuantity)}
              </Paragraph>
            </div>
            <div className="border border-solid rounded py-3 px-4 ml-2 h-[56px] w-[89px]">
              <Label variant={'xs'} className="text-dark500">
                SALES QTY
              </Label>
              <Paragraph variant={'sm'}>
                {decimalDisplay(product?.salesQuantity)}
              </Paragraph>
            </div>
            <div className="border border-solid rounded py-3 px-4 ml-2 h-[56px] w-[104px]">
              <Label variant={'xs'} className="text-dark500">
                SALES RETURN
              </Label>
              <Paragraph variant={'sm'}>
                {decimalDisplay(product?.salesReturnQuantity)}
              </Paragraph>
            </div>
          </>
        )}
        {!IS_MANUFACTURER && (
          <>
            <div className="border border-solid rounded py-3 px-4 ml-2 h-[56px] w-[89px]">
              <Label variant={'xs'} className="text-dark500">
                INBOUND
              </Label>
              <Paragraph variant={'sm'}>
                {decimalDisplay(product?.inboundQuantity)}
              </Paragraph>
            </div>
            <div className="border border-solid rounded py-3 px-4 ml-2 h-[56px] w-[89px]">
              <Label variant={'xs'} className="text-dark500">
                OUTBOUND
              </Label>
              <Paragraph variant={'sm'}>
                {decimalDisplay(product?.outboundQuantity)}
              </Paragraph>
            </div>
          </>
        )}

        <div className="border border-solid rounded py-3 px-4 ml-2 h-[56px] w-[120px]">
          <Label variant={'xs'} className="text-dark500">
            TRANSFER QTY
          </Label>
          <Paragraph variant={'sm'}>
            {decimalDisplay(
              product.addTransferQuantity - product.subtractTransferQuantity
            )}
          </Paragraph>
        </div>
        <div className="border border-solid rounded py-3 px-4 ml-2 h-[56px] w-[104px]">
          <Label variant={'xs'} className="text-dark500">
            ADJUSTMENTS
          </Label>
          <Paragraph variant={'sm'}>
            {decimalDisplay(product?.adjustments)}
          </Paragraph>
        </div>
        <div className="border border-solid rounded py-3 px-4 ml-2 h-[56px] w-[114px]">
          <Label variant={'xs'} className="text-dark500">
            GDN REVERSAL
          </Label>
          <Paragraph variant={'sm'}>
            {decimalDisplay(product?.gdnReversalQuantity)}
          </Paragraph>
        </div>
        <div className="border border-solid rounded py-3 px-4 ml-2 h-[56px] w-[114px]">
          <Label variant={'xs'} className="text-dark500">
            GRN REVERSAL
          </Label>
          <Paragraph variant={'sm'}>
            {decimalDisplay(product?.grnReversalQuantity)}
          </Paragraph>
        </div>
        <div className="border border-solid rounded py-3 px-4 ml-2 w-[109px] h-[56px]">
          <Label variant={'xs'} className="text-dark500">
            CLOSING STOCK
          </Label>
          <Paragraph variant={'sm'}>
            {decimalDisplay(product?.closingStock)}
          </Paragraph>
        </div>
      </div>
      <div className="flex justify-between mt-[28px]">
        <div>Stock Details</div>
        <div className="flex">
          <DateRangePickerSingleCalendar
            buttonClasses={'h-[32px] w-[20px]'}
            startDate={startDate}
            endDate={endDate}
            updateRange={(input) => {
              setStartDate(input.selection.startDate);
              setEndDate(input.selection.endDate);
            }}
          />
          <Filters
            options={filterOptions}
            filters={advancedfilters}
            setFilter={setFilter}
            height={220}
            isPrimary={true}
            getLedgerTransatcions={getLedgerTransatcions}
          />

          <Button
            variant="transparent"
            icon={<img src={DownloadIcon} className={`mr-2`} />}
            label="Export"
            className="py-2 px-3 ml-2 h-8 border-solid border "
            overrideSize={true}
            labelClass="font-medium text-xs"
            iconClass="h-[14px] w-[14px] mr-1"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setIsExportPopoverOpen(true);
            }}
          />
        </div>
      </div>
      <Popover
        open={isExportPopoverOpen}
        anchorEl={anchorEl}
        onClose={() => setIsExportPopoverOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          horizontal: 'right'
        }}
        style={{
          boxShadow: '0px 4px 13px 0px #0B3E722E'
        }}
        PaperProps={{
          style: {
            boxShadow: '0px 4px 13px 0px #0B3E722E'
          }
        }}
        disablePortal
      >
        <div
          onMouseEnter={(e) => e.preventDefault()}
          style={{ width: '144px' }}
        >
          <PrintPdfLedgerDetail
            setIsExportPopoverOpen={setIsExportPopoverOpen}
            data={productData}
            productDetails={product}
            startDate={startDate}
            endDate={endDate}
            isManufacturer={IS_MANUFACTURER}
          />
          <div
            className="w-full py-[12px] px-3 items-center text-xs font-normal text-dark800 cursor-pointer hover:bg-dark100"
            onClick={() => exportToExcel()}
          >
            CSV
          </div>
        </div>
      </Popover>
      {areAnyFilterActive && (
        <div className="w-full flex pt-[20px] text-dark500 text-sm font-medium">
          <div className="pt-[4px]">Applied Filters</div>
          <div className="ml-6 flex">
            {advancedfilters?.Type.length > 0 && (
              <div
                className={`h-[30px] mr-1 px-3 py-1 border-solid cursor-pointer border border-[#67718C] inline-block bg-[#274E77] text-white rounded`}
                onClick={() =>
                  setFilters((filters) => ({
                    ...filters,
                    Type: []
                  }))
                }
              >
                <span className="text-sm">Type:</span>
                <span className="text-sm">
                  {advancedfilters?.Type[0]?.label}
                </span>
                {advancedfilters?.Type?.length > 1 && (
                  <span className="text-sm ml-1">
                    +{advancedfilters?.Type?.length - 1} more
                  </span>
                )}
                <span className={clsx('ml-3 font-bold', 'text-white')}>x</span>
              </div>
            )}
            {advancedfilters?.Batches.length > 0 && (
              <div
                className={`h-[30px] mr-1 px-3 py-1 border-solid cursor-pointer border border-[#67718C] inline-block bg-[#274E77] text-white rounded`}
                onClick={() => {
                  setFilters((filters) => ({
                    ...filters,
                    Batches: []
                  }));
                }}
              >
                <span className="text-sm">Batches:</span>
                <span className="text-sm">
                  {advancedfilters?.Batches?.[0]?.[0]?.value}
                </span>
                {advancedfilters?.Batches?.[0].length > 1 && (
                  <span className="text-sm ml-1">
                    +
                    {advancedfilters?.Batches.reduce(
                      (acc, batch) => acc + batch.length,
                      0
                    ) - 1}{' '}
                    more
                  </span>
                )}
                <span className={clsx('ml-3 font-bold', 'text-white')}>x</span>
              </div>
            )}
            {advancedfilters?.['Transaction ID']?.length > 0 && (
              <div
                className={`h-[30px] mr-1 px-3 py-1 border-solid cursor-pointer border border-[#67718C] inline-block bg-[#274E77] text-white rounded`}
                onClick={() =>
                  setFilters((filters) => ({
                    ...filters,
                    'Transaction ID': []
                  }))
                }
              >
                <span className="text-sm">Transaction ID:</span>
                <span className="text-sm">
                  {advancedfilters?.['Transaction ID']?.[0]?.label}
                </span>
                {advancedfilters?.['Transaction ID']?.length > 1 && (
                  <span className="text-sm ml-1">
                    +{advancedfilters?.['Transaction ID']?.length - 1} more
                  </span>
                )}
                <span className={clsx('ml-3 font-bold', 'text-white')}>x</span>
              </div>
            )}
            {(advancedfilters?.Batches.length > 0 ||
              advancedfilters?.['Transaction ID'].length > 0 ||
              advancedfilters?.Type.length > 0) && (
              <div
                className="px-3 pt-[4px] cursor-pointer border-[#BDBDBD] border-solid border-0 border-l"
                onClick={(e) => {
                  e.preventDefault();
                  setFilters({
                    Type: [],
                    'Transaction ID': [],
                    Batches: []
                  });
                }}
              >
                <span className="text-primary font-medium">Clear All</span>
              </div>
            )}
          </div>
        </div>
      )}
      {productData.length > 0 ? (
        <div>
          <Paper className="no-scrollbar rounded-t-lg shadow-none mt-[32px]">
            <TableContainer
              style={{ overflow: 'auto' }}
              className="mt-6 no-scrollbar rounded-t-lg border border-solid border-dark300 inventory-table"
            >
              <Table stickyHeader>
                <TableHead className="z-40 relative">
                  <TableRow>
                    {columnNames.map((columnName, index) => (
                      <TableCell
                        key={index}
                        className="text-[10px] text-dark500 bg-white py-2 pl-[24px] pr-2 font-normal h-[40px]"
                      >
                        {columnName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7} style={{ padding: '0px' }}>
                      <div className="w-full h-[26px] flex justify-end bg-dark200 border-solid border-b border-dark300">
                        <p className="text-xs text-primaryBlue flex items-center mr-4">
                          Opening Stock:{' '}
                          <span className="text-sm font-medium ml-1">
                            {decimalDisplay(product?.openingStock)}
                          </span>{' '}
                        </p>
                      </div>
                    </TableCell>
                  </TableRow>
                  {productData.length > 0 &&
                    productData.map((row, index) => (
                      <TableRow key={index} className={clsx(' h-[48px]')}>
                        <TableCell
                          className={
                            'group-hover:text-primaryBlue rounded-none pl-[24px] text-xs font-normal'
                          }
                        >
                          <Tooltip
                            title={dateFormatSlashDivider(row?.createdAt)}
                          >
                            <p>{dateFormatSlashDivider(row.createdAt)}</p>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className={
                            'group-hover:text-primaryBlue rounded-none pl-[24px] text-xs font-normal'
                          }
                        >
                          <Tooltip title={row.referenceId || '-'}>
                            <p>{row.referenceId || '-'}</p>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className={
                            'group-hover:text-primaryBlue rounded-none pl-[24px] text-xs font-normal'
                          }
                        >
                          <Tooltip
                            title={
                              row?.transactionType === 'INWARD'
                                ? 'Inbound'
                                : row?.transactionType === 'GRN_REVERSAL'
                                ? 'GRN Reversal'
                                : row?.transactionType === 'GDN_REVERSAL'
                                ? 'GDN Reversal'
                                : row?.transactionType === 'SALES_RETURN'
                                ? 'Sales Return'
                                : row?.transactionType === 'STOCK_ADJUSTMENT'
                                ? 'Adjustment'
                                : row?.transactionType === 'UPDATE_BATCH_DETAIL'
                                ? 'Batch Detail Updated'
                                : row?.transactionType.toTitleCase()
                            }
                          >
                            <p className={'capitalize'}>
                              {row?.transactionType === 'INWARD'
                                ? 'Inbound'
                                : row?.transactionType === 'GRN_REVERSAL'
                                ? 'GRN Reversal'
                                : row?.transactionType === 'GDN_REVERSAL'
                                ? 'GDN Reversal'
                                : row?.transactionType === 'SALES_RETURN'
                                ? 'Sales Return'
                                : row?.transactionType === 'STOCK_ADJUSTMENT'
                                ? 'Adjustment'
                                : row?.transactionType === 'UPDATE_BATCH_DETAIL'
                                ? 'Batch Detail Updated'
                                : row?.transactionType.toTitleCase()}
                            </p>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className={
                            'group-hover:text-primaryBlue rounded-none pl-[24px] text-xs font-normal'
                          }
                        >
                          <Tooltip
                            title={`${row?.User?.firstName} ${row?.User?.lastName}`}
                          >
                            <p>{`${row?.User?.firstName} ${row?.User?.lastName}`}</p>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className={
                            'group-hover:text-primaryBlue rounded-none pl-[24px] text-xs font-normal'
                          }
                        >
                          <p>
                            {row?.originNode?.name}
                            {row?.transactionType !== 'STOCK_ADJUSTMENT' && (
                              <>
                                <span className="w-[8%] text-[24px] leading-[50%]">
                                  &#8594;
                                </span>
                                {row?.destinationNode?.name}
                              </>
                            )}
                          </p>
                        </TableCell>
                        {isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING) && (
                          <TableCell
                            className={
                              'group-hover:text-primaryBlue rounded-none pl-[24px] text-xs font-normal'
                            }
                          >
                            <Tooltip title={row?.batchNumber}>
                              <div>
                                <div>{row?.batchNumber}</div>
                                <div>
                                  {`${dateFormatSlashDivider(
                                    row?.InventoryDetail?.manufacturingDate
                                  )} - ${dateFormatSlashDivider(
                                    row?.InventoryDetail?.expiryDate
                                  )}` || ''}
                                </div>
                              </div>
                            </Tooltip>
                          </TableCell>
                        )}
                        <TableCell
                          className={
                            'group-hover:text-primaryBlue rounded-none pl-[24px] text-xs font-normal'
                          }
                        >
                          <Tooltip title={row.quantity}>
                            <p
                              className={
                                row.operation === 'ADD'
                                  ? 'text-jade700'
                                  : 'text-crimson500'
                              }
                            >
                              {row.operation === 'ADD'
                                ? `${decimalDisplay(row.quantity)}`
                                : `-${decimalDisplay(row.quantity)}`}
                            </p>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <div className="w-full flex justify-end items-center bg-dark200 rounded-b-lg h-7 border-solid border border-dark300 border-t-0 py-3 px-4">
            <p className="text-xs text-primaryBlue flex items-center">
              Closing Stock:{' '}
              <span className="text-sm font-medium ml-1">
                {decimalDisplay(product?.closingStock)}
              </span>
            </p>
          </div>
        </div>
      ) : (
        <div className="mt-4 justify-center w-100  pl-[350px]">
          {' '}
          <div>No Records Found</div>
        </div>
      )}
    </div>
  );
};
export default LedgerReportPopupView;
