/*eslint-disable*/
import { createContext } from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';

import OrderStatuses from './enums/orderStatuses';
import GreenTick from '../assets/icons/greenTick.svg';
import OutboundViewStatuses from './enums/outboundViewStatuses';
import InwardViewStatuses from './enums/inwardViewStatuses';
import API from '../libs/axios';
import { onError } from '../libs/errorLib';
import TransferViewStatuses from './enums/transferViewStatuses';
import currencySymbols from './currencySymbols.json';
import {
  addMonths,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  subQuarters
} from 'date-fns';

export const apiBaseURL =
  // eslint-disable-next-line no-undef
  (process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : '') +
  '/api/v1';

export const getURL = (...args) => {
  return [apiBaseURL, ...Array.from(args)].join('/');
};

export const digitize = (value, places) => {
  let strVal = value + '';
  return new Array(places - strVal.length).fill('0').join('') + strVal;
};

export const convertSlashFormatDate = (value) => {
  if (value) {
    const splitChar = value.includes('/') ? '/' : '-';
    const date = value.split(splitChar)[0];
    const month = value.split(splitChar)[1];
    const year = value.split(splitChar)[2];
    return isDate(moment(`${month}-${date}-${year}`).format('MM-DD-yyyy'))
      ? moment(`${month}-${date}-${year}`).format('MM-DD-yyyy')
      : null;
  }
  return null;
};

export const dateFormat = (value) => {
  return value ? moment(value).format('DD-MM-yyyy hh:mm A') : '-';
};

export const validateCols = (col, count, array, emptyMsg) => {
  if (!col || col?.length === 0) {
    array.push({
      row: count,
      message: `Row ${count} : ${emptyMsg}`
    });
  }
};
export const getDateAndTime = (value) => {
  let resp = moment(value);
  let date = resp.format('DD/MM/yyyy');
  let time = resp.format('hh:mm A');
  return { date, time };
};
export const addDaysToCurrentDate = (daysToAdd) => {
  const currentDate = new Date();
  const newDate = new Date(currentDate);
  newDate.setDate(currentDate.getDate() + daysToAdd);

  return newDate;
};

export const validateDuplication = (
  col,
  filterCol,
  count,
  message,
  prevArr,
  errorArr
) => {
  if (prevArr?.find((prevNode) => prevNode[filterCol] === col)) {
    errorArr.push({
      row: count,
      message: `Row ${count} : ${message}`
    });
  }

  return errorArr;
};

export const setStatus = (status) => {
  let color = '';
  let text = '';
  switch (status) {
    case 'DRAFT':
      color = 'yellowText';
      text = 'Draft';
      break;
    case 'NOT_ASSIGNED':
      color = 'orangeText';
      text = 'Not Assigned';
      break;
    case 'ASSIGNED':
      color = 'yellowText';
      text = 'Assigned';
      break;
    case 'SCHEDULED':
      color = 'yellowText';
      text = 'Scheduled';
      break;
    case 'ON_THE_WAY_PICKUP':
      color = 'yellowText';
      text = 'On The Way To Pickup';
      break;
    case 'ARRIVED_PICKUP':
      color = 'yellowText';
      text = 'Arrived For Pickup';
      break;
    case 'LOAD_DISPATCHED':
      color = 'yellowText';
      text = 'Load Dispatched';
      break;
    case 'ON_THE_WAY_DESTINATION':
      color = 'yellowText';
      text = 'On The Way To Destination';
      break;
    case 'LOADING_IN_PROGRESS':
      color = 'lightGreenText';
      text = 'Loading In Progress';
      break;
    case 'LOADING_COMPLETE':
      color = 'lightGreenText';
      text = 'Loading Complete';
      break;
    case 'UNLOADING_IN_PROGRESS':
      color = 'greenText';
      text = 'Unloading In Progress';
      break;
    case 'UNLOADING_COMPLETE':
      color = 'lightGreenText';
      text = 'Unloading Complete';
      break;
    case 'UNLOADING_COMPLETED':
      color = 'lightGreenText';
      text = 'Unloading Complete';
      break;
    case 'ARRIVED_DESTINATION':
      color = 'greenText';
      text = 'Arrived at destination';
      break;
    case 'JOURNEY_IN_PROGRESS':
      color = 'lightGreenText';
      text = 'Journey In Progress';
      break;
    case 'DOCK_ASSIGNED':
      color = 'lightGreenText';
      text = 'Dock Assigned';
      break;
    case 'FULLY_COMPLETE':
      color = 'lightGreenText';
      text = 'Fully Complete';
      break;
    case 'PARTIALLY_COMPLETED_CLOSED':
      color = 'DarkGreenText';
      text = 'Partially Completed Closed';
      break;
    case 'COMPLETED':
      color = 'DarkGreenText';
      text = 'Completed';
      break;
    case 'PENDING':
      color = 'orangeText';
      text = 'Pending';
      break;
    case 'CANCELLED':
      color = 'grayText';
      text = 'Cancelled';
      break;
    default:
      color = 'black';
      text = status;
  }
  return {
    status,
    text,
    color
  };
};

export const pickingStatus = (status) => {
  switch (status) {
    case 'COMPLETED':
      return (
        <Grid item container className="justify-content-right">
          <img
            src={GreenTick}
            className="mr-3 completedStatus"
            alt="Green Tick"
          />
          <h2 className="completedStatus">Completed</h2>
        </Grid>
      );
    case 'IN_PROGRESS':
      return <h2 className="inProgressStatus">In Progress</h2>;
    case 'ASSIGNED':
      return <h2 className="notAssignedStatus">Pending</h2>;
    case 'NOT_ASSIGNED':
      return <h2 className="notAssignedStatus">Not Assigned</h2>;
    case 'CANCELLED':
      return <h2>Cancelled</h2>;
    default:
      return '';
  }
};

export const dateToPickerFormatYear = (value) =>
  value ? moment(value).format('yyyy-MM-DDTHH:mm') : '';

export const regexSplit = (value) => {
  return value ? value.split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/) : null;
};

export const regexSplitCarriageReturn = (value) => {
  return value ? value.split(/\r\n|\n/) : null;
};

export const isHighlighted = (value, search) => {
  value = value?.toLowerCase();
  search = search?.toLowerCase();
  return value?.includes(search);
};

export const productLabelFormat = (product) => {
  return product?.description || product?.name
    ? `${product?.name ? product?.name + ' - ' : ''} ${product?.description}`
    : '-';
};

export const inventoryLocationFormat = (warehouse) => {
  return warehouse?.name || warehouse?.businessWarehouseCode
    ? `${warehouse?.name ? warehouse?.name + ' - ' : ''} ${
        warehouse?.businessWarehouseCode
      }`
    : '-';
};

export const reasonLabelFormat = (props, isPrint) => {
  return (
    <div
      className={`${isPrint ? 'reason-label-print' : 'reason-label-no-print'}`}
    >
      {props?.name == 'NEW_FOUND'
        ? 'Additional Stock Identified'
        : props.name.charAt(0).toUpperCase() +
            props.name.slice(1).toLowerCase() || ''}
      <span
        className={`reason-type-color ${
          props.name != 'NEW_FOUND'
            ? 'reason-type-color-red'
            : 'reason-type-color-green'
        }`}
      >
        {props.name != 'NEW_FOUND' ? '-' : '+'}
      </span>
    </div>
  );
};

export const checkDates = (date) => {
  let dateMMDDYYFormat = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[1-2]\d|3[01])\/\d{4}$/;
  let dateDDMMYYFormat = /^(0?[1-9]|[1-2]\d|3[01])\/(0?[1-9]|1[0-2])\/\d{4}$/;

  return !!(date.match(dateDDMMYYFormat) || date.match(dateMMDDYYFormat));
};

export const dateFormateHelper = (date) => {
  let dateDDMMYYFormat = /^(0?[1-9]|[1-2]\d|3[01])\/(0?[1-9]|1[0-2])\/\d{4}$/;

  return date.match(dateDDMMYYFormat);
};

export const dateToPickerFormatYearSeconds = (value) =>
  value ? moment(value).format('yyyy-MM-DD HH:mm:ss') : '';

export const bulkDateFormator = (date) => {
  let initial = date.split(/\//);

  if (dateFormateHelper(date)) {
    let resultDate = [initial[1], initial[0], initial[2]].join('/');

    return resultDate ? moment(resultDate).format('yyyy-MM-DD HH:mm:ss') : '';
  } else {
    let resultDate = [initial[0], initial[1], initial[2]].join('/');

    return resultDate ? moment(resultDate).format('yyyy-MM-DD HH:mm:ss') : '';
  }
};

export const dateToPickerFormat = (value) =>
  value ? moment(value).format('DD-MM-yyyyTHH:mm') : '';
export const dividerDateWithoutTimeFormat = (value) =>
  value ? moment(value).format('DD-MM-yyyy') : '-';
export const dividerDateFormat = (value) =>
  value ? moment(value).format('DD-MM-yyyy hh:mm a') : '-';
export const dividerTimeFormat = (value) =>
  value ? moment(value).format('hh:mm A') : '-';

export const dividerDateFormatForFilter = (value) =>
  value ? moment(value).format('DD-MM-yyyy') : '-';

export const dateFormatSlashDivider = (value) =>
  value ? moment(value).format('DD/MM/yyyy') : '-';

export const dateWithYearFirst = (value) =>
  value ? moment(value).format('YYYY-MM-DD') : '-';

export const dividerDateFormatForYear = (value) =>
  value ? moment(value).format('yyyy-MM-DD') : '-';

export const compareDateFormat = (value) =>
  value ? moment(value).format('MM-DD-yyyy') : '-';

export const dateToPickerEndFormat = (value) =>
  value ? moment(value).add(1, 'days').format('yyyy-MM-DDTHH:mm') : '';

export const dividerDateFormat12Hrs = (value) =>
  value ? moment(value).format('MM-DD-yyyy hh:mm a') : null;

export const checkForValidDate = (value) =>
  value instanceof Date && !isNaN(value);

export const SharedContext = createContext(null);

export const isDate = (date) => {
  return new Date(date) != 'Invalid Date' && !isNaN(new Date(date));
};

String.prototype.toTitleCase = function () {
  return this.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const checkForMatchInArray = (array, propertyToMatch, valueToMatch) => {
  for (var i = 0; i < array.length; i++) {
    if (array[i][propertyToMatch] == valueToMatch) return true;
  }
  return false;
};

export const checkForMatchInArrayWithMultiples = (
  array,
  propertyToMatch,
  valueToMatch,
  propertyToMatch2,
  valueToMatch2
) => {
  for (var i = 0; i < array.length; i++) {
    if (
      array[i][propertyToMatch] == valueToMatch &&
      array[i][propertyToMatch2] == valueToMatch2
    )
      return true;
  }
  return false;
};

export const filterZeroQuantity = (array) => {
  return array
    .filter((el) => el.quantity > 0)
    .map((inventory) => {
      inventory.id =
        typeof inventory.id == 'number'
          ? inventory.id
          : parseInt(inventory.id.split('-dup')[0]);
      return inventory;
    });
};

export const checkForZeroQuantityInArray = (array) => {
  for (let el of array) {
    if (el.quantity === 0 || el.quantity === 0) return false;
  }
  return true;
};

export const removeItemFromArrayIfExistInAnotherArray = (
  removeFromThisArray,
  anotherArray
) => {
  for (const item of anotherArray) {
    removeFromThisArray = removeFromThisArray.filter(
      (i) => i.id !== item.outwardId
    );
  }
  return removeFromThisArray;
};
// differenceOfTwoArray([1,2,3],[2]) => result = [1,3]
export const differenceOfTwoArray = (removeFromThisArray, anotherArray) => {
  for (const item of anotherArray) {
    removeFromThisArray = removeFromThisArray.filter((i) => i !== item);
  }
  return removeFromThisArray;
};

export const differenceOfTwoArrayOfObjects = (
  removeFromThisArray,
  anotherArray,
  key
) => {
  for (const item of anotherArray) {
    removeFromThisArray = removeFromThisArray.filter(
      (i) => i[key] !== item[key]
    );
  }
  return removeFromThisArray;
};

export const arraymove = (arr, fromIndex, toIndex) => {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
};

export const sortByKey = (array, key) => {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

export const encryptDate = (string) => {
  let charCodeArr = [];

  for (let i = 0; i < string.length; i++) {
    let code = string.charCodeAt(i);
    let character = String.fromCharCode(code + 34);
    charCodeArr.push(character);
  }

  return charCodeArr.toString().replace(/,/g, '');
};

export const decryptDate = (encrypted) => {
  if (encrypted.indexOf('/') > -1) {
    return encrypted;
  }

  let decrtypCode = encrypted;
  let decrypted = [];

  for (let i = 0; i < decrtypCode.length; i++) {
    let code = decrtypCode.charCodeAt(i);
    let character = String.fromCharCode(code - 34);
    decrypted.push(character);
  }

  return decrypted.toString().replace(/,/g, '');
};

export const convertToCSV = (objArray) => {
  let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  for (var i = 0; i < array.length; i++) {
    var line = '';
    for (var index in array[i]) {
      if (line != '') line += ',';

      line += array[i][index] !== null ? array[i][index] : '';
    }

    str += line + '\r\n';
  }
  return str;
};

export const exportToCSV = (headers, items, fileTitle) => {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);

  var csv = convertToCSV(jsonObject);

  var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

  var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

export const capitalize = (word) => {
  return word?.[0].toUpperCase() + word?.slice(1).toLowerCase();
};

export const inCaseInward = (order) => {
  const shouldShowViewGRN = InwardViewStatuses.includes(order?.status);
  if (order?.status === OrderStatuses?.DOCK_ASSIGNED) {
    return [
      {
        route: `/routing/create-grn/${order?.customId}`,
        text: 'Create GRN'
      }
    ];
  } else if (shouldShowViewGRN) {
    return [
      {
        route: `/routing/create-grn/${order?.customId}`,
        text: 'View GRN'
      }
    ];
  }
};

export const inCaseOutward = (order) => {
  const shouldShowViewGDN = OutboundViewStatuses.includes(order?.status);
  if (order?.status === OrderStatuses?.DOCK_ASSIGNED) {
    return [
      {
        route: `/routing/create-gdn/${order?.customId}`,
        text: 'Create GDN'
      }
    ];
  } else if (shouldShowViewGDN) {
    return [
      {
        route: `/routing/create-gdn/${order?.customId}`,
        text: 'View GDN'
      }
    ];
  }
};

export const inCaseTransfer = (
  order,
  accessForPickUpWarehouse,
  accessForDropOffWarehouse,
  grnFlag,
  gdnFlag
) => {
  const shouldShowViewGDN = TransferViewStatuses.includes(order?.status);
  if (
    order?.status === OrderStatuses?.DOCK_ASSIGNED &&
    (order?.dropOffDockId || order?.dropOffDock) &&
    accessForDropOffWarehouse
  ) {
    return [
      ...(grnFlag
        ? [
            {
              route: `/routing/create-grn/${order?.customId}`,
              text: 'Create GRN'
            }
          ]
        : []),
      ...(gdnFlag
        ? [
            {
              route: `/routing/create-gdn/${order?.customId}`,
              text: 'View GDN'
            }
          ]
        : [])
    ];
  } else if (
    order?.status === OrderStatuses?.DOCK_ASSIGNED &&
    !(order?.dropOffDock || order?.dropOffDockId) &&
    accessForPickUpWarehouse
  ) {
    return [
      ...(gdnFlag
        ? [
            {
              route: `/routing/create-gdn/${order?.customId}`,
              text: 'Create GDN'
            }
          ]
        : [])
    ];
  } else if (
    order?.status === OrderStatuses?.DOCK_ASSIGNED &&
    (order?.dropOffDock || order?.dropOffDockId) &&
    (accessForPickUpWarehouse || accessForDropOffWarehouse)
  ) {
    return [
      ...(gdnFlag
        ? [
            {
              route: `/routing/create-gdn/${order?.customId}`,
              text: 'View GDN'
            }
          ]
        : [])
    ];
  } else if (
    (shouldShowViewGDN && (order?.dropOffDock || order?.dropOffDockId)) ||
    order?.status === OrderStatuses?.LOAD_DISPATCHED
  ) {
    if (order?.status === OrderStatuses?.LOAD_DISPATCHED) {
      return [
        ...(gdnFlag
          ? [
              {
                route: `/routing/create-gdn/${order?.customId}`,
                text: 'View GDN'
              }
            ]
          : [])
      ];
    } else {
      return [
        ...(gdnFlag
          ? [
              {
                route: `/routing/create-gdn/${order?.customId}`,
                text: 'View GDN'
              }
            ]
          : []),
        ...(grnFlag
          ? [
              {
                route: `/routing/create-grn/${order?.customId}`,
                text: 'View GRN'
              }
            ]
          : [])
      ];
    }
  }
};

export const warehouseAccess = async (id) => {
  try {
    if (id) {
      const warehouse = await API.get(`warehouses/${id}/check-access`);
      return warehouse?.data;
    }
  } catch (err) {
    let errors = err.response.data.error.errors;
    errors.map((data) => {
      onError(data);
    });
  }
};

export const findKeyValue = (array, key) => {
  return array[key];
};

export const objectToTextConverter = (obj) => {
  let result = '';
  Object.entries(obj).forEach(([key, value], index) => {
    if (Array.isArray(obj[key])) {
      let tempKey = key[0].toUpperCase() + key.slice(1).toLowerCase();
      if (tempKey.length - 1 == 's') {
        tempKey = tempKey.slice(0, tempKey.length - 1);
      }
      result = result + '/' + tempKey;
      obj[key].map((data, index) => {
        result = result + '/' + (index + 1) + objectToTextConverterSingle(data);
      });
    } else if (
      typeof obj[key] === 'object' &&
      !Array.isArray(obj[key]) &&
      obj[key] !== null
    ) {
      result = result + objectToTextConverterSingle(obj[key]);
    } else {
      if (key == 'createdAt' || key == 'updatedAt' || key == 'shipmentDate') {
        let date = moment(value).format('DD-MM-YYYY hh:mm A');
        result = result + `/ ${key} : ${date}`;
      } else {
        result = result + `/ ${key} : ${value}`;
      }
      if (index < Object.keys(obj).length - 1) {
        result = result + ' , ';
      } else {
        result = result + '.';
      }
    }
  });
  return result;
};

export const objectToTextConverterSingle = (obj) => {
  let result = '';
  Object.entries(obj).forEach(([key, value], index) => {
    if (key == 'OrderGroup' || typeof value === 'object') {
      return result;
    }
    if (key == 'inventoryActivityLogId') {
      result = result + `/\u00a0\u00a0\u00a0\u00a0\u00a0 ${key} : ${value}`;
    } else {
      result = result + `/\u00a0\u00a0\u00a0\u00a0\u00a0 ${key} : ${value}`;
    }
    if (index < Object.keys(obj).length - 1) {
      result = result + ' ,';
    } else {
      result = result + '.';
    }
  });
  return result;
};

export const getPropByString = (obj, propString) => {
  if (!propString) return obj;

  let prop,
    props = propString.split('.'),
    i = 0,
    iLen = props.length - 1;

  for (i; i < iLen; i++) {
    prop = props[i];

    let candidate = obj[prop];
    if (candidate !== undefined) {
      obj = candidate;
    } else {
      break;
    }
  }
  return obj[props[i]];
};

export const removeArrItem = (array, item) => {
  const index = array.indexOf(item);
  if (index > -1) {
    array.splice(index, 1);
  }
  return array;
};

export const addArrItem = (array, item) => {
  array.push(item);
  return array;
};

export const capitalizeString = (str) =>
  str ? str.charAt(0).toUpperCase() + str.slice(1) : '';

export const getPickUpLocationAttribute = (order, attribute) => {
  if (order?.moveType === 'LOGISTIC' || order?.moveType === 'INWARD') {
    return order?.pickupNode ? order?.pickupNode[attribute] || '' : '';
  } else if (order?.moveType === 'OUTBOUND' || order?.moveType === 'TRANSFER') {
    return order?.warehousePickUp
      ? order?.warehousePickUp[attribute] || ''
      : '';
  }

  return '';
};

export const getDropOffLocationAttribute = (order, attribute) => {
  if (order?.moveType === 'LOGISTIC' || order?.moveType === 'OUTBOUND') {
    return order?.dropOffNode ? order?.dropOffNode[attribute] || '' : '';
  } else if (order?.moveType === 'INWARD' || order?.moveType === 'TRANSFER') {
    return order?.warehouseDropOff
      ? order?.warehouseDropOff[attribute] || ''
      : '';
  }

  return '';
};

export const getOrgSubdomain = (url) => {
  const parts = url.split('.');
  if (
    (process.env.NODE_ENV === 'development' && parts.length >= 2) ||
    parts.length >= 3
  ) {
    return parts[0];
  } else {
    return null;
  }
};

export const commaSeparator = (value) => {
  value.toLocaleString(undefined, { maximumFractionDigits: 2 });
};

export const capitalizeWords = (str) => {
  str = str.replace('_', ' ');
  const words = str.split(' ');
  const capitalizedWords = words.map((word) => {
    if (word.length === 0) return word;
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  const capitalizedString = capitalizedWords.join(' ');
  return capitalizedString;
};

export const numberToWords = (number) => {
  // Arrays for one-digit and two-digit numbers as words
  const oneDigitWords = [
    '',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine'
  ];
  const twoDigitWords = [
    '',
    'Ten',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety'
  ];

  // Arrays for special cases and teens
  const specialWords = [
    '',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen'
  ];

  // Function to convert a number less than 100 to words
  function convertLessThanHundred(num) {
    if (num < 10) {
      return oneDigitWords[num];
    } else if (num < 20) {
      return specialWords[num - 10];
    } else {
      const tens = Math.floor(num / 10);
      const ones = num % 10;
      return twoDigitWords[tens] + ' ' + oneDigitWords[ones];
    }
  }

  // Function to convert a number to words
  function convertToWords(number) {
    if (number === 0) {
      return 'Zero';
    }
    if (number < 10) {
      return oneDigitWords[number];
    }
    if (number < 100) {
      return convertLessThanHundred(number);
    }
    if (number < 1000) {
      const hundreds = Math.floor(number / 100);
      const remainder = number % 100;
      const hundredWord = oneDigitWords[hundreds] + ' Hundred';
      if (remainder === 0) {
        return hundredWord;
      } else {
        return hundredWord + ' and ' + convertLessThanHundred(remainder);
      }
    }
  }

  return convertToWords(number);
};

export const decimalDisplay = (value) => {
  let num = parseFloat(value);

  if (!isNaN(num)) {
    let formattedNum = num.toLocaleString('en-US', {
      maximumFractionDigits: 3,
      minimumFractionDigits: 3
    });

    formattedNum = formattedNum.replace(/\.?0+$/, '');
    formattedNum = formattedNum.replace(/\.$/, '');

    return formattedNum;
  } else {
    return '-';
  }
};

export const formatNumberToKAndM = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(2) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(2) + 'K';
  }
  return num.toString();
};

export const formatWeight = (weightInKg) => {
  if (weightInKg >= 1000) {
    return (weightInKg / 1000).toFixed(2);
  }
  return weightInKg.toFixed(3);
};

export const getCurrencySymbol = (currencyCode) => {
  return currencySymbols[currencyCode]?.symbol_native || currencyCode;
};

export function toSuperscript(number) {
  const superscriptDigits = '⁰¹²³⁴⁵⁶⁷⁸⁹';
  return number
    .toString()
    .split('')
    .map((digit) => superscriptDigits[digit])
    .join('');
}

export const calculateDateRangeFromTwoDates = (startDate, endDate) => {
  const define = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date(), 1),
    lastQuarterStartDate: startOfMonth(addMonths(new Date(), -2)),
    lastQuarterEndDate: endOfMonth(new Date()),
    lastSixMonthsStartDate: startOfMonth(addMonths(new Date(), -5)),
    lastSixMonthsEndDate: endOfMonth(new Date())
  };

  let text = 'Custom';

  if (startDate >= define.startOfWeek && endDate <= define.endOfWeek) {
    text = 'This Week';
  } else if (startDate >= define.startOfMonth && endDate <= define.endOfMonth) {
    text = 'This Month';
  } else if (
    startDate >= define.lastQuarterStartDate &&
    endDate <= define.lastQuarterEndDate
  ) {
    text = 'This Quarter';
  } else if (
    startDate >= define.lastSixMonthsStartDate &&
    endDate <= define.lastSixMonthsEndDate
  ) {
    text = 'Last 6 Months';
  }

  return text;
};

export const calculateDateRangeFromDates = (startDate, endDate) => {
  const define = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    lastQuarterStartDate: startOfMonth(addMonths(new Date(), -2)),
    lastQuarterEndDate: endOfMonth(new Date()),
    lastSixMonthsStartDate: startOfMonth(addMonths(new Date(), -5)),
    lastSixMonthsEndDate: endOfMonth(new Date())
  };
  let text = '';
  if (startDate >= define.startOfWeek && endDate >= define.endOfWeek) {
    text = 'This Week';
  } else if (startDate >= define.startOfMonth && endDate >= define.endOfMonth) {
    text = 'This Month';
  } else if (
    startDate >= define.lastQuarterStartDate &&
    endDate >= define.lastQuarterEndDate
  ) {
    text = 'This Quarter';
  } else if (
    startDate >= define.lastSixMonthsStartDate &&
    endDate >= define.lastSixMonthsEndDate
  ) {
    text = 'Last 6 Month';
  } else {
    text = 'Custom';
  }
  return text;
};

export function getPreviousPeriod(startDate, endDate) {
  // Parse the input dates
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the difference in milliseconds
  const diffTime = end.getTime() - start.getTime();

  // Calculate the previous period's end date by subtracting one day from the start date
  const previousEnd = new Date(start);
  previousEnd.setDate(previousEnd.getDate() - 1);

  // Calculate the previous period's start date by subtracting the duration from the previous period's end date
  const previousStart = new Date(previousEnd.getTime() - diffTime);

  // Format the dates to YYYY-MM-DD for output
  const format = (date) =>
    `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      '0'
    )}-${String(date.getDate()).padStart(2, '0')}`;

  return {
    previousStartDate: format(previousStart),
    previousEndDate: format(previousEnd)
  };
}

export const calculateEuclideanDistance = async (coord1, coord2, mode = "DRIVING") => {
  if (!window.google || !window.google.maps) {
    console.error("Google Maps API is not loaded properly.");
    return 0;
  }

  if (!coord1 || !coord2 || !coord1.lat || !coord1.lng || !coord2.lat || !coord2.lng) {
    console.error("Invalid coordinates provided.");
    return 0;
  }

  return new Promise((resolve, reject) => {
    try {
      const service = new window.google.maps.DistanceMatrixService();

      service.getDistanceMatrix(
        {
          origins: [{ lat: coord1.lat, lng: coord1.lng }],
          destinations: [{ lat: coord2.lat, lng: coord2.lng }],
          travelMode: mode,
        },
        (response, status) => {
          if (status === "OK") {
            const result = response.rows[0]?.elements[0];
            if (result?.status === "OK") {
              const distance = result.distance?.value / 1000;
              resolve(parseFloat(distance.toFixed(3)));
            } else {
              console.error(`Error in response: ${result?.status}`);
              resolve(0);
            }
          } else {
            console.error(`Distance Matrix API error: ${status}`);
            reject(new Error(`Distance Matrix API error: ${status}`));
          }
        }
      );
    } catch (error) {
      console.error("Error calculating distance:", error);
      reject(error);
    }
  });
};

export const orderRead = (organizationType, user) => {
  return (
    (organizationType === ORGANIZATION_TYPES.MANUFACTURER &&
      (checkPermission(user, 'OPS_SALES_ORDER_READ') ||
        checkPermission(user, 'OPS_PURCHASE_ORDER_READ') ||
        checkPermission(user, 'OPS_SALES_RETURN_ORDER_READ') ||
        checkPermission(user, 'OPS_TRANSFER_ORDER_READ'))) ||
    (organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER &&
      (checkPermission(user, 'OPS_INBOUND_ORDER_READ') ||
        checkPermission(user, 'OPS_OUTBOUND_ORDER_READ') ||
        checkPermission(user, 'OPS_LOGISTIC_ORDER_READ') ||
        checkPermission(user, 'OPS_TRANSFER_ORDER_READ')))
  );
};

export const orderCreate = (organizationType, user) => {
  return (
    (organizationType === ORGANIZATION_TYPES.MANUFACTURER &&
      (checkPermission(user, 'OPS_SALES_ORDER_CREATE') ||
        checkPermission(user, 'OPS_PURCHASE_ORDER_CREATE') ||
        checkPermission(user, 'OPS_SALES_RETURN_ORDER_CREATE') ||
        checkPermission(user, 'OPS_TRANSFER_ORDER_CREATE'))) ||
    (organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER &&
      (checkPermission(user, 'OPS_INBOUND_ORDER_CREATE') ||
        checkPermission(user, 'OPS_OUTBOUND_ORDER_CREATE') ||
        checkPermission(user, 'OPS_LOGISTIC_ORDER_CREATE') ||
        checkPermission(user, 'OPS_TRANSFER_ORDER_CREATE')))
  );
};

export const orderUpdate = (organizationType, user) => {
  return (
    (organizationType === ORGANIZATION_TYPES.MANUFACTURER &&
      (checkPermission(user, 'OPS_SALES_ORDER_UPDATE') ||
        checkPermission(user, 'OPS_PURCHASE_ORDER_UPDATE') ||
        checkPermission(user, 'OPS_SALES_RETURN_ORDER_UPDATE') ||
        checkPermission(user, 'OPS_TRANSFER_ORDER_UPDATE'))) ||
    (organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER &&
      (checkPermission(user, 'OPS_INBOUND_ORDER_UPDATE') ||
        checkPermission(user, 'OPS_OUTBOUND_ORDER_UPDATE') ||
        checkPermission(user, 'OPS_LOGISTIC_ORDER_UPDATE') ||
        checkPermission(user, 'OPS_TRANSFER_ORDER_UPDATE')))
  );
};

export const orderCancel = (organizationType, user) => {
  return (
    (organizationType === ORGANIZATION_TYPES.MANUFACTURER &&
      (checkPermission(user, 'OPS_SALES_ORDER_CANCEL') ||
        checkPermission(user, 'OPS_PURCHASE_ORDER_CANCEL') ||
        checkPermission(user, 'OPS_SALES_RETURN_ORDER_CANCEL') ||
        checkPermission(user, 'OPS_TRANSFER_ORDER_CANCEL'))) ||
    (organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER &&
      (checkPermission(user, 'OPS_INBOUND_ORDER_CANCEL') ||
        checkPermission(user, 'OPS_OUTBOUND_ORDER_CANCEL') ||
        checkPermission(user, 'OPS_LOGISTIC_ORDER_CANCEL') ||
        checkPermission(user, 'OPS_TRANSFER_ORDER_CANCEL')))
  );
};

export const orderExport = (organizationType, user) => {
  return (
    (organizationType === ORGANIZATION_TYPES.MANUFACTURER &&
      (checkPermission(user, 'OPS_SALES_ORDER_EXPORT') ||
        checkPermission(user, 'OPS_PURCHASE_ORDER_EXPORT') ||
        checkPermission(user, 'OPS_SALES_RETURN_ORDER_EXPORT') ||
        checkPermission(user, 'OPS_TRANSFER_ORDER_EXPORT'))) ||
    (organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER &&
      (checkPermission(user, 'OPS_INBOUND_ORDER_EXPORT') ||
        checkPermission(user, 'OPS_OUTBOUND_ORDER_EXPORT') ||
        checkPermission(user, 'OPS_LOGISTIC_ORDER_EXPORT') ||
        checkPermission(user, 'OPS_TRANSFER_ORDER_EXPORT')))
  );
};

export const calculateHoursUntilDelivery = (expectedDelivery) => {
  if (!expectedDelivery) {
    return null;
  }
  const currentTime = new Date();
  const deliveryTime = new Date(expectedDelivery);
  const differenceInMilliseconds = deliveryTime - currentTime;
  const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
  return differenceInHours;
};

export const computeDistance = (coords1, coords2) => {
  if (!coords1 || !coords2) return 0;
  const toRadians = (degrees) => degrees * (Math.PI / 180);

  const R = 6371;
  const lat1 = toRadians(coords1.lat);
  const lon1 = toRadians(coords1.lng);
  const lat2 = toRadians(coords2.lat);
  const lon2 = toRadians(coords2.lng);

  const dLat = lat2 - lat1;
  const dLon = lon2 - lon1;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;
  return distance;
};

export const calculateETA = (
  distance,
  avgSpeed,
  deliveryDate,
  shipmentStatus
) => {
  if (shipmentStatus !== 'COMPLETED') {
    const timeInHours = avgSpeed > 0 ? distance / avgSpeed : 0;

    if (timeInHours <= 0) {
      const { hours, minutes } = calculateTimeDifference(
        deliveryDate,
        shipmentStatus
      );
      return `${hours}h ${minutes}min`;
    }

    if (timeInHours >= 24) {
      const days = Math.floor(timeInHours / 24);
      return `${days} day${days > 1 ? 's' : ''}`;
    }

    const hours = Math.floor(timeInHours);
    const minutes = Math.floor((timeInHours % 1) * 60);
    return `${hours}h ${minutes}min`;
  } else {
    return '0h 0min';
  }
};

export const calculateTimeDifference = (dropoffDateTime, shipmentStatus) => {
  if (dropoffDateTime && shipmentStatus !== 'COMPLETED') {
    const dateTime1 = new Date(dropoffDateTime);
    const currentDateTime = new Date();
    if (dateTime1 < currentDateTime) {
      return { hours: 0, minutes: 0 };
    }
    const timeDifferenceMillis = Math.abs(dateTime1 - currentDateTime);

    const hours = Math.floor(timeDifferenceMillis / (1000 * 60 * 60));
    const minutes = Math.floor(
      (timeDifferenceMillis % (1000 * 60 * 60)) / (1000 * 60)
    );

    return { hours, minutes };
  } else {
    return { hours: 0, minutes: 0 };
  }
};

export const isArabCountry = (org) => {
  return ['SAR', 'AED']?.includes(org?.defaultCurrency);
};

export const formatDate = (isoDate) => {
  if (!isoDate) return 'N/A';
  return new Date(isoDate)?.toLocaleDateString();
};

export const replaceSHPwithWB = (customId) => {
  return customId?.replace(/^SHP-/, 'WB-');
};
