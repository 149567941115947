/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Tab, Tabs } from '@material-ui/core';
import { useNavigate } from 'react-router';

import { SharedContext } from '../../../utils/common';
import DetailText from '../../../atomicComponents/DetailText';
import InvoicesTab from './InvoicesTab';
import DraftsTab from './DraftsTab';
import CancelledTab from './CancelledTab';
import {
  InvoiceFiltersInitialValue,
  cancelledFilterInitialValue
} from '../expenseManagement/ExpenseManagement';
import API from '../../../libs/axios';
import { checkPermission } from '../../../utils/auth';
import useStyles from '../invoiceStyles';
import mixpanel from 'mixpanel-browser';
import { ReceivableStats } from './receivableStats';
import clsx from 'clsx';
import DateRangePickerSingleCalendar from '../../reporting/ledgerReports/DateRangePicker';
import Button from '../../../core-components/atoms/Button';
import Popup from '../../../core-components/atoms/Popup';
import { CreditNotePopup } from './CreditNotePopup';
import CreditNotes from './CreditNote';
import { onError } from '../../../libs/errorLib';

export default function InvoiceManagement() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [filterStateInvoices, setFilterStateInvoices] = useState(
    InvoiceFiltersInitialValue
  );
  const [filterStateDrafts, setFilterStateDrafts] = useState(
    InvoiceFiltersInitialValue
  );
  const [filterCancelledInvoice, setFilterCancelInvoice] = useState(
    cancelledFilterInitialValue
  );
  const [invoiceData, setInvoiceDate] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [search, setSearch] = useState('');
  const [invoicesLength, setInvoicesLength] = useState(0);
  const [draftLength, setDraftLength] = useState(0);
  const [cancelled, setCancelled] = useState(0);
  const navigate = useNavigate();
  const [stats, setStats] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [creditNotePopup, setCreditNotePopup] = useState(false);
  const [creditNotes, setCreditNotes] = useState([])
  const [creditNotePage, setCreditNotePage] = useState(1)
  const [creditNotePageCount, setCreditNotePageCount] = useState(0)
  const [creditNoteSearch, setCreditNoteSearch] = useState('')

  const { setCurrentPageTitle, setAPILoader, currentUser } =
    useContext(SharedContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getStats = async () => {
    try {
      setAPILoader(true);
      const stats = await API.get('invoices/stats', {
        params: { billing: false }
      });
      setInvoicesLength(stats.invoices);
      setDraftLength(stats.drafts);
      setCancelled(stats.cancelled);
    } catch (e) {
      // onError(e)
    } finally {
      setAPILoader(false);
    }
  };

  const getTopStats = async (startDate, endDate) => {
    try {
      setAPILoader(true);
      const stats = await API.get('invoices/invoice-amount-stats', {
        params: {
          startDate,
          endDate
        }
      });
      setStats(stats);
    } catch (e) {
      // onError(e)
    } finally {
      setAPILoader(false);
    }
  };

  const getCreditNotes = async () => {
    try {
      setAPILoader(true);
      const params = {
        page: creditNotePage,
        search: creditNoteSearch,
        type: 'credit'
      };

      const response = await API.get('invoices/credit-note', { params });
      setCreditNotes(response.data || []);
      setCreditNotePageCount(response.pages);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    setCurrentPageTitle('Receivables');
    mixpanel.track('Receivables Page', {});
    getStats();
    getTopStats(startDate, endDate);
  }, []);

  useEffect(() => {
      getCreditNotes();
    }, [creditNoteSearch, creditNotePage]);

  return (
    <div>
      <ReceivableStats stats={stats} />
      <Grid
        item
        container
        className="mt-10"
        style={{ width: '95%', margin: 'auto' }}
      >
        <Box className={classes.borderBottom} mt={5}>
          <Box className={classes.tabsContainer}>
            <Tabs value={value} onChange={handleChange}>
              <Tab
                label={
                  <DetailText
                    text={`Invoices (${invoicesLength})`}
                    style={{ textTransform: 'none', fontSize: '1rem' }}
                    active={value == 0}
                  />
                }
              />
              <Tab
                label={
                  <DetailText
                    text={`Drafts (${draftLength})`}
                    style={{ textTransform: 'none', fontSize: '1rem' }}
                    active={value == 1}
                  />
                }
              />
              <Tab
                label={
                  <DetailText
                    text={`Cancelled (${cancelled})`}
                    style={{ textTransform: 'none', fontSize: '1rem' }}
                    active={value == 2}
                  />
                }
              />

              <Tab
                label={
                  <DetailText
                    text={`Credit Notes`}
                    style={{ textTransform: 'none', fontSize: '1rem' }}
                    active={value == 3}
                  />
                }
              />
            </Tabs>
          </Box>
          <div className={'flex gap-2'}>
            <DateRangePickerSingleCalendar
              startDate={startDate}
              endDate={endDate}
              updateRange={async (input) => {
                setStartDate(input.selection.startDate);
                setEndDate(input.selection.endDate);
                await getTopStats(
                  input.selection.startDate,
                  input.selection.endDate
                );
              }}
              showDatesInButton={false}
            />

            {checkPermission(currentUser, 'OPS_CREDIT_NOTE_CREATE') && (
              <Button
                onClick={() => setCreditNotePopup(true)}
                variant="primary"
                labelClass="font-medium text-xs"
                className="ml-2 h-8 rounded border-solid"
              >
                <span className={classes.addIcon}>+</span>
                <span>Credit Note</span>
              </Button>
            )}

            {checkPermission(currentUser, 'OPS_INVOICE_CREATE') && (
              <Button
                variant="primary"
                onClick={() => navigate('/billing-invoice/receivables/create')}
                className="ml-2 h-8 rounded border-solid"
              >
                <span className={classes.addIcon}>+</span>
                <span>Add Invoice</span>
              </Button>
            )}
          </div>
        </Box>
        {value === 0 && (
          <InvoicesTab
            tab={value}
            billing={false}
            filterStateInvoices={filterStateInvoices}
            setFilterStateInvoices={setFilterStateInvoices}
            getStats={getStats}
          />
        )}
        {value === 1 && (
          <DraftsTab
            tab={value}
            billing={false}
            filterStateDrafts={filterStateDrafts}
            setFilterStateDrafts={setFilterStateDrafts}
          />
        )}
        {value === 2 && (
          <CancelledTab
            tab={value}
            billing={false}
            filterCancelledInvoice={filterCancelledInvoice}
            setFilterCancelInvoice={setFilterCancelInvoice}
          />
        )}

        {value === 3 && <CreditNotes type="credit" creditNotes={creditNotes} page={creditNotePage} setPage={setCreditNotePage} pageCount={creditNotePageCount} search={creditNoteSearch} setSearch={setCreditNoteSearch} refetch={async () => await getCreditNotes()} />}
      </Grid>

      <Popup
        open={creditNotePopup}
        setOpen={setCreditNotePopup}
        content={
          <div className="min-w-[600px]">
            <CreditNotePopup setOpen={setCreditNotePopup} type="credit" refetch={async () => await getCreditNotes()} />
          </div>
        }
        title={<span>Create Credit Note</span>}
      />
    </div>
  );
}
