/*eslint-disable*/
import {
  Collapse,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { useContext, useState } from 'react';

import ShipmentIcon from '../../../../assets/icons/shipment.svg';
import CancelledIcon from '../../../../assets/icons/cancelled.svg';
import EditIcon from '../../../../assets/icons/edit-row.svg';
import LinearProgressBar from '../../../../core-components/atoms/LinerProgressBar';
import { CRIMSON_500, JADE_700, PRIMARY } from '../../../../constants/colors';
import OptionsMenu from '../../Options';
import AddShipmentTMS from '../../AddShipmentTMS';
import DockAssignment from '../../DockAssignment';
import { toaster } from '../../../../utils/toaster';
import { onError } from '../../../../libs/errorLib';
import API from '../../../../libs/axios';
import Button from '../../../../core-components/atoms/Button';
import truckIcon from '../../../../assets/icons/TruckIcon.svg';
import {
  SharedContext,
  decimalDisplay,
  getCurrencySymbol
} from '../../../../utils/common';
import { RemoveRedEye } from '@material-ui/icons';
import ShipmentDetail from '../../ShipmentDetail';
import EyeIcon from '../../../../assets/icons/eye.svg';
import ShipIcon from '../../../../assets/icons/ShipIcon.svg';
import AirplaneIcon from '../../../../assets/icons/AirplaneIcon.svg';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import FLAGS from '../../../../constants/featureFlags';
import PopupButton from '../../../../core-components/molecules/PopupButton';
import PodPopup from '../PodPopup';
import ViewPodPopup from '../ViewPodPopup';

export const OutboundTMSShipmentTracking = ({
  order,
  expanded,
  setActiveShipment,
  setActiveShipmentOrder,
  shipmentIdFilter,
  activeShipment,
  setCancelPopUp,
  refetch
}) => {
  const [open, setOpen] = useState(false);
  const [shipmentDetailView, setShipmentDetailView] = useState(false);
  const [action, setAction] = useState('edit');

  return (
    <TableRow>
      <TableCell colSpan={8} sx={{ paddingLeft: '16px', paddingRight: '32px' }}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <div>
            <Table class="border-separate border-spacing-y-4 border-spacing-x-0 w-full">
              <TableBody className="w-full">
                {order.Shipments?.length > 0 &&
                  order?.Shipments?.map((shipment, id) => {
                    return (
                      <OutBoundShipmentRow
                        setActiveShipment={setActiveShipment}
                        setActiveShipmentOrder={setActiveShipmentOrder}
                        shipment={shipment}
                        order={order}
                        setCancelPopUp={setCancelPopUp}
                        setShipmentDetailView={setShipmentDetailView}
                        expanded={expanded}
                        shipmentIdFilter={shipmentIdFilter}
                        setAction={setAction}
                        refetch={refetch}
                        id={id}
                        setOpen={setOpen}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </Collapse>
      </TableCell>

      {shipmentDetailView && (
        <ShipmentDetail
          open={shipmentDetailView}
          shipmentId={activeShipment?.customId}
          setOpen={setShipmentDetailView}
          selectedShipmentMode={activeShipment?.ShipmentLegs?.[0]?.mode}
          order={order}
          onClose={async () => await refetch()}
        />
      )}

      {open && (
        <AddShipmentTMS
          shipment={activeShipment}
          open={open}
          setOpen={setOpen}
          selectedOrder={order}
          edit={true}
          refetch={refetch}
          action={action}
          onClose={() => setAction('edit')}
          editable={
            !(
              (activeShipment?.status?.key == 'IN_TRANSIT' &&
                activeShipment?.subStatus?.key == 'ARRIVED_AT_DESTINATION') ||
              activeShipment?.status.key == 'COMPLETED' ||
              activeShipment?.status.key == 'CANCELLED'
            )
          }
        />
      )}
    </TableRow>
  );
};

export const OutBoundShipmentRow = ({
  shipment,
  setActiveShipment,
  setActiveShipmentOrder,
  setCancelPopUp,
  setShipmentDetailView,
  order,
  expanded,
  shipmentIdFilter,
  id,
  setAction,
  refetch,
  setOpen
}) => {
  const { organization } = useContext(SharedContext);
  const { isFeatureEnabled } = useFeatureFlags();
  const [podOpen, setPodOpen] = useState(false);
  const [waseeqaPopup, setWaseeqaPopup] = useState(false);
  const [podDownloadOpen, setPodDownloadOpen] = useState(false);

  const assignDock = async (orderId, shipmentId, dockId) => {
    try {
      await API.put(`orders/${orderId}/shipments/${shipmentId}`, {
        shipmentPayload: {
          status: 'DOCK_ASSIGNED',
          dockId
        }
      });
      toaster('success', 'Dock assigned successfully');
      refetch();
    } catch (err) {
      toaster('error', 'Failed to assign dock');
      onError(err);
    } finally {
    }
  };

  let cases = {
    AWAITING_CARRIER:
      shipment?.status?.key == 'CREATED' &&
      shipment?.ShipmentLegs[0]?.status == 'AWAITING_CARRIER',
    CARRIER_ASSIGNED:
      shipment?.status?.key == 'CREATED' &&
      shipment?.ShipmentLegs[0]?.status == 'CARRIER_ASSIGNED' &&
      !shipment?.dockId,
    DOCK_ASSGINED:
      shipment?.status?.key == 'CREATED' &&
      shipment?.subStatus?.key == 'DOCK_ASSIGNED',
    OUT_FOR_DELIVERY: shipment?.status?.key == 'IN_TRANSIT',
    ARRIVED_AT_DESTINATION: shipment?.status?.key == 'ARRIVED_AT_DESTINATION',
    COMPLETED: shipment?.status?.key == 'COMPLETED',
    CANCELLED: shipment?.status?.key == 'CANCELLED'
  };

  const expectedDeliveryDate = moment(shipment.dropoffDateTime);
  const currentDate = moment();
  const timeDifference = expectedDeliveryDate.diff(currentDate);
  const daysDifference =
    moment.duration(timeDifference).asDays() < 0
      ? '0'
      : moment.duration(timeDifference).asDays().toFixed(0);

  const className = `mt-4 mb-4 pt-2 pb-2 w-full border
                        ${
                          !cases.ARRIVED_AT_DESTINATION &&
                          !cases.COMPLETED &&
                          !cases.CANCELLED
                            ? 'bg-bgCreated'
                            : cases.ARRIVED_AT_DESTINATION || cases.COMPLETED
                            ? 'bg-jade100'
                            : 'bg-bgCancelled'
                        }`;
  const options = [];
  if (!cases.CANCELLED) {
    options.push({
      label: (
        <div className="flex gap-3">
          <img src={EditIcon} className="w-4" />
          <span>Edit Shipment</span>
        </div>
      ),
      onClick: () => {
        setActiveShipment(shipment);
        setActiveShipmentOrder(order);
        setOpen(true);
      }
    });
  }
  if (
    !cases.CANCELLED &&
    !cases.COMPLETED &&
    !cases.ARRIVED_AT_DESTINATION &&
    !cases.OUT_FOR_DELIVERY &&
    isFeatureEnabled(FLAGS.SHIPMENT_CANCELLATION)
  ) {
    options.push(
      ...[
        {
          label: (
            <div className="flex gap-3">
              <img src={CancelledIcon} className="w-4" />
              <span>Cancel Shipment</span>
            </div>
          ),
          onClick: () => {
            setActiveShipment(shipment);
            setActiveShipmentOrder(order);
            setCancelPopUp(true, 'shipment');
          },
          icon: CancelledIcon
        }
      ]
    );
  }

  options.push({
    label: (
      <div className="flex gap-3">
        <img src={EyeIcon} className="w-4" />
        <span>View Shipment Detail</span>
      </div>
    ),
    onClick: () => {
      setShipmentDetailView(true);
      setActiveShipment(shipment);
      setActiveShipmentOrder(order);
    }
  });

  const bgStyle = cases.OUT_FOR_DELIVERY
    ? 'bg-[#000] text-[#fff]'
    : cases.ARRIVED_AT_DESTINATION
    ? 'border-jade700 text-jade700'
    : cases.COMPLETED
    ? 'bg-jade700 border-jade700'
    : cases.CANCELLED
    ? 'bg-crimson700 border-none'
    : 'border-black';

  const textStyle =
    cases.OUT_FOR_DELIVERY || cases.COMPLETED || cases.CANCELLED
      ? 'text-[#fff]'
      : cases.ARRIVED_AT_DESTINATION
      ? 'text-jade700'
      : '';

  const mode = shipment?.ShipmentLegs?.[0]?.mode;
  const shipmentCost = shipment?.Expenses?.reduce((acc, v) => acc + v.cost, 0);

  const duration = moment.duration(
    moment(shipment.completedAt).diff(shipment.createdAt)
  );
  const durationInDays = duration.asDays();

  const formattedDuration =
    durationInDays >= 1 ? `${Math.round(durationInDays)} days` : '1 day';

  return (
    <TableRow
      sx={expanded && { '& > *': { borderBottom: 'unset' } }}
      className={className}
      key={id}
    >
      <TableCell sx={{ width: '25%' }} className="border-none">
        <div className="flex gap-[8px]">
          <p className="font-semibold text-sm flex items-center gap-2">
            <span
              className={`${
                shipmentIdFilter &&
                shipment.id == shipmentIdFilter.id &&
                'bg-highlight'
              }`}
            >
              {shipment.customId}
            </span>
          </p>
          <div className="flex gap-4">
            <div
              className={`flex items-center justify-center py-1 px-[6px] border rounded-[5px] ${bgStyle}`}
            >
              <span className={`text-[10px] ${textStyle} font-medium`}>
                {cases.AWAITING_CARRIER ||
                cases.CARRIER_ASSIGNED ||
                cases.DOCK_ASSGINED
                  ? 'Ready for pickup'
                  : cases.OUT_FOR_DELIVERY
                  ? 'In Transit'
                  : cases.ARRIVED_AT_DESTINATION
                  ? 'Arrived at destination'
                  : cases.COMPLETED
                  ? 'Delivered'
                  : 'Cancelled'}
              </span>
            </div>
            {shipment?.shipmentTracking?.currentEvent && (
              <div
                className={`flex items-center justify-center py-1 px-[6px] border rounded-[5px] ${bgStyle}`}
              >
                <span className={`text-[10px] ${textStyle} font-medium`}>
                  {shipment?.shipmentTracking?.currentEvent}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="mt-1">
          <p className="text-primaryBlue text-xs">
            {cases.CANCELLED
              ? `CANCELLED :   ${moment(shipment.updatedAt).format(
                  'DD/MM/YYYY'
                )}`
              : cases.COMPLETED
              ? `Delivered in: ${formattedDuration}`
              : `EST: ${
                  daysDifference && daysDifference != 'NaN'
                    ? `${daysDifference || 1} days`
                    : '-'
                }`}
          </p>
          <p className="text-primaryBlue text-xs mt-4">
            Cost: {`${getCurrencySymbol(organization.defaultCurrency)}`}{' '}
            {decimalDisplay(shipmentCost)}
          </p>
        </div>
      </TableCell>
      <TableCell
        sx={{
          width: '40%',
          paddingLeft: 0,
          paddingRight: 0,
          textAlign: 'left'
        }}
        className="border-none"
      >
        <div className="w-full">
          {cases.AWAITING_CARRIER ? (
            <div className="flex gap-2 justify-center items-center  mb-2">
              <img
                src={
                  mode == 'LAND'
                    ? truckIcon
                    : mode == 'SEA'
                    ? ShipIcon
                    : AirplaneIcon
                }
              />
              <span className="text-xs">Awaiting Carrier Details</span>
            </div>
          ) : cases.CARRIER_ASSIGNED ? (
            <div className="flex gap-2 justify-center items-center  mb-2">
              <img
                src={
                  mode == 'LAND'
                    ? truckIcon
                    : mode == 'SEA'
                    ? ShipIcon
                    : AirplaneIcon
                }
              />
              <span className="text-xs">Carrier Assigned</span>
            </div>
          ) : cases.DOCK_ASSGINED ? (
            <div className="flex gap-2 justify-center items-center  mb-2">
              <img
                src={
                  mode == 'LAND'
                    ? truckIcon
                    : mode == 'SEA'
                    ? ShipIcon
                    : AirplaneIcon
                }
              />
              <span className="text-xs">Dock Assigned</span>
            </div>
          ) : cases.OUT_FOR_DELIVERY ? (
            <div className="flex gap-2 justify-center items-center  mb-2">
              <img
                src={
                  mode == 'LAND'
                    ? truckIcon
                    : mode == 'SEA'
                    ? ShipIcon
                    : AirplaneIcon
                }
              />
              <span className="text-xs">Out For Delivery</span>
            </div>
          ) : cases.ARRIVED_AT_DESTINATION ? (
            <div className="flex gap-2 justify-center items-center  mb-2">
              <img
                src={
                  mode == 'LAND'
                    ? ShipmentIcon
                    : mode == 'SEA'
                    ? ShipIcon
                    : AirplaneIcon
                }
              />
              <span className="text-xs">Reached</span>
            </div>
          ) : cases.CANCELLED ? (
            <div className="flex justify-center mb-2">
              <span className="text-xs">
                Cancelled By{' '}
                {shipment.cancelledBy?.firstName +
                  ' ' +
                  shipment.cancelledBy?.lastName}
              </span>
            </div>
          ) : (
            <div className="flex gap-2 items-center justify-center mb-2">
              <span className="text-xs">Completed - </span>
              <span className="text-xs">
                {moment(shipment.updatedAt).format('DD/MM/YYYY')}
              </span>
              <span className="text-xs">
                {moment(shipment.updatedAt).format('hh:mm A')}
              </span>
            </div>
          )}
          <div className="flex items-center w-full">
            <div
              className={`rounded-full h-[6px] w-[6px] ${
                cases.ARRIVED_AT_DESTINATION || cases.COMPLETED
                  ? 'bg-jade700'
                  : cases.CANCELLED
                  ? 'bg-crimson500'
                  : 'bg-primary'
              }`}
            ></div>
            <LinearProgressBar
              variant="determinate"
              value={100}
              sx={{
                backgroundColor: 'white',
                '& .MuiLinearProgress-bar': {
                  backgroundColor:
                    cases.ARRIVED_AT_DESTINATION || cases.COMPLETED
                      ? JADE_700
                      : cases.CANCELLED
                      ? CRIMSON_500
                      : PRIMARY
                },
                border: `1px ${
                  cases.ARRIVED_AT_DESTINATION || cases.COMPLETED
                    ? JADE_700
                    : cases.CANCELLED
                    ? CRIMSON_500
                    : PRIMARY
                } solid`,
                borderRadius: '1000px',
                height: '2px',
                width: '100%'
              }}
            />
            <div
              className={`rounded-full h-[6px] w-[6px] ${
                cases.ARRIVED_AT_DESTINATION || cases.COMPLETED
                  ? 'bg-jade700'
                  : cases.CANCELLED
                  ? 'bg-crimson500'
                  : 'bg-primary'
              }`}
            ></div>
          </div>
          <div className="mt-2 text-center">
            <p className="flex gap-2 justify-center">
              <span className="text-sm">{order.pickupNode?.name || '-'}</span>
              {order.dropOffNode?.name && (
                <span className="text-[24px] leading-[50%]">&#8594;</span>
              )}
              <span className="text-sm">{order.dropOffNode?.name}</span>
            </p>
          </div>
        </div>
      </TableCell>
      <TableCell className="border-none" sx={{ width: '35%', border: 'none' }}>
        <div className="flex justify-end gap-4 items-center">
          <ActionBtns
            assignDock={assignDock}
            order={order}
            shipment={shipment}
            refetch={refetch}
            setPodDownloadOpen={setPodDownloadOpen}
            setPodOpen={setPodOpen}
            setWaseeqaPopup={setWaseeqaPopup}
            onAssignCarrier={() => {
              setActiveShipment(shipment);
              setActiveShipmentOrder(order);
              setOpen(true);
              setAction('assign');
            }}
          />
          <OptionsMenu options={options} />
        </div>
      </TableCell>

      <PodPopup
        setOpen={setPodOpen}
        open={podOpen}
        shipmentId={shipment.id}
        orderId={order.id}
        refetch={refetch}
        referenceId={shipment.referenceId || ''}
        edit={!!shipment.referenceId}
      />

      <ViewPodPopup
        setOpen={setPodDownloadOpen}
        open={podDownloadOpen}
        shipmentId={shipment.id}
        orderId={order.id}
      />
    </TableRow>
  );
};

export const ActionBtns = ({
  shipment,
  order,
  assignDock,
  refetch,
  onAssignCarrier,
  setPodDownloadOpen,
  setPodOpen,
  setWaseeqaPopup
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const navigate = useNavigate();
  const { setAPILoader } = useContext(SharedContext);
  const [open, setOpen] = useState(false);

  if (
    shipment?.status?.key == 'CREATED' &&
    shipment?.ShipmentLegs?.[0]?.status == 'AWAITING_CARRIER'
  ) {
    return (
      <div>
        <Button
          label={
            <span className="text-[12px] font-medium">Assign Carrier</span>
          }
          variant="transparent"
          size="large"
          onClick={onAssignCarrier}
          className="h-9"
        />
      </div>
    );
  }
  if (
    shipment?.status?.key == 'CREATED' &&
    shipment?.ShipmentLegs?.[0]?.status == 'CARRIER_ASSIGNED' &&
    !shipment.dockId &&
    isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT)
  ) {
    return (
      <div className="w-44">
        <DockAssignment
          dockInitialValues={order}
          shipment={shipment}
          submitHandler={assignDock}
          refetch={refetch}
        />
      </div>
    );
  }

  if (
    shipment?.status?.key == 'CREATED' &&
    ((shipment?.subStatus?.key == 'DOCK_ASSIGNED' &&
      shipment.dockId &&
      isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT)) ||
      !isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT))
  ) {
    return (
      <div className="flex gap-2">
        {isFeatureEnabled(FLAGS.GDN_CREATION) &&
          (isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING) ? (
            <Button
              label={
                <span className="text-[12px] font-medium">
                  Dispatch Inventory
                </span>
              }
              variant="transparent"
              size="large"
              onClick={() =>
                navigate(
                  `/routing/create-gdn/${order.customId}/${shipment.customId}`
                )
              }
              className="h-9"
            />
          ) : (
            <PopupButton
              open={open}
              setOpen={setOpen}
              btn={
                <Button
                  label="Dispatch Inventory"
                  variant="transparent"
                  size="large"
                  onClick={() => (open ? setOpen(false) : setOpen(true))}
                />
              }
              content={
                <List className="py-4 w-40">
                  <ListItem
                    className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
                    onClick={() => {
                      navigate(
                        `/routing/create-gdn/${order.customId}/${shipment.customId}`,
                        { state: 'Dispatch_full' }
                      );
                    }}
                  >
                    Dispatch Full
                  </ListItem>
                  <ListItem
                    className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
                    onClick={() => {
                      navigate(
                        `/routing/create-gdn/${order.customId}/${shipment.customId}`
                      );
                    }}
                  >
                    Dispatch Partial
                  </ListItem>
                </List>
              }
            />
          ))}
        {isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) && (
          <div className="w-44">
            <DockAssignment
              dockInitialValues={order}
              shipment={shipment}
              submitHandler={assignDock}
              refetch={refetch}
              edit={true}
            />
          </div>
        )}
      </div>
    );
  }

  if (shipment?.status?.key == 'IN_TRANSIT') {
    return (
      <div className="flex gap-2">
        <Button
          label={
            <span className="text-[12px] font-medium">Mark as Reached</span>
          }
          variant="transparent"
          size="large"
          onClick={async () => {
            try {
              setAPILoader(true);
              await API.put(
                `orders/${order.customId}/shipments/${shipment.customId}`,
                {
                  shipmentPayload: {
                    status: 'ARRIVED_AT_DESTINATION',
                    subStatusId: null
                  }
                }
              );
              await refetch();
              setAPILoader(false);
            } catch (e) {
              console.log(e);
            }
          }}
          className="h-9"
        />
        {isFeatureEnabled(FLAGS.GDN_CREATION) && (
          <Button
            label={<span className="text-[12px] font-medium">View GDN</span>}
            variant="transparent"
            size="large"
            onClick={() =>
              navigate(
                `/routing/create-gdn/${order.customId}/${shipment.customId}`
              )
            }
            className="h-9"
          />
        )}
      </div>
    );
  }

  if (shipment?.status?.key == 'ARRIVED_AT_DESTINATION') {
    return (
      <div className="flex gap-2">
        <Button
          label={
            <span className="text-[12px] font-medium">Mark as Completed</span>
          }
          variant="transparent"
          size="large"
          onClick={async () => {
            try {
              setAPILoader(true);
              await API.post(
                `orders/${order.customId}/shipments/${shipment.customId}/update_status`
              );
              await refetch();
              setAPILoader(false);
            } catch (e) {
              console.log(e);
            }
          }}
          className="h-9"
        />
        {isFeatureEnabled(FLAGS.GDN_CREATION) && (
          <Button
            label={<span className="text-[12px] font-medium">View GDN</span>}
            variant="transparent"
            size="large"
            onClick={() =>
              navigate(
                `/routing/create-gdn/${order.customId}/${shipment.customId}`
              )
            }
            className="h-9"
          />
        )}
      </div>
    );
  }

  if (
    shipment?.status?.key == 'COMPLETED' &&
    shipment?.subStatus?.key == 'POD_PENDING'
  ) {
    return (
      <div className="flex gap-2">
        <Button
          label={<span className="text-[12px] font-medium">View GDN</span>}
          variant="transparent"
          size="large"
          onClick={() =>
            navigate(
              `/routing/create-gdn/${order.customId}/${shipment.customId}`
            )
          }
          className="h-9"
        />
        <Button
          label={<span className="text-[12px] font-medium">Upload PoD</span>}
          variant="transparent"
          size="large"
          onClick={async () => {
            setPodOpen(true);
          }}
          className="h-9"
        />
      </div>
    );
  }

  if (
    shipment?.status?.key === 'COMPLETED' &&
    (shipment?.subStatus?.key === 'POD_UPLOADED' ||
      shipment?.subStatus?.key === 'INVOICE_GENERATED')
  ) {
    return (
      <div className="flex gap-2">
        <Button
          label={<span className="text-[12px] font-medium">View PoD(s)</span>}
          variant="transparent"
          size="large"
          onClick={async () => {
            setPodDownloadOpen(true);
          }}
          className="h-9"
        />
      </div>
    );
  }

  return null;
};
