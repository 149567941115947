/*eslint-disable*/
import Dehaze from '@material-ui/icons/Dehaze';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TreeItem, TreeView } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import {
  MdBusAlert,
  MdFindInPage,
  MdHouseSiding,
  MdListAlt,
  MdNotifications,
  MdOutlineAssignment,
  MdOutlineGridView,
  MdOutlineInventory2,
  MdSort,
  MdTask,
  MdToday
} from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { Box, Divider, Drawer, Typography } from '@mui/material';
import classNames from 'classnames/bind';
import '../../../assets/css/style.css';
import Account from '../../../assets/icons/Account.svg';
import Carside from '../../../assets/icons/Carside.svg';
import CompanyLogo from '../../../assets/icons/Company.svg';
import Dice from '../../../assets/icons/Dice.svg';
import DriverLogo from '../../../assets/icons/DriverLogo.svg';
import LogisticTruck from '../../../assets/icons/LogisticTruck.svg';
import Package from '../../../assets/icons/Package.svg';
import Portrait from '../../../assets/icons/Portrait.svg';
import QRIcon from '../../../assets/icons/QRIcon.svg';
import ReportIcon from '../../../assets/icons/Report.svg';
import UserLogo from '../../../assets/icons/User.svg';
import WarehouseLogo from '../../../assets/icons/Warehouse.svg';
import OnexusIcon from '../../../assets/icons/onexusIcon.svg';
import OnexusListingIcon from '../../../assets/icons/onexusListingIcon.svg';
import OrdersLogo from '../../../assets/icons/ordersIcon.svg';
import OwareLogo from '../../../assets/icons/O360LightLogo.svg';
import TowerIcon from '../../../assets/icons/tower.svg';
import WarehouseBookingIcon from '../../../assets/icons/warehouseBookingIcon.svg';
import IntelligenceIcon from '../../../assets/icons/intelligence.svg';
import PaymentLogo from '../../../assets/icons/payments.svg';
import {
  ADMINISTRATION_ORGANIZATION,
  ADMINISTRATION_VENDOR
} from '../../../constants/features';
import API from '../../../libs/axios';
import {
  checkPermission,
  isOwareSubdomain,
  isTpplSuperAdmin,
  isOwareSuperAdmin,
  isTpplSubdomain,
  isSuperAdmin
} from '../../../utils/auth';
import { SharedContext } from '../../../utils/common';
import { FeatureFlag } from '../../../utils/featureFlag';
import NavItem from './NavItem';
import { onError } from '../../../libs/errorLib';
import { ORGANIZATION_TYPES } from '../../../constants';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import FLAGS from '../../../constants/featureFlags';

const navTreeData = (
  organizationType,
  sms,
  tms,
  isFeatureEnabled,
  selfCreated
) => [
  {
    title: 'Control Tower',
    nodeId: 'control-tower',
    image: TowerIcon,
    canActivate: (user) =>
      isFeatureEnabled(FLAGS.UNIFIED_DASHBOARD) &&
      checkPermission(user, 'OPS_CONTROL_TOWER_VIEW'),
    href: '/control-tower'
  },
  {
    title: 'Order Management',
    nodeId: 'order-management',
    image: OrdersLogo,
    canActivate: (user) =>
      isFeatureEnabled(FLAGS.OMS) && checkPermission(user, 'OPS_ORDER_READ'),
    href: '/routing/order-management'
  },
  {
    title: 'Production Management',
    nodeId: 'production-management',
    image: OrdersLogo,
    canActivate: (user) =>
      isFeatureEnabled(FLAGS.OMS) &&
      organizationType === ORGANIZATION_TYPES.MANUFACTURER,
    href: '/routing/production-management'
  },
  {
    title: 'Shipment Management',
    nodeId: 'shipment-management',
    image: OrdersLogo,
    canActivate: (user) => isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION),
    href: '/routing/shipment-management'
  },
  {
    title: 'Inventory Management',
    nodeId: 'inventory-management',
    image: Package,
    canActivate: (user) =>
      isFeatureEnabled(FLAGS.INVENTORY_DETAILS_TABLE) &&
      checkPermission(user, 'OPS_INVENTORY_READ'),
    href: '/routing/inventory'
  },
  {
    title: 'Warehouse Management',
    nodeId: 'operations',
    image: WarehouseLogo,
    canActivate: () => isFeatureEnabled(FLAGS.WMS),
    children: [
      {
        canActivate: (user) =>
          isFeatureEnabled(FLAGS.STOCK_ADJUSTMENT) &&
          checkPermission(user, 'OPS_STOCK_ADJUSTMENT_READ'),
        href: '/operations/stock-adjustment',
        title: 'Stock Adjustment',
        activeRouteName: 'stock-adjustment',
        IconType: MdOutlineInventory2
      },
      {
        canActivate: (user) =>
          isFeatureEnabled(FLAGS.STOCK_RELOCATION) &&
          checkPermission(user, 'OPS_SECTION_RELOCATION_READ'),
        href: '/operations/section-relocation',
        title: 'Stock Movements',
        activeRouteName: 'section-relocation',
        IconType: MdSort
      },
      // {
      //   canActivate: (user) => checkPermission(user, "OPS_PRODUCT_INWARD_READ"),
      //   href: "/operations/product-inward",
      //   title: "Product Inward",
      //   activeRouteName: "product-inward",
      //   IconType: MdOutlineFileDownload,
      // },
      // {
      //   canActivate: (user) => checkPermission(user, "OPS_DISPATCH_ORDER_READ"),
      //   href: "/operations/dispatch-order",
      //   title: "Dispatch Order",
      //   activeRouteName: "dispatch-order",
      //   IconType: MdLocalShipping,
      // },
      // {
      //   canActivate: (user) => checkPermission(user, "OPS_PRODUCT_OUTWARD_READ"),
      //   href: "/operations/product-outward",
      //   title: "Product Outward",
      //   activeRouteName: "product-outward",
      //   IconType: MdOutlineFileUpload,
      // },
      {
        canActivate: (user) =>
          checkPermission(user, 'OPS_ORDER_READ') &&
          (isFeatureEnabled(FLAGS.PICKING) ||
            isFeatureEnabled(FLAGS.PUTAWAY) ||
            isFeatureEnabled(FLAGS.CYCLE_COUNT)),
        href: '/routing/task-management',
        title: 'Tasks Management',
        activeRouteName: 'task-management',
        IconType: MdTask
      }
      // {
      //   canActivate: (user) =>
      //     checkPermission(user, 'OPS_STOCK_PALLETIZATION_READ'),
      //   href: '/operations/palletizer',
      //   title: 'Palletizer',
      //   activeRouteName: 'palletizer',
      //   IconType: MdTask
      // }
    ]
  },
  {
    title: 'Transport Management',
    nodeId: 'logistics',
    image: LogisticTruck,
    canActivate: () => sms || tms,
    children: [
      {
        canActivate: (user) => tms && checkPermission(user, 'OPS_DRIVER_READ'),
        href: '/logistics/driver',
        title: 'Driver(s)',
        activeRouteName: 'driver',
        image: DriverLogo
      },
      {
        canActivate: (user) =>
          (tms || sms) && checkPermission(user, 'OPS_VEHICLE_TYPE_READ'),
        href: '/logistics/vehicle-types',
        title: 'Vehicle Type(s)',
        activeRouteName: 'vehicle-types',
        IconType: MdBusAlert
      },
      {
        canActivate: (user) => tms && checkPermission(user, 'OPS_VEHICLE_READ'),
        href: '/logistics/vehicle',
        title: 'Vehicle(s)',
        activeRouteName: 'vehicle',
        image: Carside
      }
      // {
      //   canActivate: (user) => checkPermission(user, "OPS_RIDE_READ"),
      //   href: "/logistics/load",
      //   title: "Load",
      //   activeRouteName: "load",
      //   image: TruckLoading,
      // },
    ]
  },
  {
    title: 'Intelligence',
    nodeId: 'intelligence',
    image: IntelligenceIcon,
    canActivate: (user) =>
      checkPermission(user, 'OPS_INTELLIGENCE') && !isTpplSubdomain(),
    href: '/intelligence'
  },
  {
    title: 'Billing and Invoicing',
    nodeId: 'billing-invoice',
    image: Package,
    canActivate: () => isFeatureEnabled(FLAGS.BILLING_AND_INVOICING),
    children: [
      {
        canActivate: (user) =>
          isFeatureEnabled(FLAGS.BILLING_AND_INVOICING) &&
          checkPermission(user, 'OPS_INVENTORY_READ'),
        href: '/billing-invoice/receivables',
        title: 'Receivables',
        activeRouteName: 'receivables',
        IconType: MdOutlineAssignment
      },
      {
        canActivate: (user) =>
          isFeatureEnabled(FLAGS.BILLING_AND_INVOICING) &&
          checkPermission(user, 'OPS_INVENTORY_READ'),
        href: '/billing-invoice/payables',
        title: 'Payables',
        activeRouteName: 'payables',
        IconType: MdOutlineGridView
      },
      {
        canActivate: (user) =>
          isFeatureEnabled(FLAGS.BILLING_AND_INVOICING) &&
          checkPermission(user, 'OPS_INVENTORY_READ'),
        href: '/billing-invoice/settings',
        title: 'Billing and Invoice Settings',
        activeRouteName: 'settings',
        IconType: MdOutlineAssignment
      },
      {
        canActivate: (user) => isFeatureEnabled(FLAGS.BILLING_AND_INVOICING),
        href: '/reporting/invoice-ledger',
        title: 'Invoice Report',
        activeRouteName: 'invoice-ledger',
        IconType: MdNotifications
      },
      {
        canActivate: (user) => isFeatureEnabled(FLAGS.BILLING_AND_INVOICING),
        href: '/reporting/payables-ledger',
        title: 'Payables Report',
        activeRouteName: 'payables-ledger',
        IconType: MdNotifications
      }
    ]
  },
  {
    title: 'Reporting',
    nodeId: 'reporting',
    image: ReportIcon,
    canActivate: () => true,
    children:
      selfCreated === false
        ? [
            {
              canActivate: (user) =>
                isFeatureEnabled(FLAGS.INVENTORY_LEDGER_REPORT) &&
                checkPermission(user, 'OPS_LEDGER_READ'),
              href: '/reporting/ledger-report',
              title: 'Ledger Report',
              activeRouteName: 'ledger-report',
              IconType: MdOutlineAssignment
            },
            {
              canActivate: (user) =>
                isFeatureEnabled(FLAGS.NOTIFICATION_CONSOLE) &&
                checkPermission(user, 'OPS_NOTIFICATION_READ'),
              href: '/reporting/notification-console',
              title: 'Notification Console',
              activeRouteName: 'notification-console',
              IconType: MdNotifications
            },
            {
              canActivate: (user) =>
                isFeatureEnabled(FLAGS.BILLING_AND_INVOICING),
              href: '/reporting/invoice-ledger',
              title: 'Invoice Report',
              activeRouteName: 'invoice-ledger',
              IconType: MdNotifications
            }
          ]
        : [
            {
              canActivate: (user) =>
                isFeatureEnabled(FLAGS.INVENTORY_LEDGER_REPORT) &&
                checkPermission(user, 'OPS_LEDGER_READ'),
              href: '/reporting/ledger-report',
              title: 'Ledger Report',
              activeRouteName: 'ledger-report',
              IconType: MdOutlineAssignment
            }
          ]
  },
  {
    title: 'Administration',
    nodeId: 'administration',
    image: Account,
    canActivate: () => true,
    children: [
      ...(FeatureFlag(ADMINISTRATION_ORGANIZATION)
        ? [
            {
              canActivate: (user) => isOwareSuperAdmin(user),
              href: '/administration/organization',
              title: 'Organization',
              image: UserLogo,
              activeRouteName: 'organization'
            }
          ]
        : []),
      {
        canActivate: (user) => checkPermission(user, 'OPS_USER_READ'),
        href: '/administration/user',
        title: 'User(s)',
        image: UserLogo,
        activeRouteName: 'user'
      },
      {
        canActivate: (user) => checkPermission(user, 'OPS_USER_READ'),
        href: '/administration/settings',
        title: 'Settings',
        activeRouteName: 'settings'
      },
      {
        canActivate: (user) => checkPermission(user, 'OPS_ROLE_READ'),
        href: '/access-permissions/roles',
        title: 'Role(s)',
        activeRouteName: 'roles',
        image: UserLogo
      },
      {
        canActivate: (user) =>
          (isOwareSuperAdmin(user) || isTpplSuperAdmin(user)) &&
          checkPermission(user, 'OPS_REPORT_READ'),
        href: '/reporting/embed-report',
        title: 'Embedded Reports',
        activeRouteName: 'embed-report',
        IconType: MdOutlineGridView
      },
      // {
      //   canActivate: (user) => checkPermission(user, 'OPS_COUNTRY_READ'),
      //   href: '/administration/country',
      //   title: 'Country',
      //   image: Flag,
      //   activeRouteName: 'country'
      // },
      // {
      //   canActivate: (user) => checkPermission(user, 'OPS_WAREHOUSE_READ'),
      //   href: '/administration/warehouse',
      //   title: 'Warehouse',
      //   image: WarehouseLogo,
      //   activeRouteName: 'warehouse'
      // },
      {
        canActivate: (user) =>
          isFeatureEnabled(FLAGS.PRODUCT_HIERARCHY_CREATION) &&
          checkPermission(user, 'OPS_UOM_READ'),
        href: '/administration/uom',
        title: 'UoM(s)',
        image: Dice,
        activeRouteName: 'uom'
      },
      // {
      //   canActivate: (user) => checkPermission(user, 'OPS_COMPANY_READ'),
      //   href: '/administration/company',
      //   title: 'Customer Onboarding',
      //   image: CompanyLogo,
      //   activeRouteName: 'company'
      // },
      {
        canActivate: (user) =>
          isFeatureEnabled(FLAGS.CREATING_CUSTOMER_MASTER) &&
          checkPermission(user, 'OPS_COMPANY_READ'),
        href: '/administration/supply-chain-network',
        title: 'Supply Chain Network',
        image: CompanyLogo,
        activeRouteName: 'supply-chain-network'
      },
      {
        canActivate: (user) =>
          isFeatureEnabled(FLAGS.PRODUCT_HIERARCHY_CREATION) &&
          checkPermission(
            user,
            organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
              ? 'OPS_COMPANY_READ'
              : 'OPS_PRODUCT_READ'
          ),
        href: '/administration/products',
        title: 'Product(s)',
        image: CompanyLogo,
        activeRouteName: 'products'
      },
      ...(FeatureFlag(ADMINISTRATION_VENDOR)
        ? [
            {
              canActivate: (user) =>
                (tms || sms) && checkPermission(user, 'OPS_CARRIER_READ'),
              href: '/administration/carrier',
              title: 'Carrier(s) & Vendor(s)',
              activeRouteName: 'carrier',
              image: Portrait
            }
          ]
        : []),
      // {
      //   canActivate: (user) => checkPermission(user, "OPS_PRODUCT_READ"),
      //   href: "/administration/product",
      //   title: "Product",
      //   IconType: MdShoppingBag,
      //   activeRouteName: "product",
      // },
      {
        canActivate: (user) =>
          isFeatureEnabled(FLAGS.STOCK_RELOCATION) &&
          checkPermission(user, 'OPS_QR_READ'),
        href: '/QR/generate',
        title: 'Batch QR Generator',
        activeRouteName: 'generate',
        image: QRIcon
      },
      {
        canActivate: (user) => checkPermission(user, 'OPS_PALLET_READ'),
        href: '/administration/pallets',
        title: 'Pallets',
        activeRouteName: 'pallets',
        image: QRIcon
      }
    ]
  },
  {
    title: 'Integrations',
    nodeId: 'integrations',
    image: OrdersLogo,
    canActivate: (user) =>
      organizationType == 'MANUFACTURER' &&
      isSuperAdmin(user) &&
      isFeatureEnabled(FLAGS.QUICK_BOOKS_INTEGRATION),
    href: '/integrations'
  },
  {
    title: 'O-Nexus',
    nodeId: 'o-nexus',
    image: OnexusIcon,
    canActivate: (user) =>
      isOwareSubdomain() && checkPermission(user, 'OPS_WAREHOUSE_BOOKING_READ'),
    children: [
      {
        canActivate: (user) => checkPermission(user, 'OPS_CARRIER_READ'),
        href: '/onexus/listing',
        title: 'O-Nexus Listing',
        activeRouteName: 'listing',
        image: OnexusListingIcon
      },
      {
        canActivate: (user) =>
          checkPermission(user, 'OPS_WAREHOUSE_BOOKING_READ'),
        href: '/onexus/booking',
        title: 'Warehouse Booking',
        activeRouteName: 'booking',
        image: WarehouseBookingIcon
      },
      {
        canActivate: (user) =>
          checkPermission(user, 'OPS_WAREHOUSE_BOOKING_READ'),
        href: '/onexus/customer-onboardings',
        title: 'Customer Onboarding',
        activeRouteName: 'cusomer-onboarding',
        image: WarehouseBookingIcon
      }
    ]
  },
  {
    title: 'Payments',
    nodeId: 'payments',
    image: PaymentLogo,
    canActivate: () => selfCreated,
    children: [
      {
        canActivate: () => selfCreated,
        href: '/payment/customer-portal',
        title: 'Customer Portal',
        activeRouteName: 'customer-portal',
        IconType: MdSort
      }
    ]
  }
];

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: '100vw'
  },
  desktopDrawer: {
    top: 0,
    backgroundColor: theme.palette.secondary.main,
    height: '100vh'
  },

  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  brand: {
    // color: theme.palette.primary.light,
    fontSize: '18px'
  },
  treeNode: {
    color: '#FFF',
    fontSize: '14px !important',
    fontStyle: 'normal !important',
    fontWeight: '400 !important',
    lineHeight: '150% !important',
    letterSpacing: '0.15px !important',
    padding: '12px 0px',
    opacity: '0.800000011920929'
  },
  treeNodeSelected: {
    color: 'white',
    opacity: '1 !important',
    fontWeight: '500 !important',
    backgroundColor: '#0F2666'
  },
  treeItem: {
    '&:hover': {
      color: 'white'
    }
  },
  logoIcon: {
    width: '29.078px',
    height: '28.559px'
  },
  logoType: {
    width: '88.174px',
    height: '17.706px',
    marginLeft: 8
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    height: '59px',
    margin: '0px 16px 32px 16px'
  },
  logoBox: {
    marginTop: 8
  },
  navBox: {
    '& .MuiTreeItem-content': {
      flexDirection: 'row-reverse',
      alignItems: 'center',
      width: '100%',
      '& .MuiTreeItem-iconContainer': {
        width: '20px',
        height: '20px',
        marginRight: '10px',
        '& .MuiSvgIcon-root': {
          fontSize: '20px'
        }
      }
    }
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center'
  }
}));

// const NavBar = ({ onMobileClose, openMobile }) => {
export default function NavBar({ onMobileClose, openMobile }) {
  const { isFeatureEnabled } = useFeatureFlags();
  const { currentUser, subdomain, organizationType, organization } =
    useContext(SharedContext);

  const [navList, setNavList] = useState(
    navTreeData(
      organizationType,
      isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION),
      isFeatureEnabled(FLAGS.TMS),
      isFeatureEnabled,
      organization?.selfCreated
    )
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState([]);
  const [expanded, setExpanded] = useState([`${location.pathname}`]);
  const classes = useStyles();

  const { sidebar, setSidebar } = useContext(SharedContext);
  let sideBarEff = null;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (organizationType) {
      setNavList(
        navTreeData(
          organizationType,
          isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION),
          isFeatureEnabled(FLAGS.TMS),
          isFeatureEnabled,
          organization?.selfCreated
        )
      );
    }
  }, [organizationType, organization]);

  useEffect(() => {
    if (
      checkPermission(currentUser, 'OPS_REPORT_READ') &&
      organization?.selfCreated === false
    ) {
      getReports();
    }
  }, [organization]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  const getReports = async () => {
    try {
      const reports = await API.get(
        `organizations/${subdomain}/embedded-reports`
      );
      if (reports?.data?.length) {
        let index = navList.findIndex((nav) => nav.title === 'Reporting');

        const children = navList[index].children.slice(0, 3);
        reports?.data?.map((report) => {
          if (report?.isActive) {
            let icon;
            switch (report?.icon) {
              case 1:
                icon = MdFindInPage;
                break;
              case 2:
                icon = MdHouseSiding;
                break;
              case 3:
                icon = MdToday;
                break;
              case 4:
                icon = MdListAlt;
                break;
              case 5:
                icon = MdOutlineAssignment;
                break;
              case 6:
                icon = MdNotifications;
                break;
              default:
                icon = MdFindInPage;
                break;
            }

            children.push({
              canActivate: (user) => checkPermission(user, 'OPS_REPORT_READ'),
              href: `/reporting/embed-report/${report?.id}`,
              title: report?.name,
              activeRouteName: `${report?.name}`,
              IconType: icon
            });
          }
        });

        setNavList((prev) => {
          prev[index].children = children;
          return prev;
        });
      }
    } catch (err) {
      onError(err);
    }
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded([nodeIds[0]]);
  };

  const removeAttr = () => {
    document
      .querySelector('#parentCommonSidebar .MuiDrawer-paperAnchorLeft')
      ?.removeAttribute('tabindex');
  };

  const goToHome = () => {
    navigate('/control-tower');
  };

  removeAttr();
  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const handleMainItemClick = (route, nodeIds) => {
    if (!route) return;
    navigate(route);
    setExpanded([nodeIds]);
  };

  var parentClass = classNames({
    [classes.treeItem]: true,
    itemRemove: !sidebar,
    childNavItem: true
  });
  var parentItemClass = classNames({
    itemRemove: !sidebar
  });

  const content = (
    <Box
      height="100%"
      className="mainNavStyle"
      id={sidebar ? 'commonSidebar' : 'mainSidebar'}
      display="flex"
      tabindex={false}
      flexDirection="column"
    >
      <Box
        alignItems="start"
        display="flex"
        flexDirection="row"
        className={classes.logoBox}
      >
        <Typography
          variant="h2"
          style={{ fontWeight: 'bolder', fontSize: '20px', color: 'white' }}
          component="div"
          className={classes.brand}
          color="primary"
          id={!sidebar ? 'display-none' : ''}
        >
          {/* <img onClick={goToHome} src={OwareLogo} alt="Logo" className="imgLogo" /> */}
          <div onClick={goToHome} className={classes.logo}>
            <img src={OwareLogo} alt="Logo" className="h-4" />
          </div>
        </Typography>
        <Dehaze
          className="collapseIcon"
          onClick={() => {
            sideBarEff = sidebar ? false : true;
            setSidebar(sideBarEff);
          }}
        />
      </Box>
      <Divider className="dividerMargin" />
      <Box className={classes.navBox}>
        <TreeView
          defaultCollapseIcon={<ExpandLessIcon />}
          defaultExpandIcon={<ExpandMoreIcon />}
          expanded={expanded}
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
        >
          {navList &&
            navList
              .filter((val) =>
                isMobile ? val.title === 'Order Management' : val
              )
              .map((treedata) => (
                <React.Fragment key={treedata.nodeId}>
                  {treedata.canActivate(currentUser) ? (
                    <TreeItem
                      key={treedata?.href}
                      nodeId={treedata.nodeId}
                      id="mainItem"
                      classes={{
                        root: classes.treeNode,
                        selected: classes.treeNodeSelected
                      }}
                      className={parentItemClass}
                      onClick={() => {
                        handleMainItemClick(treedata?.href, treedata.nodeId);
                      }}
                      label={
                        <div className={classes.labelRoot}>
                          {treedata.image ? (
                            <img
                              alt="nav icon"
                              style={{
                                marginRight: 10,
                                width: '16px',
                                height: '16px'
                              }}
                              src={treedata.image}
                            ></img>
                          ) : (
                            <treedata.icon className={classes.treeNode} />
                          )}
                          <Typography
                            variant="body2"
                            className={classes.labelText}
                          >
                            {treedata.title}
                          </Typography>
                        </div>
                      }
                    >
                      {treedata?.children?.map((treeitem) =>
                        treeitem?.canActivate(currentUser) ? (
                          <NavItem
                            id="navItem"
                            key={treeitem?.title}
                            title={treeitem.title}
                            className={parentClass}
                            href={treeitem.href}
                            activeRouteName={treeitem.activeRouteName}
                            onClick={() => {
                              setSidebar(true);
                            }}
                          />
                        ) : (
                          ''
                        )
                      )}
                    </TreeItem>
                  ) : null}
                </React.Fragment>
              ))}
        </TreeView>
      </Box>

      {/* <Box flexGrow={1} />
      <Box p={2} m={2} bgcolor="background.dark"></Box> */}
    </Box>
  );

  return (
    <>
      {isMobile ? (
        ''
      ) : (
        <Drawer
          id={sidebar ? 'parentCommonSidebar' : 'mainSidebar'}
          classes={{ paper: classes.desktopDrawer }}
          anchor="left"
          open
          onClose={onMobileClose}
          variant="persistent"
        >
          {content}
        </Drawer>
      )}
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

// export default NavBar;
